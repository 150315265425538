import React, { useState } from 'react';
import styled from 'styled-components';
import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Close as CloseIcon } from '@mui/icons-material';
import { ReactComponent as UnlockedIcon } from 'src/assets/icons/unlocked-item.svg';
import { ReactComponent as LockedIcon } from 'src/assets/icons/locked-item.svg';
import { ReactComponent as DuplicateIcon } from 'src/assets/icons/duplicate-item.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete-item.svg';
import { ReactComponent as CopyLinkIcon } from 'src/assets/icons/chain-link.svg';
import { makeJumpToIconUrl } from 'src/components/UrlReactor';

export const CloseButton: React.VFC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <CloseButtonStyle onClick={onClick}>
    <CloseIcon />
  </CloseButtonStyle>
);

const CloseButtonStyle = styled(IconButton)`
  padding: 0;
  margin-right: 21px;
  width: 22px;
  height: 22px;

  & svg {
    width: 22px;
    height: 22px;
    color: ${(props) => props.theme.mainAccent};
  }
`;

export const ActionIconButton = styled(IconButton)`
  padding: 0;
  margin-left: 12px;
  width: 24px;
  height: 24px;

  &.locked path {
    fill: ${(props) => props.theme.errorPrimary};
  }
`;

export const LockUnlockButton: React.FC<{
  locked: boolean;
  setLocked: (v: boolean) => void;
  disabled: boolean;
}> = ({ locked, setLocked, disabled }) => (
  <ActionIconButton
    classes={locked ? { root: 'locked' } : undefined}
    onClick={() => setLocked(!locked)}
    disabled={disabled}
  >
    {locked ? (
      <LockedIcon style={{ height: 21 }} />
    ) : (
      <UnlockedIcon style={{ height: 21 }} />
    )}
  </ActionIconButton>
);

export const EditButton: React.FC<{
  onClick: () => void;
  style: React.CSSProperties;
}> = ({ onClick, style }) => (
  <ActionIconButton onClick={onClick} style={style}>
    <EditIcon style={{ objectFit: 'contain', width: 21, height: 21 }} />
  </ActionIconButton>
);

export const DuplicateButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <ActionIconButton onClick={onClick}>
    <DuplicateIcon style={{ objectFit: 'contain', width: 21, height: 21 }} />
  </ActionIconButton>
);

export const DeleteButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <ActionIconButton onClick={onClick}>
    <DeleteIcon style={{ height: 18 }} />
  </ActionIconButton>
);

export const CopyLinkButton: React.FC<{ itemId: ID }> = ({ itemId }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  return (
    <>
      <ActionIconButton
        onClick={() => {
          window.navigator.clipboard
            .writeText(makeJumpToIconUrl(itemId))
            .then(() => setSnackbarOpen(true))
            .catch(() => window.alert('Failed to copy item link to clipboard'));
        }}
      >
        <CopyLinkIcon
          fill="#202252"
          opacity="0.5"
          style={{ objectFit: 'contain', width: 21, height: 21 }}
        />
      </ActionIconButton>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          Copied to clipboard
        </Alert>
      </Snackbar>
    </>
  );
};
