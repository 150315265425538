import { DEFAULT_LAG, DEFAULT_SPANNING_ITEM_DURATION } from 'src/constants';
import {
  CreateProcessDocument,
  CreateProcessMutationFn,
  CreateRunDocument,
  CreateRunMutationFn,
  CreateSinkDocument,
  CreateSinkMutationFn,
  CreateSourceDocument,
  CreateSourceMutationFn,
  LocateItemDocument,
  LocateItemQuery,
  LocateItemQueryVariables,
  useCreateProcessMutation,
} from 'src/generated/graphql';
import { TimeRange } from 'src/models';
import { ItemType } from 'src/models/item';
import { ApolloClient } from '@apollo/client';

export interface CreateRunProcessInput {
  runId: ID;
}

export function useCreateRunProcess(input: CreateRunProcessInput) {
  return useCreateProcessMutation({
    variables: {
      input: {
        duration: DEFAULT_SPANNING_ITEM_DURATION,
        lag: DEFAULT_LAG,
        resourceId: null,
        runId: input.runId,
        startTime: null,
      },
    },
  });
}

export interface CreateTimelineItemInput {
  type: ItemType;
  resourceId: ID;
  startTime: Date;
}

export async function createTimelineItem(
  client: ApolloClient<unknown>,
  input: CreateTimelineItemInput,
): Promise<ID> {
  let result;
  if (input.type === 'Process') {
    result = await (client.mutate as CreateProcessMutationFn)({
      mutation: CreateProcessDocument,
      variables: {
        input: {
          duration: DEFAULT_SPANNING_ITEM_DURATION,
          lag: DEFAULT_LAG,
          resourceId: input.resourceId,
          startTime: input.startTime,
        },
      },
    });
  } else if (input.type === 'Run') {
    result = await (client.mutate as CreateRunMutationFn)({
      mutation: CreateRunDocument,
      variables: {
        input: {
          duration: DEFAULT_SPANNING_ITEM_DURATION,
          lag: DEFAULT_LAG,
          resourceId: input.resourceId,
          startTime: input.startTime,
        },
      },
    });
  } else if (input.type === 'Source') {
    result = await (client.mutate as CreateSourceMutationFn)({
      mutation: CreateSourceDocument,
      variables: {
        input: {
          resourceId: input.resourceId,
          startTime: input.startTime,
        },
      },
    });
  } else if (input.type === 'Sink') {
    result = await (client.mutate as CreateSinkMutationFn)({
      mutation: CreateSinkDocument,
      variables: {
        input: {
          resourceId: input.resourceId,
          startTime: input.startTime,
        },
      },
    });
  }

  const id = result?.data?.result.item.id;
  if (id == null) {
    throw new Error('Failed to create item');
  }

  return id;
}

export type LocateItemResult =
  | { type: 'not-found' }
  | { type: 'not-on-timeline' }
  | ({ type: 'located' } & TimeRange);

export async function locateItem(
  client: ApolloClient<unknown>,
  id: ID,
): Promise<LocateItemResult> {
  const { data } = await client.query<
    LocateItemQuery,
    LocateItemQueryVariables
  >({
    query: LocateItemDocument,
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  if (data.item == null) {
    return { type: 'not-found' };
  }

  if (data.item.timelineUsage == null) {
    return { type: 'not-on-timeline' };
  }

  const tu = data.item.timelineUsage;
  return {
    type: 'located',
    startTime: new Date(tu.startTime),
    endTime: new Date(tu.endTime),
  };
}
