import { Run } from 'src/generated/graphql';
import { timeDays } from 'd3-time';
import { TimeRange } from 'src/models';
import {
  ItemLayoutData,
  isPointItem,
  Item,
  ProcessLayoutData,
  RunLayoutData,
} from 'src/models/item';
import { Scale } from 'src/utils/scale';

export type MinimalItemProps = {
  start: Date;
  end: Date;
};

export enum ViewState {
  Normal,
  Expanded,
  Compressed,
}

// Hours, Minutes, Seconds, ...
const WORKING_HOURS_START = [5, 0, 0];
const WORKING_HOURS_END = [21, 0, 0];

const mergeDate = (date: Date, hms: number[]) => {
  const prefix = [date.getFullYear(), date.getMonth(), date.getDate()];
  // @ts-ignore
  return new Date(...[...prefix, ...hms]);
};

export function generateAfterHours(
  periodStart: Date,
  periodEnd: Date,
): [Date, Date][] {
  const days = timeDays(periodStart, periodEnd);
  const output: [Date, Date][] = [];

  output.push([periodStart, mergeDate(periodStart, WORKING_HOURS_START)]);
  for (let i = 0; i < days.length - 1; i += 1) {
    const today = days[i];
    const tomorrow = days[i + 1];
    output.push([
      mergeDate(today, WORKING_HOURS_END),
      mergeDate(tomorrow, WORKING_HOURS_START),
    ]);
  }
  output.push([
    mergeDate(days[days.length - 2], WORKING_HOURS_START),
    periodEnd,
  ]);

  return output;
}

export interface GroupableItem {
  id: any;
  timelineUsage: TimeRange;
}

export interface Grouped {
  verticalOrder: number;
  big: boolean;
}

export interface Bounds {
  left: number;
  right: number;
  timePointLeft: number;

  hiddenIndicatorOffsets?: number[];
  outputBufferOffset?: number;

  run?: { processes: Bounds[] };
}

// Probably not perfect - but can improve if we start seeing issues.
const PX_PER_LETTER = 10;
const PX_FOR_LOCK_ICON = 20;

interface Context {
  scale: Scale;
  hiddenTimes: TimeRange[];
  downtimes: TimeRange[];
}

export function getBounds(item: Item, ctx: Context): Bounds {
  const { scale, hiddenTimes, downtimes } = ctx;

  const timePoint = scale(item.timelineUsage!.startTime);
  if (isPointItem(item)) {
    return {
      left: timePoint - 15,
      right:
        timePoint +
        15 +
        (item.locked ? PX_FOR_LOCK_ICON : 0) +
        Math.max((item.title || '').length, (item.subtitle || '').length) *
          PX_PER_LETTER,
      timePointLeft: timePoint,
    };
  }

  const data: Bounds = {
    left: timePoint,
    right: scale(item.timelineUsage!.endTime),
    timePointLeft: timePoint,
  };

  if (item.__typename === 'Process' || item.__typename === 'Run') {
    // Add any applicable downtime to the output buffer
    const itemStart = item.timelineUsage!.startTime.getTime();
    const itemEnd = item.timelineUsage!.endTime.getTime();

    let remOutputBuffer = (item.lag ?? 0) * 1000;
    let outputBuffer = 0;

    for (const d of downtimes) {
      if (remOutputBuffer === 0) break;

      const start = Math.max(0, d.startTime.getTime() - itemStart);
      const end = Math.max(0, d.endTime.getTime() - itemStart);
      const add =
        Math.min(outputBuffer + remOutputBuffer, start) - outputBuffer;
      outputBuffer += add;
      remOutputBuffer = Math.max(0, remOutputBuffer - add);
      if (remOutputBuffer === 0) break;

      outputBuffer += Math.min(end, itemEnd) - start;
    }

    outputBuffer = Math.min(outputBuffer + remOutputBuffer, itemEnd);

    data.outputBufferOffset =
      scale(new Date(itemStart + outputBuffer)) - data.left;
  }

  if (item.__typename === 'Run') {
    data.run = {
      processes: item.runUsages.map((x) => getBounds(x.process, ctx)),
    };
  }

  if (item.__typename === 'Process' || item.__typename === 'Run') {
    const width = data.right - data.left;
    data.hiddenIndicatorOffsets = hiddenTimes
      .map((hidden) => scale(hidden.startTime) - data.left)
      .filter((x) => 0 <= x && x <= width);
  }

  return data;
}

interface ArrangeResult {
  bounds: Bounds;
  verticalOrder: number | null;
  item: Item;
}

function verticallyOrderItems(
  itemsIn: Item[],
  context: Context,
): { items: ArrangeResult[]; rowCount: number } {
  // Magic values for `verticalOrder`
  const ON_NEW_ROW = -1; // Should be placed on a new row
  const IS_TALL = null; // Should be tall - no overlaps

  const items = itemsIn
    .map(
      (item): ArrangeResult => ({
        item,
        bounds: getBounds(item, context),
        // Assume it'll go on a new row unless we determine otherwise
        verticalOrder: ON_NEW_ROW,
      }),
    )
    .sort((a, b) => a.bounds.left - b.bounds.left);

  const lastOnRow: ArrangeResult[] = [];

  for (const cur of items) {
    const overlap = lastOnRow.find((x) => x.bounds.right > cur.bounds.left);
    if (overlap && overlap.verticalOrder === IS_TALL) {
      overlap.verticalOrder = 0;
    }

    for (let i = 0; i < lastOnRow.length; i += 1) {
      if (cur.bounds.left >= lastOnRow[i].bounds.right) {
        // Can place in that vertical position
        cur.verticalOrder = i === 0 && !overlap ? IS_TALL : i;
        lastOnRow[i] = cur;
        break;
      }
    }

    if (cur.verticalOrder === ON_NEW_ROW) {
      cur.verticalOrder = lastOnRow.length === 0 ? IS_TALL : lastOnRow.length;
      lastOnRow.push(cur);
    }
  }

  return { items, rowCount: lastOnRow.length };
}

const HEIGHT = 80;
const COMPRESSED_HEIGHT = 40;
const PADDING = 10;
const MIN_RESOURCE_HEIGHT = 80;

function calculateItemPositions(
  items: ArrangeResult[],
  rowCount: number,
  viewState: ViewState,
): { items: ItemLayoutData[]; resourceHeight: number; stacked: boolean } {
  const result: ItemLayoutData[] = [];
  let resourceMinHeight = MIN_RESOURCE_HEIGHT;

  // The height of a "big" (i.e. no overlaps - no stacking) item. If the
  // resource is compressed it should be small, but otherwise use the normal
  // height.
  const bigHeight =
    viewState === ViewState.Compressed && rowCount === 1
      ? COMPRESSED_HEIGHT
      : HEIGHT;

  // Additional spacing above a "big" point item
  const fullPointSpacing =
    viewState === ViewState.Compressed && rowCount === 1 ? 20 : 40;

  // Add big items
  const bigItems = items.filter((i) => i.verticalOrder === null);
  for (const item of bigItems) {
    resourceMinHeight = bigHeight + PADDING * 2;
    const data = mapToData(item, {
      height: bigHeight,
      topMargin: PADDING,
      spacing: fullPointSpacing,
    });
    result.push(...data);
  }

  // Add stacked items
  let resourceHeight = 0;
  let verticalOrder = 0;
  const height = viewState === ViewState.Expanded ? HEIGHT : COMPRESSED_HEIGHT;
  const filter = (i: ArrangeResult) => i.verticalOrder === verticalOrder;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const rowItems = items.filter(filter);
    if (rowItems.length === 0) break;

    resourceHeight += PADDING;

    for (const item of rowItems) {
      const data = mapToData(item, {
        height,
        topMargin: resourceHeight,
        spacing: 20,
      });
      result.push(...data);
    }

    resourceHeight += height;
    verticalOrder += 1;
  }

  resourceHeight += PADDING;
  if (resourceHeight < resourceMinHeight) resourceHeight = resourceMinHeight;

  return {
    items: result,
    resourceHeight,
    stacked: verticalOrder > 1,
  };
}

interface MapConfig {
  height: number;
  topMargin: number;
  spacing: number;
}

function mapToData(i: ArrangeResult, config: MapConfig): ItemLayoutData[] {
  const data: ItemLayoutData = {
    item: i.item,
    height: config.height,
    left: i.bounds.timePointLeft,
    right: i.bounds.right,
    top: config.topMargin + (isPointItem(i.item) ? config.spacing : 0),
  };

  const results: ItemLayoutData[] = [];
  results.push(data);

  if (i.item.__typename === 'Process') {
    data.item = i.item;
    const d = data as ProcessLayoutData;
    d.item = i.item;
    d.outputBufferOffset = i.bounds.outputBufferOffset!;
    d.hiddenIndicatorOffsets = i.bounds.hiddenIndicatorOffsets!;
  }
  if (i.item.__typename === 'Run') {
    const d = data as RunLayoutData;
    d.item = i.item;
    d.outputBufferOffset = i.bounds.outputBufferOffset!;
    d.hiddenIndicatorOffsets = i.bounds.hiddenIndicatorOffsets!;
    d.processes = i.bounds.run!.processes.map((x, idx, arr) => {
      const left = x.left - d.left + 2;
      const right = x.right - d.left + (idx === arr.length - 1 ? -2 : 0);
      return { left, right, outputBufferOffset: x.outputBufferOffset };
    });

    results.push(
      ...(i.bounds.run?.processes ?? []).map(
        (x, idx): ProcessLayoutData => ({
          inRun: true,
          item: (i.item as Run).runUsages[idx].process,
          height: 52,
          left: x.left + 6,
          right: x.right,
          top:
            config.topMargin + (config.height === COMPRESSED_HEIGHT ? 3 : 25),
          hiddenIndicatorOffsets: [],
          outputBufferOffset: x.outputBufferOffset!,
        }),
      ),
    );
  }

  return results;
}

export function positionItems(
  data: Item[],
  viewState: ViewState,
  context: Context,
) {
  const { items, rowCount } = verticallyOrderItems(data, context);
  return calculateItemPositions(items, rowCount, viewState);
}
