import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useApolloClient } from '@apollo/client';
import { locateItem } from 'src/server/items';
import { useAppDispatch } from 'src/store/hooks';
import { focusOn, selectItems } from 'src/store/planner';
import { openItemSideDrawer } from 'src/store/drawer';
import { retry } from './retry';

export function useJumpToItem() {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  return useCallback(
    (itemId: ID) => {
      locateItem(client, itemId)
        .then((result) => {
          if (result.type === 'not-found') {
            const message = 'Item not found. Perhaps it was deleted';
            alert(message);
            return;
          }

          if (result.type === 'not-on-timeline') {
            const message = "Item isn't on the timeline";
            alert(message);
            return;
          }

          const { startTime, endTime } = result;
          const mid = new Date((startTime.getTime() + endTime.getTime()) / 2);

          dispatch(focusOn({ center: mid }));
          dispatch(openItemSideDrawer({ id: itemId, autoFocus: false }));
          dispatch(selectItems([itemId]));

          attemptFocus(itemId);
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
          alert('Cannot jump to item on timeline');
        });
    },
    [dispatch, client],
  );
}

async function attemptFocus(itemId: ID) {
  // Function doesn't throw, even if it never succeeds
  await retry({
    maxAttempts: 25,
    delayMs: (failureCount) => {
      if (failureCount < 8) return 200;
      if (failureCount < 15) return 500;
      return 1000;
    },
    action: () => {
      const item = document.getElementById(`item-${itemId}`);
      console.log('action', itemId, item);
      if (!item) {
        throw new Error('Item not loaded');
      }

      item.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    },
  });
}
