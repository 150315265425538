import { createTheme } from '@mui/material/styles';

export const materialOverrides = createTheme({
  typography: {
    fontFamily:
      "'Heebo', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
});
