import React from 'react';
import styled from 'styled-components';
import { ItemState } from 'src/models/item';
import {
  ItemTitleStyle,
  ItemSubtitleStyle,
  PointItemTextWrapperStyle,
} from './ItemStyles';
import { ColorwayName, colorways, ItemColorway } from 'src/config/theme';
import { ReactComponent as LockedIcon } from 'src/assets/icons/locked-item.svg';

export interface SourceProps {
  onClick?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  data: SourceDisplayData;
}

interface SourceDisplayData {
  title: string;
  subtitle: string;
  error: boolean;
  changed: boolean;
  locked: boolean;
  state: ItemState;
  colorway: ColorwayName;
  onClipboard: boolean;
}

export const Source: React.VFC<SourceProps> = ({
  onClick,
  onMouseMove,
  data: {
    title,
    subtitle,
    error,
    changed,
    locked,
    state,
    colorway,
    onClipboard,
  },
}) => (
  <div
    onClick={onClick}
    onMouseMove={onMouseMove}
    style={{
      position: 'relative',
      height: 20,
      margin: onClipboard ? 30 : undefined,
      opacity: state === ItemState.Inactive ? 0.5 : undefined,
    }}
  >
    <SourceDiamond
      className={state === ItemState.Selected ? 'selected' : undefined}
      {...colorways[colorway]}
    />
    {error && (
      <ErrorDiamond>
        <div className="error-dash bottom" />
        <div className="error-dash top" />
      </ErrorDiamond>
    )}
    {changed && <ChangedHighlightDiamond />}
    <PointItemTextWrapperStyle>
      <ItemTitleStyle
        style={{
          display: 'flex',
          color: onClipboard ? 'white' : 'inherit',
        }}
      >
        {locked && (
          <LockedIcon style={{ marginRight: 5, width: 17, height: 24 }} />
        )}
        <span>{title}</span>
      </ItemTitleStyle>
      <ItemSubtitleStyle style={{ color: onClipboard ? 'white' : 'inherit' }}>
        {subtitle}
      </ItemSubtitleStyle>
    </PointItemTextWrapperStyle>
  </div>
);

const SourceDiamond = styled.div<ItemColorway>`
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: ${(props) => props.standardBackground};
  border: 3px solid ${(props) => props.selectedBackground};
  border-radius: 4px;

  &.selected {
    background-color: ${(props) => props.selectedBackground};
    border: 3px solid ${(props) => props.selectedBorder};
  }
`;

const ErrorDiamond = styled.div`
  .error-dash {
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 7px;
    z-index: -1;
  }

  .top {
    border: 3px dashed ${(props) => props.theme.errorPrimary};
  }

  .bottom {
    border: 3px solid ${(props) => props.theme.errorSecondary};
  }
`;

const ChangedHighlightDiamond = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 26px;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 7px;
  z-index: -1;
  border: 3px solid ${(props) => props.theme.changedHighlight};
`;
