import styled from 'styled-components';
import { line } from 'd3-shape';
import { timeDay } from 'd3-time';
import { generateBackgroundComponent } from './backgroundUtils';
import { TimeRange } from 'src/models';
import { RunStyle } from 'src/components/Item/Run';
import { Scale } from 'src/utils/scale';
import { theme } from 'src/config/theme';
import { TIMELINE_NOW_UPDATE_MS } from 'src/constants';

const ALPHA = 0.04;
function calcRgbPart(hex: string) {
  // This function converts 2 characters of HEX into a decimal number
  // It does this by calculating a colour that when applied with an opacity of alpha
  // it will appear as the expected colour on a white background.
  const expected = parseInt(hex, 16);
  return (expected - 255) / ALPHA + 255;
}

// This could be replaced, by calculating it based on themes colours dynamically in the future
const e1 = calcRgbPart('e1');
const e2 = calcRgbPart('e2');
const fillStyle = `rgba(${e1},${e1},${e2},${ALPHA})`; // #fbfbfc
const d2 = calcRgbPart('d2');
const d3 = calcRgbPart('d3');
const strokeStyle = `rgba(${d2},${d2},${d3},${ALPHA})`; // #f2f2f3

function drawHatchPattern(
  ctx: CanvasRenderingContext2D,
  start: number,
  end: number,
  lineGap: number,
) {
  if (Math.abs(start - end) < 1e-5) {
    return;
  }
  const width = end - start;
  const { height } = ctx.canvas;
  const numLines = Math.floor(width / lineGap);
  if (isNaN(numLines) || numLines <= 0) return;
  const gap = (width - numLines * lineGap) / 2;

  // generate lines
  const lines = Array.from(Array(numLines).keys()).map((_, idx) => {
    // Populate the full lines in the diagonal hatch pattern
    const x = start + gap + idx * lineGap;
    return [
      [x, 0],
      [x + lineGap, height],
    ];
  });
  if (gap > 0) {
    // This function attempts to fill in the gap on the left and right of the hatched lines
    // The formula's have been optimised and required the lineGap and height to equal.
    // left most line
    const rightX = start + gap;
    const leftY = lineGap - gap;
    lines.push([
      [start, leftY],
      [rightX, height],
    ]);

    // right most line
    const leftX = end - gap;
    const rightY = gap;
    lines.push([
      [leftX, 0],
      [end, rightY],
    ]);
    // This is required so that the diagLines do not spill over the gaps
  }

  // Draw on canvas
  ctx.fillStyle = fillStyle;
  ctx.fillRect(start, 0, width, height);

  ctx.beginPath();
  ctx.strokeStyle = strokeStyle;
  // @ts-ignore // ignore following typescript error as the data's format does match
  lines.forEach(line().context(ctx));
  ctx.stroke();
}

interface DowntimeBackgroundProps {
  scale: Scale;
  downtime?: TimeRange[];
  lineGap?: number;
}

function generateBackground({
  scale,
  downtime = [],
  lineGap = 6,
}: DowntimeBackgroundProps): string {
  const [, bgWidth] = scale.range();

  const canvas = document.createElement('canvas');
  canvas.width = bgWidth;
  canvas.height = lineGap; // height has to equal line gap for drawHatchPattern to work correctly
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (const range of downtime) {
      const start = scale(range.startTime);
      const end = scale(range.endTime);
      drawHatchPattern(ctx, start, end, lineGap);
    }
  }

  // This function is called every `TIMELINE_NOW_UPDATE_MS` milliseconds,
  // so the rendered view will remain close to live
  const now = new Date();
  const start = scale(timeDay.floor(now));
  const end = scale(timeDay.ceil(now));

  if (ctx) {
    ctx.fillStyle = `${theme.secondaryAccent}10`;
    ctx.fillRect(start, 0, end - start, ctx.canvas.height);
  }

  return canvas.toDataURL();
}

const Styled = styled.div`
  background-repeat: repeat-y;

  width: 100%;
  position: absolute;

  pointer-events: none;

  .highlighted ${RunStyle} {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 1);
  }
`;

export const DowntimeBackground =
  generateBackgroundComponent<DowntimeBackgroundProps>(
    ['scale', 'downtime', 'lineGap'],
    generateBackground,
    Styled,
    TIMELINE_NOW_UPDATE_MS,
  );
