import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  DocumentNode,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import {
  authDisabled,
  kioskMode,
  getBackendToken,
} from 'src/services/auth.service';

import introspectionResult from 'src/generated/introspection-result';
import { env } from 'src/runtime-environment';

const DateConverter = {
  /** Converts the ISO date string stored in the cache into a Date object */
  read: (cachedValue: string): Date => new Date(cachedValue),
};

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        resources: {
          merge: false,
        },
      },
    },
    Resource: {
      fields: {
        items: {
          merge: false,
        },
      },
    },
    Clipboard: {
      fields: {
        items: {
          merge: false,
        },
      },
    },
    Change: {
      fields: {
        date: DateConverter,
      },
    },
    Item: {
      fields: {
        lastPositioned: DateConverter,
      },
    },
    Process: {
      fields: {
        lastPositioned: DateConverter,
      },
    },
    Run: {
      fields: {
        lastPositioned: DateConverter,
      },
    },
    Sink: {
      fields: {
        lastPositioned: DateConverter,
      },
    },
    Source: {
      fields: {
        lastPositioned: DateConverter,
      },
    },
    ResourceScheduleRange: {
      fields: {
        startTime: DateConverter,
        endTime: DateConverter,
      },
    },
    TimelineUsage: {
      fields: {
        startTime: DateConverter,
        endTime: DateConverter,
      },
    },
  },
});

const postprocessLink = new ApolloLink((operation, forward) => {
  if (!kioskMode) {
    // Only apply in kiosk mode
    return forward(operation);
  }

  if (operation.operationName !== 'TimelineResources') {
    return forward(operation);
  }

  return forward(operation).map((res) => {
    if (!res.data) return res;

    // We fetch the cached resource filter config (if any), from local storage,
    // where it would be saved. See `useLocalResourceFilter` in
    // `ResourceFilterDropdown.tsx` for more information.
    const cached = window.localStorage.getItem('local-resource-filter');
    const arr: string[] = cached ? JSON.parse(cached) : [];
    const hiddenResources = new Set(arr);

    return {
      ...res,
      data: {
        ...res.data,
        resources: res.data.resources.filter(
          (x: any) => !hiddenResources.has(x.id),
        ),
      },
    };
  });
});

const authLink = setContext(async (_, { headers }) => {
  if (authDisabled) return { headers };

  const token = await getBackendToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.accessToken}` : '',
    },
  };
});

function isSubscription(query: DocumentNode) {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
}

let client: ApolloClient<unknown> | null = null;
export const getApolloClient = (): ApolloClient<unknown> => {
  if (client) return client;

  const httpUri = env.GRAPHQL_URI;
  const httpLink = createHttpLink({ uri: httpUri });

  const wsLink = new WebSocketLink({
    uri: env.GRAPHQL_SUBSCRIPTION_URI,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        if (authDisabled) return {};
        const token = await getBackendToken();
        return {
          authToken: token ? `Bearer ${token.accessToken}` : '',
        };
      },
    },
  });

  const sentryLink = new SentryLink({
    uri: httpUri,
    setTransaction: true,
    setFingerprint: true,
    attachBreadcrumbs: {},
  });

  const splitLink = split(
    ({ query }) => isSubscription(query),
    wsLink,
    httpLink,
  );

  client = new ApolloClient({
    link: ApolloLink.from([postprocessLink, authLink, sentryLink, splitLink]),
    cache,
  });
  return client;
};
