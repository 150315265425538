import React from 'react';
import { useTheme } from 'styled-components';
import { GetItemQuery } from 'src/generated/graphql';
import { ItemBubble } from './InputOutput/ItemBubble';
import { ItemListContainer } from './ItemListContainer';
import { displayItemType } from 'src/models/item';

type Item = NonNullable<GetItemQuery['item']>;
type Run = Exclude<Item, { __typename: 'Process' | 'Sink' | 'Source' }>;

export interface RunUsagesProps {
  run: Run;
}

export const RunUsages: React.VFC<RunUsagesProps> = ({ run }) => {
  const theme = useTheme();
  return (
    <ItemListContainer
      style={{
        marginLeft: -24,
        width: 'calc(100% + 48px)',
        borderBottom: `solid 2px ${theme.lightTint}`,
      }}
    >
      <div className="header">Processes</div>
      {run.runUsages
        .map((x) => x.process)
        .map(({ id, title, colorway, taskIconId, __typename }) => (
          <ItemBubble
            key={id}
            remove={null}
            colorway={colorway}
            title={title ?? `${displayItemType(__typename)} #${id}`}
            taskIconId={taskIconId}
          />
        ))}
    </ItemListContainer>
  );
};
