import { useMatch } from 'react-router-dom';

export const Routes = {
  PLANNER: '/',
  DASHBOARD: '/resource/:resourceId',
  SETTINGS: '/settings',
  CHANGES: '/changes',
} as const;

export function useMatchDashboardRoute(): ID | undefined {
  const match = useMatch(Routes.DASHBOARD);
  return match?.params?.resourceId as ID | undefined;
}
