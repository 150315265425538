import React from 'react';
import styled from 'styled-components';
import { ItemTitleStyle, ItemSubtitleStyle } from './ItemStyles';
import { AfterHoursIndicator } from './AfterHoursIndicator';
import { ChangedHighlight } from './ChangedHighlight';
import { ErrorDash } from './ErrorDash';
import { ColorwayName, colorways, ItemColorway } from 'src/config/theme';
import { ItemState } from 'src/models/item';
import { ReactComponent as LockedIcon } from 'src/assets/icons/locked-item.svg';
import { clamp, secondsToHours } from 'src/utils';
import { getTextWidth } from 'src/utils/text';

const WIDTH_DURATION_CUTOFF = 40;
const HEIGHT_DURATION_ERROR_CUTOFF = 70;
const HEIGHT_DURATION_STANDARD_CUTOFF = 25;
const HEIGHT_DESCRIPTION_CUTOFF = 60;
const HEIGHT_NAME_CUTOFF = 35;
const HEIGHT_LOCKED_CUTOFF = 25;
const NORMAL_HEIGHT = 80;

export type ProcessProps = {
  onClick?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  data: ProcessDisplayData;
};

interface ProcessDisplayData {
  left: number;
  width: number;
  height: number;
  error: boolean;
  changed: boolean;
  locked: boolean;
  title: string;
  subtitle: string;
  duration: Seconds;
  state: ItemState;
  colorway: ColorwayName;
  taskIconId: string | null;
  hiddenIndicatorOffsets: number[];
  outputBufferOffset: number;
}

export const Process: React.VFC<ProcessProps> = ({
  onClick,
  onMouseMove,
  data: {
    left,
    width,
    height,
    error,
    changed,
    locked,
    title,
    subtitle,
    duration,
    state,
    colorway,
    taskIconId,
    hiddenIndicatorOffsets,
    outputBufferOffset,
  },
}) => {
  if (isNaN(width)) return null;
  const durationCutoff = error
    ? HEIGHT_DURATION_ERROR_CUTOFF
    : HEIGHT_DURATION_STANDARD_CUTOFF;

  const className = () => {
    switch (state) {
      case ItemState.Inactive:
        return 'inactive';
      case ItemState.Active:
        return 'active';
      case ItemState.Selected:
        return 'selected';
      default:
        return undefined;
    }
  };

  const iconDecrease = taskIconId ? height * 0.7 + 4 : 0;
  const contentWidth = Math.max(20, width + Math.min(0, left) - iconDecrease);
  return (
    <ProcessStyle
      title={title}
      className={className()}
      style={{ width, height }}
      onClick={onClick}
      onMouseMove={onMouseMove}
      {...colorways[colorway]}
    >
      <div className="vivid-strip" />
      {!!outputBufferOffset && (
        <div style={{ width: outputBufferOffset }} className="output-buffer" />
      )}
      {error && <ErrorDash borderRadius={4} borderSize={3} />}
      {changed && <ChangedHighlight borderRadius={4} borderSize={3} />}
      {state === ItemState.Selected && <div className="selected-border" />}
      <div className="content">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-end',
            minHeight: 19,
          }}
        >
          {height >= durationCutoff && width > WIDTH_DURATION_CUTOFF && (
            <div className="duration">
              <div>{secondsToHours(duration)}</div>
            </div>
          )}
        </div>
        <div
          style={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            marginTop: height < NORMAL_HEIGHT ? -13 : -9,
          }}
        >
          <div className="text" style={{ marginLeft: 3 - Math.min(0, left) }}>
            <div>
              {height >= HEIGHT_NAME_CUTOFF &&
                (locked && height >= HEIGHT_LOCKED_CUTOFF ? (
                  <div style={{ display: 'flex' }}>
                    <LockedIcon
                      style={{ marginRight: 5, width: 17, height: 24 }}
                    />
                    <ItemTitleStyle
                      style={{
                        fontSize: getFontSize(contentWidth - 23, title, 10, 18),
                      }}
                    >
                      {title}
                    </ItemTitleStyle>
                  </div>
                ) : (
                  <ItemTitleStyle
                    style={{
                      marginLeft: 2,
                      fontSize: getFontSize(contentWidth, title, 10, 18),
                    }}
                  >
                    {title}
                  </ItemTitleStyle>
                ))}
              {height >= HEIGHT_DESCRIPTION_CUTOFF && (
                <ItemSubtitleStyle
                  style={{
                    marginLeft: locked ? 14 : 2,
                    fontSize: getFontSize(
                      width - (locked ? 12 : 0),
                      subtitle,
                      8,
                      14,
                    ),
                  }}
                >
                  {subtitle}
                </ItemSubtitleStyle>
              )}
            </div>
          </div>
          {taskIconId != null && (
            <img
              src={`/assets/task-icons/${taskIconId}.svg`}
              alt="icon"
              style={{
                marginRight: 3,
                maxWidth: height * 0.65,
                maxHeight: height * 0.6,
                ...(state === ItemState.Selected
                  ? { filter: 'invert(100%)' }
                  : undefined),
              }}
            />
          )}
        </div>
      </div>

      {hiddenIndicatorOffsets.map((offset, i) => (
        <AfterHoursIndicator
          key={i}
          style={{ position: 'absolute', left: offset }}
          height={height}
        />
      ))}
    </ProcessStyle>
  );
};

export function getFontSize(
  processWidth: number,
  text: string,
  min: number,
  max: number,
) {
  const num = max * (processWidth - 20);
  const denom = getTextWidth(text, max, 'px');
  if (denom === 0) return min;
  return clamp(num / denom, min, max);
}

const ProcessStyle = styled.div<ItemColorway>`
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;

  &.inactive {
    opacity: 0.5;
    background-color: ${(props) => props.standardBackground};
  }

  &.active {
    background-color: ${(props) => props.standardBackground};
  }

  &.selected {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    background-color: ${(props) => props.selectedBackground};

    .duration {
      background-color: ${(props) => props.selectedBorder};
      z-index: 4;
    }

    .output-buffer {
      background-color: ${(props) => props.selectedBorder};
      height: 7px;
    }

    .content div {
      color: white;
    }
  }

  .selected-border {
    z-index: 2;
    pointer-events: none;
    border: solid 3px ${(props) => props.selectedBorder};
  }

  .vivid-strip {
    z-index: 1;
    min-width: 3px;
    height: 100%;
    border-radius: 4px 0 0 4px;
    background-color: ${(props) => props.selectedBackground};
  }

  .output-buffer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    background-color: ${(props) => props.durationBackground};
  }

  .duration {
    border-radius: 0 4px 0 4px;
    padding: 4px 8px;
    background-color: ${(props) => props.durationBackground};
    min-height: 19px;
    font-size: 12px;
    font-weight: 600;
    flex-grow: 0;
    color: #fff;

    & div {
      line-height: 13px;
    }
  }

  .content {
    flex: 1 1;
    max-height: calc(80px - 8px);
    min-width: 27px;
    width: calc(100% - 43px);

    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .text {
      width: 100%;
      margin: 4px 6px 4px 0;
      display: flex;

      & > div {
        width: 100%;
      }
    }
  }

  .selected-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;
