import { TimeRange } from 'src/models';

export const getRangeDuration = (startTime: Date, endTime: Date): Seconds =>
  ((endTime.getTime() - startTime.getTime()) / 1000) as Seconds;

// Assumes that no downtimes overlap
export function getDuration(
  startTime: Date,
  endTime: Date,
  downtimes: TimeRange[],
): Seconds {
  if (startTime > endTime) {
    return -getDuration(endTime, startTime, downtimes) as Seconds;
  }

  const duration = getRangeDuration(startTime, endTime);
  const containedDowntimes = downtimes
    .filter((d) => d.endTime > startTime && d.startTime < endTime)
    .reduce(
      (acc, range) =>
        acc +
        getRangeDuration(
          new Date(Math.max(range.startTime.getTime(), startTime.getTime())),
          new Date(Math.min(range.endTime.getTime(), endTime.getTime())),
        ),
      0,
    );
  return (duration - containedDowntimes) as Seconds;
}
