import React from 'react';
import * as Sentry from '@sentry/react';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import { Button, ButtonGroup } from '@mui/material';
import { TimeRange } from 'src/models';
import { CreateItemLocation } from './reducer';
import { useCurrentPeriodScale } from 'src/utils/scale';
import { createTimelineItem } from 'src/server/items';
import { useApolloClient } from '@apollo/client';

export type CreateItemButtonsProps = CreateItemLocation & {
  schedule: TimeRange[];
  onCreated: (id: ID) => void;
};

export const CreateItemButtons: React.VFC<CreateItemButtonsProps> = ({
  onCreated,
  schedule,
  allowedTypes,
  resourceId,
  pos,
}) => {
  const scale = useCurrentPeriodScale();
  const apollo = useApolloClient();

  const startTime = roundToNearestMinutes(scale.invert(pos.x), {
    nearestTo: 15,
  });

  const inDowntime = !!schedule.find(
    (x) => x.startTime <= startTime && startTime < x.endTime,
  );

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 10,
        width: 'fit-content',
        top: pos.y + 15, // Adding a margin to prevent triple click issues
        left: pos.x,
        transform: 'translateX(-50%)',
      }}
    >
      <ButtonGroup variant="contained" size="small">
        {inDowntime ? (
          <Button disabled>Cannot create item in downtime</Button>
        ) : (
          allowedTypes.map((type) => (
            <Button
              key={type}
              onClick={() => {
                createTimelineItem(apollo, {
                  type,
                  resourceId,
                  startTime,
                })
                  .then((id) => onCreated(id))
                  .catch(Sentry.captureException);
              }}
            >
              Add {type}
            </Button>
          ))
        )}
      </ButtonGroup>
    </div>
  );
};
