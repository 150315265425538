import * as Sentry from '@sentry/react';
import { ApolloClient } from '@apollo/client';
import { DragSourceMonitor } from 'react-dnd';
import { Scale } from 'src/utils/scale';
import { RunLockedDocument } from 'src/generated/graphql';
import { Position, TimeRange } from 'src/models';
import { RootState, AppDispatch } from 'src/store';
import {
  moveItem,
  shiftItemsOnTimeline,
  moveOutOfCollision,
} from './item-movement';

export interface ResourceDrop {
  type: 'resource';
  resourceId: ID;
  inventoryWorkstationId: ID | null;
}

export interface RunDrop {
  type: 'run';
  runId: ID;
  /**
   * Can be used to ensure process can't be dropped into runs on the timeline
   * when they shouldn't be (when the resource the run is sitting on has a
   * different `inventoryWorkstationId` to that of the process)
   */
  resourceInventoryWorkstationId: ID | null;
}

export interface ClipboardDrop {
  type: 'clipboard';
}

export type SimpleDropData = ResourceDrop | RunDrop | ClipboardDrop;

export type DropData<T extends SimpleDropData = SimpleDropData> = T & {
  clientOffset: Position;
};

export async function drop({
  apollo,
  state,
  dispatch,
  scale,
  monitor,
  resources,
}: {
  apollo: ApolloClient<unknown>;
  state: RootState;
  dispatch: AppDispatch;
  scale: Scale;
  monitor: DragSourceMonitor;
  resources: Array<{ id: ID; schedule: TimeRange[] }>;
}): Promise<void> {
  const dropItem = monitor.getItem();
  if (dropItem == null) return;
  const itemId: ID = (dropItem as any).data.itemId;
  const dropResult: DropData | null = monitor.getDropResult();
  if (dropResult == null) return;

  if (dropResult.type === 'clipboard') {
    await moveItem(apollo, dispatch, {
      type: 'clipboard',
      itemId,
    }).catch((error) => {
      Sentry.captureException(error);
    });
    return;
  }

  if (dropResult.type === 'resource') {
    const resource = resources.find((r) => r.id === dropResult.resourceId);
    const startTime = getDroppedStartTime(
      scale,
      resource!.schedule,
      dropResult,
    );

    const selected = state.planner.selectedItems;
    if (selected.has(itemId) && selected.size > 1) {
      await shiftItemsOnTimeline(apollo, dispatch, {
        initiator: {
          itemId,
          startTime,
        },
        followers: [...selected.values()].filter((id) => id !== itemId),
        resources,
      });
      return;
    }

    await moveItem(apollo, dispatch, {
      type: 'timeline',
      itemId,
      resourceId: dropResult.resourceId,
      startTime,
    }).catch((error) => {
      Sentry.captureException(error);
    });

    return;
  }

  const { data } = await apollo.query({
    fetchPolicy: 'cache-first',
    variables: { id: dropResult.runId },
    query: RunLockedDocument,
  });

  if (!data.item.locked) {
    await moveItem(apollo, dispatch, {
      type: 'run',
      processId: (dropItem as any).data.itemId,
      runId: dropResult.runId,
      duration: null,
      endTime: null,
      index: null,
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }
}

export function getDroppedStartTime(
  scale: Scale,
  downtimes: TimeRange[],
  dropResult: DropData,
) {
  const clientOffset = dropResult.clientOffset;
  const unroundedTime = scale.invert(clientOffset.x);
  return moveOutOfCollision(unroundedTime, downtimes);
}
