import React from 'react';
import styled from 'styled-components';
import { Input, InputProps } from '@mui/material';

interface CustomTextBoxProps {
  setValue: (v: string) => void;
  inputStyle?: React.CSSProperties;
}

export type TextBoxProps = CustomTextBoxProps & InputProps;

export const TextBox: React.VFC<TextBoxProps> = ({
  setValue,
  inputStyle,
  inputProps,
  ...extraProps
}) => (
  <Container
    onChange={(e) => setValue(e.target.value)}
    inputProps={{
      // Tell LastPass not to suggest passwords in this field
      'data-lpignore': true,
      autoComplete: 'false',
      style: inputStyle,
      ...inputProps,
    }}
    className={extraProps.disabled ? undefined : 'enabled'}
    classes={{
      focused: 'focused',
      input: 'input',
      underline: 'underline',
    }}
    {...extraProps}
  />
);

const Container = styled(Input)`
  margin-bottom: 5px;

  .input {
    outline: none;
    padding: 14px 10px 12px 10px;
    height: 24px;
    font-weight: 500;
    background-color: ${(props) => props.theme.lightTint};
    color: ${(props) => props.theme.mainAccent};
  }

  ::before {
    border-bottom: none !important;

    .focused {
      border-bottom: 2px solid ${(props) => props.theme.secondaryAccent} !important;
    }
  }

  &:not(.enabled).underline::after {
    border-bottom: none !important;
  }

  &.enabled:hover::before {
    border-bottom: 2px solid ${(props) => props.theme.mainAccent} !important;
  }
`;
