import { useMediaQuery } from '@mui/material';

export const MOBILE_MEDIA = '(max-width: 1050px)';
export const MOBILE_MEDIA_PORTRAIT =
  '(max-width: 480px) and (orientation: portrait)';

export function useIsMobile(): boolean {
  return useMediaQuery(MOBILE_MEDIA);
}

export function useIsMobilePortrait(): boolean {
  return useMediaQuery(MOBILE_MEDIA_PORTRAIT);
}
