import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getApolloClient } from 'src/services/apollo.service';
import {
  authDisabled,
  authenticationRequest,
  getProvider,
} from 'src/services/auth.service';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import '@mui/styles';
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { materialOverrides } from 'src/config/material-overrides';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { theme } from 'src/config/theme';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { store } from 'src/store';
import { InteractionType } from '@azure/msal-browser';
import { App } from './App';
import { useAppDispatch } from '../store/hooks';
import { useEffect } from 'react';
import { UrlReactor } from './UrlReactor';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();

export const Root: React.VFC = () => (
  <ReduxProvider store={store}>
    <MicrosoftAuth>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={getApolloClient()}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={materialOverrides}>
                <ThemeProvider theme={theme}>
                  <BrowserRouter>
                    <DndProvider backend={HTML5Backend}>
                      <UrlReactor />
                      <App />
                    </DndProvider>
                  </BrowserRouter>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </MicrosoftAuth>
  </ReduxProvider>
);

const MicrosoftAuth: React.FC = ({ children }) => {
  if (authDisabled) {
    return <>{children}</>;
  }

  return (
    <MsalProvider instance={getProvider()}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authenticationRequest}
      >
        <MicrosoftAuthInner>{children}</MicrosoftAuthInner>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

const MicrosoftAuthInner: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  // If this component renders, we are logged in. We feed the account info to
  // redux. This happens only when the component is mounted
  useEffect(() => {
    dispatch({ type: 'AAD_LOGIN_SUCCESS' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
