import { Resizable as Resize } from 're-resizable';
import { ITEM_RESIZE_HANDLE_WIDTH } from '../../constants';

export interface ResizableProps {
  width: number;
  height: number;
  disabled: boolean;
  onResize: (leftDelta: number, rightDelta: number) => void;
  onResizeStop: () => void;
  children: React.ReactNode;
}

export const Resizable: React.VFC<ResizableProps> = ({
  width,
  height,
  disabled,
  onResize,
  onResizeStop,
  children,
}) => {
  const handleWidth = Math.min(width, ITEM_RESIZE_HANDLE_WIDTH);
  return (
    <Resize
      handleStyles={{
        left: { width: handleWidth },
        right: { width: handleWidth },
      }}
      enable={{
        right: !disabled,
        left: !disabled,
      }}
      onResizeStart={() => {}}
      onResize={(_ev, dir, _ref, delta) => {
        if (dir === 'left') {
          onResize(delta.width, 0);
        } else if (dir === 'right') {
          onResize(0, delta.width);
        }
      }}
      onResizeStop={onResizeStop}
      size={{
        width,
        height,
      }}
    >
      {children}
    </Resize>
  );
};
