import React from 'react';
import styled from 'styled-components';

interface ErrorDashStyleProps {
  borderRadius: number;
  borderSize: number;
  grow?: boolean;
}

export interface ErrorDashProps extends ErrorDashStyleProps {
  style?: React.CSSProperties;
}

export const ErrorDash: React.VFC<ErrorDashProps> = ({
  borderRadius,
  borderSize,
  grow,
  style,
}) => (
  <ErrorDashStyle
    borderRadius={borderRadius}
    borderSize={borderSize}
    grow={grow}
  >
    <div className="bottom" style={style} />
    <div className="top" style={style} />
  </ErrorDashStyle>
);

const ErrorDashStyle = styled.div<ErrorDashStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${(props) =>
    props.grow &&
    `\
    top: -${props.borderSize}px;
    left: -${props.borderSize}px;
    width: calc(100% + ${props.borderSize * 2}px);
    height: calc(100% + ${props.borderSize * 2}px);
  `}

  z-index: 3;

  .top,
  .bottom {
    border-radius: ${(props) => props.borderRadius}px;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .top {
    border: ${(props) => props.borderSize}px dashed
      ${(props) => props.theme.errorPrimary};
  }

  .bottom {
    border: ${(props) => props.borderSize}px solid
      ${(props) => props.theme.errorSecondary};
  }
`;
