import { timeDay, timeSunday } from 'd3-time';
import { TimeRange } from 'src/models';
import { makeSkipSunday } from './d3fc/skipSunday';

export function getHiddenRanges(start: Date, end: Date): TimeRange[] {
  const curSunBegin = timeSunday.floor(start);
  return timeSunday.range(curSunBegin, end).map((sun) => ({
    startTime: sun,
    endTime: timeDay.offset(sun, 1),
  }));
}

export const SkipSunday = makeSkipSunday();
