import { ApolloClient } from '@apollo/client';
import {
  ResizeItemOnTimelineCommandInput,
  ResizeItemOnTimelineDocument,
  ResizeItemOnTimelineMutationFn,
} from 'src/generated/graphql';

export function resizeItem(
  apolloClient: ApolloClient<unknown>,
  input: ResizeItemOnTimelineCommandInput,
) {
  return (apolloClient.mutate as ResizeItemOnTimelineMutationFn)({
    mutation: ResizeItemOnTimelineDocument,
    variables: { input },
  });
}
