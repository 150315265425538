import React, { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Button, LinearProgress, Typography } from '@mui/material';
import { ConfirmationModal } from 'src/components/Util/ConfirmationModal';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { deselectItem } from 'src/store/planner';
import { closeItemSideDrawer } from 'src/store/drawer';
import { deleteChain } from 'src/server/chains';
import { deleteItem } from 'src/server/item-delete';
import * as drawer from 'src/server/drawer';
import { AccountSelectors } from 'src/store/login';
import { moveItem, moveDuration } from 'src/server/item-movement';
import { GetItemQuery } from 'src/generated/graphql';
import { useCachedResourceSchedule } from 'src/server/resources';
import { useApolloClient } from '@apollo/client';
import { useHotkeys } from 'react-hotkeys-hook';

import { ItemSideDrawerHeader } from './ItemSideDrawerHeader';
import { MainItemFields } from './MainItemFields';
import { DatePicker } from './DatePicker';
import { InputOutput } from './InputOutput';
import { OutputBuffer } from './OutputBuffer';
import { Details } from './Details';
import { Description } from './Description';
import { TaskIcons } from './TaskIcons';
import { ItemChanges } from './ItemChanges';
import { RunUsages } from './RunUsages';
import { useItemChanges } from 'src/server/changes';

export interface ItemSideDrawerContentsProps {
  editingRun: boolean;
  editRun: () => void;
}

export const ItemSideDrawerContents: React.FC<ItemSideDrawerContentsProps> = ({
  editingRun,
  editRun,
}) => {
  const [itemId, autoFocus] = useAppSelector(({ drawer }) => [
    drawer.editingItemId,
    drawer.autoFocus,
  ]);

  const changes = useItemChanges(itemId);

  if (itemId == null) {
    throw new Error('Not valid');
  }

  const dispatch = useAppDispatch();
  const close = useCallback(() => {
    dispatch(closeItemSideDrawer());
  }, [dispatch]);

  const canEdit = useAppSelector(AccountSelectors.canEdit);

  const { error, data, refetch } = drawer.useGetItem(itemId);

  const schedule = useCachedResourceSchedule(
    data?.item?.timelineUsage?.resourceId ?? null,
  );

  const client = useApolloClient();
  const ae = document.activeElement as any;
  const selectedItemStartTime = data?.item?.timelineUsage?.startTime?.getTime();

  const canEject =
    canEdit && data?.item?.timelineUsage?.resource.allowProcesses;

  const itemInRun =
    data?.item?.__typename === 'Process' && !!data.item.runUsage;

  const [moving, setMoving] = useState(false);
  const handleHotkeyMoveItem = (left: boolean, bigShift?: boolean) => {
    if (itemInRun) return;
    if (moving) return;
    if (ae!.tagName === 'INPUT' || ae!.tagName === 'TEXTAREA') return;
    if (ae!.tagName && ae!.blur) {
      ae!.blur();
    }
    if (selectedItemStartTime && data?.item && schedule) {
      // bigShift moves left or right 6 hours
      const minutesShift = bigShift ? 360 : 15;
      setMoving(true);
      moveItem(client, dispatch, {
        type: 'timeline',
        itemId,
        resourceId: data.item.timelineUsage?.resourceId ?? null,
        startTime: moveDuration(
          new Date(selectedItemStartTime),
          minutesShift * 60 * 1000 * (left ? -1 : 1),
          schedule,
        ),
      })
        .then(() => setMoving(false))
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  };

  useHotkeys('left', () => handleHotkeyMoveItem(true), {}, [
    handleHotkeyMoveItem,
  ]);

  useHotkeys('right', () => handleHotkeyMoveItem(false), {}, [
    handleHotkeyMoveItem,
  ]);

  useHotkeys('shift+left', () => handleHotkeyMoveItem(true, true), {}, [
    handleHotkeyMoveItem,
  ]);

  useHotkeys('shift+right', () => handleHotkeyMoveItem(false, true), {}, [
    handleHotkeyMoveItem,
  ]);

  const [deleteModal, setDeleteModal] = useState(false);
  const onDeleteItem = () =>
    deleteItem(client, itemInRun ? null : dispatch, itemId).then(() => {
      close();
      dispatch(deselectItem(itemId));
    });

  const setItemLocked = (locked: boolean) => {
    drawer
      .setItemLocked(client, { itemId, locked })
      .then(() => refetch())
      .catch(console.error);
  };

  const cloneItem = () => {
    drawer
      .cloneItem(client, { id: itemId })
      .then(() => close())
      .catch(console.error);
  };

  const { item } = data || {};

  if (!error && !item) return <LinearProgress />;
  if (error) return <>Error! {error.message}</>;

  if (!item) {
    throw new Error('No item...');
  }

  const simple = editingRun && item.__typename !== 'Run';

  const map: Record<
    string,
    'sourceData' | 'sinkData' | 'processData' | 'runData'
  > = {
    Source: 'sourceData',
    Sink: 'sinkData',
    Process: 'processData',
    Run: 'runData',
  };
  const key = map[item.__typename];
  if (!key) throw new Error('Unsupported item type');

  return (
    <>
      <ItemSideDrawerHeader
        onCloseDrawer={close}
        canEdit={canEdit}
        editRun={item.__typename === 'Run' ? editRun : null}
        showIcons={!itemInRun}
        itemLocked={item.locked}
        setItemLocked={setItemLocked}
        onItemClone={cloneItem}
        onDeleteItem={
          (item.__typename === 'Run' || item.__typename === 'Process') &&
          !itemInRun
            ? () => setDeleteModal(true)
            : onDeleteItem
        }
        resourceName={getItemResource(item)}
        itemId={item.id}
        itemType={item.__typename}
      />
      {(item.__typename === 'Source' ||
        item.__typename === 'Sink' ||
        item.__typename === 'Process' ||
        item.__typename === 'Run') && (
        <MainItemFields
          onCloseDrawer={close}
          itemId={item.id}
          disabled={item.locked || !canEdit}
          itemType={item.__typename}
          inRun={simple}
          fieldDefinitions={item.resourceType[key].fields}
          colorway={(item as any).colorway || null}
          autoFocus={autoFocus}
        />
      )}

      {item.__typename === 'Process' && item.inventoryEntityId != null && (
        <Typography
          style={{ padding: '1em 5px 0 5px', fontWeight: 500 }}
          align="center"
        >
          TimberSmart ID: {item.inventoryEntityId}
        </Typography>
      )}

      {(item.__typename === 'Source' || item.__typename === 'Sink') &&
        item.timelineUsage != null && (
          <DatePicker
            disabled={item.locked || !canEdit}
            serverStartTime={item.timelineUsage.startTime}
            hideDuration={false}
            item={{
              id: item.id,
              duration: null,
              inRun: false,
            }}
          />
        )}
      {(item.__typename === 'Process' || item.__typename === 'Run') &&
        item.timelineUsage != null && (
          <DatePicker
            disabled={item.locked || !canEdit}
            hideDuration={simple}
            serverStartTime={item.timelineUsage.startTime}
            serverEndTime={item.timelineUsage.endTime}
            item={{
              id: item.id,
              duration: item.duration,
              inRun: itemInRun,
            }}
          />
        )}
      {item.__typename === 'Process' && itemInRun && (
        <Button
          style={{ marginTop: '2em' }}
          disabled={!canEject}
          onClick={(e) => {
            e.stopPropagation();
            moveItem(client, dispatch, {
              type: 'timeline',
              itemId: item.id,
              resourceId: null,
              startTime: item.timelineUsage!.startTime,
            }).catch((error) => {
              Sentry.captureException(error);
            });
          }}
        >
          Eject from Run
        </Button>
      )}
      {item.timelineUsage !== null && (
        <InputOutput
          inputs={item.inputs}
          outputs={item.outputs}
          canEdit={canEdit}
          onRemoveInput={(source) => {
            deleteChain(client, {
              sourceItemId: source,
              targetItemId: itemId,
            });
          }}
          onRemoveOutput={(target) => {
            deleteChain(client, {
              sourceItemId: itemId,
              targetItemId: target,
            });
          }}
        />
      )}
      {item.__typename === 'Run' && <RunUsages run={item} />}
      {(item.__typename === 'Process' || item.__typename === 'Run') && (
        <OutputBuffer
          id={itemId!}
          disabled={item.locked || !canEdit}
          serverLag={item.lag ?? null}
          duration={item.duration}
        />
      )}

      <Details />

      <Description
        itemId={itemId!}
        disabled={item.locked || !canEdit}
        description={item.description || ''}
      />
      {item.__typename === 'Process' && (
        <TaskIcons
          resourceTypeId={item.resourceType.id}
          processId={itemId}
          disabled={item.locked || !canEdit}
          taskIconId={(item as any).taskIconId}
        />
      )}

      <ItemChanges data={changes.data} error={changes.error} />

      {deleteModal && (
        <ConfirmationModal
          confirm={onDeleteItem}
          confirmButtonText="DELETE"
          cancel={() => setDeleteModal(false)}
          headingText="Delete the item"
          explanatoryText="Are you sure?"
        />
      )}
    </>
  );
};

function getItemResource(item: NonNullable<GetItemQuery['item']>) {
  return (item.timelineUsage?.resource ?? item.resourceType).name;
}
