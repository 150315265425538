import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DrawerState {
  readonly editingItemId: ID | null;
  readonly autoFocus: boolean;
  readonly addingChain: 'input' | 'output' | null;
}

const initialState: DrawerState = {
  editingItemId: null,
  autoFocus: false,
  addingChain: null,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openItemSideDrawer(
      state,
      action: PayloadAction<{ id: ID; autoFocus: boolean }>,
    ) {
      state.editingItemId = action.payload.id;
      state.autoFocus = action.payload.autoFocus;
    },
    closeItemSideDrawer(state) {
      state.editingItemId = null;
    },
    addChain(state, action: PayloadAction<'input' | 'output'>) {
      state.addingChain = action.payload;
    },
    clearChain(state) {
      state.addingChain = null;
    },
  },
});

export const { openItemSideDrawer, closeItemSideDrawer, addChain, clearChain } =
  drawerSlice.actions;

export const drawerReducer = drawerSlice.reducer;
