import * as Schema from 'src/generated/graphql';
import { Position } from '.';

export type Item = Schema.Process | Schema.Run | Schema.Source | Schema.Sink;
export type ItemType = Item['__typename'];

export const ITEM_TYPES: ItemType[] = ['Process', 'Run', 'Source', 'Sink'];

export type SpanningItem = Schema.Process | Schema.Run;
export type SpanningItemType = SpanningItem['__typename'];
export type PointItem = Schema.Source | Schema.Sink;
export type PointItemType = PointItem['__typename'];

export const isSpanningItem = (item: Item): item is SpanningItem =>
  item.__typename === 'Process' || item.__typename === 'Run';

export const isPointItem = (item: Item): item is PointItem =>
  item.__typename === 'Source' || item.__typename === 'Sink';

export function assumeItemType<Type extends ItemType>(item: {
  __typename: ItemType;
}): asserts item is { __typename: Type } {
  // Assuming...
}

export function displayItemType(type: ItemType): string {
  // Could be different if item types weren't pleasant
  return type;
}

export enum ItemState {
  Inactive,
  Active,
  Selected,
}

export interface BasicItemLayoutData {
  left: number;
  right: number;
  top: number;
  height: number;
}

export type SourceLayoutData = BasicItemLayoutData & {
  item: Schema.Source;
};
export type SinkLayoutData = BasicItemLayoutData & {
  item: Schema.Sink;
};

export type PointItemLayoutData = SourceLayoutData | SinkLayoutData;

export type RunLayoutData = BasicItemLayoutData & {
  item: Schema.Run;
  hiddenIndicatorOffsets: number[];
  outputBufferOffset: number;
  processes: Array<{
    left: number;
    right: number;
    outputBufferOffset?: number;
  }>;
};

export type ProcessLayoutData = BasicItemLayoutData & {
  item: Schema.Process;
  inRun: boolean;
  outputBufferOffset: number;
  hiddenIndicatorOffsets: number[];
};

export type SpanningItemLayoutData = RunLayoutData | ProcessLayoutData;

export type ItemLayoutData = BasicItemLayoutData & { item: Item };

export class ItemLayoutUtils {
  static inputTimelinePosition(
    data: ItemLayoutData,
    resourceTop: number,
  ): Position {
    const type = data.item.__typename;

    if (type === 'Sink' || type === 'Source') {
      return {
        x: data.left - 10,
        y: resourceTop + data.top,
      };
    }

    return {
      x: data.left,
      y: resourceTop + data.top + data.height * 0.5,
    };
  }

  static chainCreateButtonRightPosition(
    data: ItemLayoutData,
    resourceTop: number,
  ): Position {
    const type = data.item.__typename;
    if (type === 'Sink' || type === 'Source') {
      return this.outputTimelinePosition(data, resourceTop);
    }

    return {
      x: data.right,
      y: resourceTop + data.top + data.height * 0.5,
    };
  }

  static outputTimelinePosition(
    data: ItemLayoutData,
    resourceTop: number,
  ): Position & { fromBuffer: boolean } {
    const type = data.item.__typename;
    if (type === 'Sink' || type === 'Source') {
      return {
        x: data.left + 10,
        y: resourceTop + data.top,
        fromBuffer: false,
      };
    }

    if (type === 'Process' || type === 'Run') {
      const p = data as ProcessLayoutData | RunLayoutData;
      if (p.item.lag !== null) {
        return {
          x: p.left + p.outputBufferOffset!,
          y: resourceTop + p.top + p.height,
          fromBuffer: true,
        };
      }
    }

    return {
      x: data.right,
      y: resourceTop + data.top + data.height * 0.5,
      fromBuffer: false,
    };
  }
}

export interface ItemInteractionProps {
  state: ItemState;
  canEdit: boolean;
  onClick: (ev: React.MouseEvent) => void;
  onMouseMove?: (
    item: Pick<Item, 'id' | '__typename'> | null,
    ev: React.MouseEvent,
  ) => void;
}
