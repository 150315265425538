import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import styled from 'styled-components';
import { ReactComponent as RemoveChainIcon } from 'src/assets/icons/chain-remove.svg';

export interface RemoveChainButtonProps {
  onClick: () => void;
}

export const RemoveChainButton: React.VFC<RemoveChainButtonProps> = ({
  onClick,
}) => (
  <Button onClick={onClick}>
    <RemoveChainIcon />
  </Button>
);

const Button = styled(ButtonBase)`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: solid 2px #4a4a4a;
  background-color: #fff;
`;
