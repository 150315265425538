import React from 'react';
import styled from 'styled-components';

export const Details: React.VFC = () => (
  <Container>
    <div className="text">Details</div>
  </Container>
);

const Container = styled.div`
  margin-top: 28px;

  .text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 9px;
  }
`;
