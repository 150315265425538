import React from 'react';
import * as Sentry from '@sentry/react';
import { SpanningItemLayoutData } from 'src/models/item';
import { useCurrentPeriodScale } from 'src/utils/scale';
import { TimeRange } from 'src/models';
import { getBounds } from 'src/components/Resources/position-items';
import { roundToNearestMinutes } from 'date-fns';
import { getDuration } from 'src/utils/scale/rangeDuration';
import { ItemPositioner } from './ItemPositioner';
import { resizeItem } from 'src/server/item-resize';

import { useHasChanged } from 'src/store/planner';
import { DraggableResizableContainer } from 'src/components/DragDrop/DraggableResizableContainer';
import { MovementTimestamp } from 'src/components/DragDrop/MovementTimestamp';
import { timeFormat } from 'd3-time-format';
import { FORMAT_TIME } from 'src/constants';
import { useApolloClient } from '@apollo/client';
import { useItemDragDropContext } from '../Resources/TimelineContext';

const timeFormatter = timeFormat(FORMAT_TIME);

export const SpanningItemWrapper: React.VFC<
  SpanningItemLayoutData & {
    children: (data: {
      left: number;
      width: number;
      outputBufferOffset: number;
      hiddenIndicatorOffsets: number[];
      duration: Seconds;
      changed: boolean;
    }) => React.ReactNode;
    resizing: any;
    setResizing: any;
    hiddenTimes: TimeRange[];
    schedule: TimeRange[];
    canEdit: boolean;

    outputBufferOffset?: number;
    hiddenIndicatorOffsets?: number[];
  }
> = ({
  top,
  height,
  item,
  children,
  resizing,
  setResizing,
  outputBufferOffset,
  hiddenIndicatorOffsets,
  hiddenTimes,
  schedule,
  canEdit,
}) => {
  const { onDrag, onDrop } = useItemDragDropContext();

  const client = useApolloClient();
  const scale = useCurrentPeriodScale();

  const isResizing = resizing && resizing.itemId === item.id;

  const startTime = isResizing
    ? resizing!.startTime
    : item.timelineUsage!.startTime;

  let endTime = item.timelineUsage!.endTime;
  if (isResizing) {
    endTime = resizing!.endTime;
    const inDowntime = schedule.find(
      (d) => d.endTime >= endTime && d.startTime <= endTime,
    );

    if (inDowntime) endTime = inDowntime.startTime;
  }

  const duration = getDuration(startTime, endTime, schedule);
  if (
    isResizing &&
    (item.__typename === 'Process' || item.__typename === 'Run')
  ) {
    const bounds = getBounds(
      {
        ...item,
        duration,
        timelineUsage: {
          ...item.timelineUsage!,
          startTime,
          endTime,
        },
      },
      {
        scale,
        hiddenTimes,
        downtimes: [...schedule].sort(
          (a, b) => a.startTime.getTime() - b.startTime.getTime(),
        ),
      },
    );

    outputBufferOffset = bounds.outputBufferOffset!;
    hiddenIndicatorOffsets = bounds.hiddenIndicatorOffsets!;
  }

  const left = scale(startTime);
  const right = scale(endTime);

  const itemElement = children({
    left,
    width: right - left,
    hiddenIndicatorOffsets: hiddenIndicatorOffsets!,
    outputBufferOffset: outputBufferOffset!,
    duration,
    changed: useHasChanged(item.lastPositioned ?? null),
  });

  const resizingEnd = isResizing
    ? resizing!.startTime.getTime() === item.timelineUsage!.startTime.getTime()
    : false;

  return (
    <ItemPositioner
      id={`item-${item.id}`}
      left={left}
      top={top}
      height={height}
    >
      {item.locked || !canEdit ? (
        itemElement
      ) : (
        <DraggableResizableContainer
          id={item.id}
          targetKey={item.__typename!}
          dragData={{
            itemId: item.id,
            inventoryWorkstationIds: getInventoryWorkstationIds(item),
          }}
          onDrag={onDrag}
          onDrop={onDrop}
          left={left}
          right={right}
          height={height}
          onResize={(leftDelta, rightDelta) => {
            const newStartTime = roundToNearestMinutes(
              scale.invert(scale(item.timelineUsage!.startTime) - leftDelta),
              { nearestTo: 15 },
            );
            const newEndTime = roundToNearestMinutes(
              scale.invert(scale(item.timelineUsage!.endTime) + rightDelta),
              { nearestTo: 15 },
            );

            setResizing({
              itemId: item.id,
              startTime: newStartTime,
              endTime: newEndTime,
            });
          }}
          onResizeStop={() => {
            resizeItem(client, {
              itemId: item.id,
              startTime: startTime,
              endTime: endTime,
            }).catch((error) => {
              Sentry.captureException(error);
            });
            setResizing(null);
          }}
          dragDisabled={isResizing || item.locked}
          resizeDisabled={item.locked}
        >
          {isResizing && (
            <MovementTimestamp style={{ right: resizingEnd ? 0 : undefined }}>
              {timeFormatter(resizing![resizingEnd ? 'endTime' : 'startTime'])}
            </MovementTimestamp>
          )}
          {itemElement}
        </DraggableResizableContainer>
      )}
    </ItemPositioner>
  );
};

type InventoryWorkstationFields =
  | { __typename: 'Sink' | 'Source' }
  | {
      __typename: 'Process';
      inventoryWorkstationId?: ID | null;
    }
  | {
      __typename: 'Run';
      runUsages: Array<{
        process: {
          inventoryWorkstationId?: ID | null;
        };
      }>;
    };

export function getInventoryWorkstationIds(item: InventoryWorkstationFields) {
  const inventoryWorkstationIds: Array<ID | null> = [];
  if (item.__typename === 'Process') {
    inventoryWorkstationIds.push(item.inventoryWorkstationId ?? null);
  }
  if (item.__typename === 'Run') {
    inventoryWorkstationIds.push(
      ...item.runUsages.map((x) => x.process.inventoryWorkstationId ?? null),
    );
  }
  return inventoryWorkstationIds;
}
