import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { useWindowWidth } from '@react-hook/window-size';
import { TimeRange } from 'src/models';
import {
  ItemState,
  ItemLayoutData,
  PointItemLayoutData,
  RunLayoutData,
  Item,
  ItemType,
  ProcessLayoutData,
} from 'src/models/item';
import { Run } from 'src/generated/graphql';
import { useAppSelector } from 'src/store/hooks';
import { AccountSelectors } from 'src/store/login';

import { TimelineProcess } from 'src/components/Item/TimelineProcess';
import { TimelineRun } from 'src/components/Item/TimelineRun';
import { TimelinePointItem } from 'src/components/Item/TimelinePointItem';

interface ResourceItemsProps {
  createChain?: (item: { id: ID; __typename: ItemType }) => void;
  onMouseMove?: (
    item: Pick<Item, 'id' | '__typename'>,
    ev: React.MouseEvent,
  ) => void;

  onSelectItem: (id: ID, modifier: 'shift' | 'ctrl' | null) => void;
  selectedItems: Set<ID>;

  resourceInventoryWorkstationId: ID | null;
  hiddenTimes: TimeRange[];
  schedule: TimeRange[];

  items: ItemLayoutData[];

  itemStateOverrides: Record<ID, ItemState>;
}

export const ResourceItems: React.VFC<ResourceItemsProps> = ({
  createChain,
  onMouseMove,

  onSelectItem,
  selectedItems,

  resourceInventoryWorkstationId,
  hiddenTimes,
  schedule,

  items,

  itemStateOverrides,
}) => {
  const windowWidth = useWindowWidth();
  const [resizing, setResizing] = useState<{
    itemId: ID;
    startTime: Date;
    endTime: Date;
  } | null>(null);

  const canEdit = useAppSelector(AccountSelectors.canEdit);

  return (
    <>
      {items.map((data) => {
        // This results in a huge improvement in drag and drop performance. Wow
        if (data.right < 0 || data.left > windowWidth) {
          return null;
        }

        const onClick = (ev: React.MouseEvent, id?: ID) => {
          ev.stopPropagation();
          if (createChain) {
            createChain(id != null ? { id, __typename: 'Process' } : data.item);
          } else {
            const modifier = ev.shiftKey
              ? 'shift'
              : // Meta key = command key on a Mac
              ev.ctrlKey || ev.metaKey
              ? 'ctrl'
              : null;
            onSelectItem(id ?? data.item.id, modifier);
          }
        };

        let state =
          selectedItems.size === 0 ? ItemState.Active : ItemState.Inactive;
        const override = itemStateOverrides[data.item.id];
        if (override !== undefined) state = override;
        if (selectedItems.has(data.item.id)) state = ItemState.Selected;

        const children =
          (data.item as Run).runUsages?.map((x) => x.process.id) ?? [];
        if (children.find((x) => selectedItems.has(x))) {
          state = ItemState.Active;
        }

        switch (data.item.__typename) {
          case 'Sink':
          case 'Source':
            return (
              <TimelinePointItem
                key={data.item.id}
                {...(data as PointItemLayoutData)}
                onMouseMove={(item, ev) => onMouseMove?.(item ?? data.item, ev)}
                {...{
                  state,
                  onClick,
                  canEdit,
                }}
              />
            );
          case 'Process':
            if ('inRun' in data) return null;
            return (
              <TimelineProcess
                key={data.item.id}
                {...(data as ProcessLayoutData)}
                onMouseMove={(item, ev) =>
                  onMouseMove?.(
                    item ?? { __typename: 'Process', id: data.item.id },
                    ev,
                  )
                }
                {...{
                  state,
                  onClick,
                  resizing,
                  setResizing,
                  hiddenTimes,
                  schedule,
                  canEdit,
                }}
              />
            );
          case 'Run':
            return (
              <TimelineRun
                key={data.item.id}
                {...(data as RunLayoutData)}
                onMouseMove={(item, ev) =>
                  onMouseMove?.(
                    item ?? { __typename: 'Run', id: data.item.id },
                    ev,
                  )
                }
                {...{
                  state,
                  onClick,
                  resizing,
                  setResizing,
                  resourceInventoryWorkstationId,
                  hiddenTimes,
                  schedule,
                  selectedItems,
                  canEdit,
                }}
              />
            );
        }

        // Will cause a type error if not all cases handled
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _exhaustiveCheck: never = data.item;

        return null;
      })}
    </>
  );
};

export default React.memo(ResourceItems, isEqual);
