import React, { useEffect } from 'react';
import { sortBy } from 'lodash';
import { LinearProgress, Typography } from '@mui/material';
import { Scale } from 'src/utils/scale';
import { useTimelineResources } from 'src/server/resources';
import { subscribeToTimeline } from 'src/server/subscriptions';
import { ResourcesDisplay } from './ResourcesDisplay';
import { LoadingFailed } from 'src/components/LoadingFailed';

export interface ResourcesProps {
  scale: Scale;
  getScroll: () => number;
  getTop: () => number;
}

export const Resources: React.VFC<ResourcesProps> = ({
  scale,
  getScroll,
  getTop,
}) => {
  const query = useTimelineResources(scale, false);
  const { loading, error, refetch, startTime, endTime } = query;
  const data = query.data ?? query.previousData;

  useEffect(() => {
    return subscribeToTimeline(query.subscribeToMore, query.refetch);
  }, [query.subscribeToMore, query.refetch]);

  useEffect(() => {
    refetch({ startTime, endTime });
    // Because we don't care about referential equality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, startTime.getTime(), endTime.getTime()]);

  if (error) {
    return (
      <LoadingFailed>
        <Typography variant="h6">
          Failed to load resources.
          <br />
          Consider refreshing the page?
        </Typography>
      </LoadingFailed>
    );
  }

  return (
    <>
      {loading && (
        <LinearProgress
          variant="query"
          style={{ zIndex: 199, marginBottom: -4, position: 'sticky', top: 0 }}
        />
      )}
      {data && (
        <ResourcesDisplay
          resources={sortBy(data.resources, 'displayIndex')}
          getScroll={getScroll}
          getTop={getTop}
        />
      )}
    </>
  );
};
