import React, { useState } from 'react';
import styled from 'styled-components';
import { OutlinedButton } from 'src/components/Util/Buttons';
import { Grid, TextField } from '@mui/material';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import { timeMonday } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import DatePicker from '@mui/lab/DatePicker';
import { PickersDay, PickersDayProps } from '@mui/lab';
import { styled as materialStyled } from '@mui/material/styles';

export const formatWeek = timeFormat('%e %B %Y');

type CustomPickerDayProps = PickersDayProps<Date> & {
  isMonday: boolean;
};

export const CustomPickersDay = materialStyled<React.FC<CustomPickerDayProps>>(
  PickersDay,
  { shouldForwardProp: (prop) => prop !== 'isMonday' },
)(({ isMonday }) => {
  if (isMonday) return {};
  return { visibility: 'hidden' };
});

export const WeekPicker: React.VFC<{
  week: Date;
  setWeek: (v: Date) => void;
}> = ({ week, setWeek }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  return (
    <WeekPickerStyle item>
      <div className="text pad-right">Apply from week of:</div>
      <DatePicker
        value={week}
        onOpen={() => setOpenDatePicker(true)}
        onClose={() => setOpenDatePicker(false)}
        open={openDatePicker}
        onChange={(v) => setWeek(v!)}
        minDate={timeMonday.ceil(new Date())}
        disabled={false}
        disableCloseOnSelect={false}
        renderDay={(day: Date, _selectedDate, dayComponent) => {
          const monday = timeMonday(day!).getTime() === day!.getTime();
          return <CustomPickersDay {...dayComponent} isMonday={monday} />;
        }}
        renderInput={(params) => (
          <div style={{ position: 'relative' }}>
            <TextField
              {...params}
              style={{
                position: 'absolute',
                left: 20,
                bottom: -5,
                visibility: 'hidden',
              }}
            />
            <ApplyFromWeekButton onClick={() => setOpenDatePicker(true)}>
              <CalendarIcon style={{ marginRight: 12 }} />
              <span className="text">{formatWeek(week)}</span>
            </ApplyFromWeekButton>
          </div>
        )}
      />
    </WeekPickerStyle>
  );
};

const WeekPickerStyle = styled(Grid)`
  align-self: flex-end;
  display: flex;
  align-items: center;
  margin-bottom: -3px;

  .text {
    color: ${(props) => props.theme.mainAccent};
    font-weight: 500;
    font-size: 16px;
  }

  .pad-right {
    padding-right: 16px;
  }
`;

const ApplyFromWeekButton = styled(OutlinedButton)`
  height: 46px;
  border-radius: 22px;
  padding: 8px 16px;
`;
