import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MoveItemOnTimelineEntry {
  type: 'move-item-on-timeline';
  itemId: ID;
  former: {
    resourceId: ID;
    startTime: Date;
  };
  validForState: {
    resourceId: ID;
    startTime: Date;
  };
}

export interface MultiMoveItemOnTimelineEntry {
  type: 'multi-move-item-on-timeline';
  movements: Array<{
    itemId: ID;
    former: {
      startTime: Date;
    };
    validForState: {
      resourceId: ID;
      startTime: Date;
    };
  }>;
}

export interface DeleteItemEntry {
  type: 'delete-item';
  itemId: ID;
}

export type UndoEntry =
  | MoveItemOnTimelineEntry
  | MultiMoveItemOnTimelineEntry
  | DeleteItemEntry;

interface UndoState {
  readonly entries: UndoEntry[];
  readonly frozen: boolean;
}

const initialState: UndoState = {
  entries: [],
  frozen: false,
};

const undoSlice = createSlice({
  name: 'undo',
  initialState,
  reducers: {
    invalidate(state) {
      state.entries = [];
    },
    freeze(state) {
      state.frozen = true;
    },
    unfreeze(state) {
      state.frozen = false;
    },
    push(state, action: PayloadAction<UndoEntry>) {
      state.entries.push(action.payload);
    },
    pop(state) {
      state.entries.pop();
    },
  },
});

export const UndoActions = undoSlice.actions;

export const undoReducer = undoSlice.reducer;
