import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { colorways } from 'src/config/theme';
import { ProcessRowStyle } from './ProcessRowStyle';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete-item.svg';
import { ReactComponent as DragIcon } from 'src/assets/icons/six-dot-grab.svg';
import { IconButton, Typography } from '@mui/material';
import { TextBox } from 'src/components/Input/TextBox';
import { useServerOutputBuffer } from 'src/components/ItemSideDrawer/OutputBuffer';
import { StaticDateDisplay } from 'src/components/ItemSideDrawer/DatePicker/DatePickerDisplay';
import { DurationBox } from 'src/components/ItemSideDrawer/DatePicker/DurationBox';
import {
  ResourceTypeField,
  RunEditorQueryResult,
  useSetItemCustomFieldMutation,
} from 'src/generated/graphql';
import { useDebouncedCallback } from 'use-debounce';
import { moveItem } from 'src/server/item-movement';
import { deleteItem } from 'src/server/item-delete';
import { useAppDispatch } from 'src/store/hooks';
import { useSetRangedValue } from 'src/utils/hooks';
import { secondsToHours, hoursToSeconds } from 'src/utils';
import { useApolloClient } from '@apollo/client';
import { Draggable } from 'react-beautiful-dnd';

const HEIGHT = 90;
const DEBOUNCE_MS = 500;

type Process = Extract<
  NonNullable<NonNullable<RunEditorQueryResult['data']>['item']>,
  { __typename?: 'Run' }
>['runUsages'][0]['process'];

export interface ProcessRowProps {
  index: number;
  open: () => void;
  close: () => void;
  startTabIndex: number;
  tabOutLeft: () => void;
  tabOutRight: () => void;
  process: Process;
  fields: Array<Pick<ResourceTypeField, '__typename' | 'name'>>;
  isSelected: boolean;
  canEdit: boolean;
}

export const ProcessRow: React.FC<ProcessRowProps> = ({
  index,
  open,
  close,
  startTabIndex,
  tabOutLeft,
  tabOutRight,
  process,
  fields,
  isSelected,
  canEdit,
}) => {
  const dispatch = useAppDispatch();
  const buf = useOutputBuffer({
    itemId: process.id,
    serverLag: process.lag ?? null,
    duration: process.duration,
  });

  const [setField] = useSetItemCustomFieldMutation();

  const setTitle = useDebouncedCallback((value: string) => {
    setField({
      variables: {
        input: {
          itemId: process.id,
          name: fields[0].name,
          value,
        },
      },
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, DEBOUNCE_MS);

  const setSubtitle = useDebouncedCallback((value: string) => {
    setField({
      variables: {
        input: {
          itemId: process.id,
          name: fields[1].name,
          value,
        },
      },
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, DEBOUNCE_MS);

  useEffect(
    () => () => {
      setTitle.flush();
      setSubtitle.flush();
    },
    [setTitle, setSubtitle],
  );

  const apollo = useApolloClient();

  const [duration_, setDuration_] = useState<number | null>(null);
  const setServerDuration = useDebouncedCallback((value: Seconds) => {
    moveItem(apollo, dispatch, {
      type: 'run',
      processId: process.id,
      duration: value,
      endTime: null,
      runId: null,
      index: -1,
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, DEBOUNCE_MS);
  const setDuration = (value: Hours) => {
    setDuration_(hoursToSeconds(value));
    setServerDuration(hoursToSeconds(value));
  };

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isSelected) {
      ref.current?.scrollIntoView(false);
    }
  }, [isSelected]);

  const firstOnKeyDownCapture = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && e.shiftKey) {
      tabOutLeft();
    }
  };

  return (
    <Draggable draggableId={process.id} index={index}>
      {(provided) => (
        <ProcessRowStyle
          ref={provided.innerRef}
          className={`prow-${process.id}`} // for selecting once created
          style={{ height: HEIGHT }}
          selected={isSelected}
          {...colorways[process.colorway]}
          {...provided.draggableProps}
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
        >
          <div ref={ref} className="drag" {...provided.dragHandleProps}>
            <DragIcon />
          </div>
          <div className="name">
            {fields.length >= 1 && (
              <TextBox
                autoFocus={isSelected}
                inputProps={{ tabIndex: startTabIndex }}
                defaultValue={process.title ?? ''}
                setValue={(v) => setTitle(v)}
                placeholder={fields[0]?.name ?? 'Title'}
                disabled={!canEdit}
                onKeyDownCapture={firstOnKeyDownCapture}
              />
            )}
            {fields.length >= 2 && (
              <TextBox
                inputProps={{ tabIndex: startTabIndex + 1 }}
                defaultValue={process.subtitle ?? ''}
                setValue={(v) => setSubtitle(v)}
                disabled={!canEdit}
                placeholder={fields[1]?.name ?? 'Subtitle'}
              />
            )}
          </div>
          <div className="duration">
            <div style={{ width: '5.5em' }}>
              <TextBox
                inputProps={{ tabIndex: startTabIndex + 2 }}
                autoFocus={fields.length === 0 && isSelected}
                style={{ margin: 0 }}
                value={buf.stringVal}
                setValue={buf.onChange}
                disabled={!canEdit}
                onKeyDownCapture={(e) => {
                  if (e.key === 'ArrowUp') {
                    e.stopPropagation();
                    e.preventDefault();
                    buf.changeValue(1);
                  } else if (e.key === 'ArrowDown') {
                    e.stopPropagation();
                    e.preventDefault();
                    buf.changeValue(-1);
                  }

                  if (fields.length === 0) {
                    firstOnKeyDownCapture(e);
                  }
                }}
              />
              <span className="unit">h</span>
              <Typography
                color="textSecondary"
                style={{
                  position: 'absolute',
                  fontSize: 13,
                  textAlign: 'center',
                  marginLeft: 19,
                }}
              >
                Out. Buf.
              </Typography>
            </div>
            <div style={{ width: '4.5em' }}>
              <DurationBox
                disabled={!canEdit}
                value={
                  Number(
                    (
                      Math.round(
                        ((duration_ ?? process.duration) * 100) / 3600,
                      ) / 100
                    ).toFixed(2),
                  ) as Hours
                }
                setValue={setDuration}
                inputProps={{ tabIndex: startTabIndex + 3 }}
                style={{ margin: 0 }}
                onKeyDownCapture={(e) => {
                  if (e.key === 'Tab' && !e.shiftKey) {
                    tabOutRight();
                  }
                }}
              />
              <span className="unit">h</span>
              <Typography
                color="textSecondary"
                style={{
                  position: 'absolute',
                  fontSize: 13,
                  textAlign: 'center',
                  marginLeft: 12,
                }}
              >
                Duration
              </Typography>
            </div>
            {process.timelineUsage && (
              <div style={{ alignSelf: 'center', marginLeft: 20 }}>
                <Typography
                  style={{ marginLeft: -10 }}
                  align="center"
                  color="textSecondary"
                >
                  Start
                </Typography>
                <StaticDateDisplay value={process.timelineUsage.startTime} />
              </div>
            )}
          </div>
          <div className="in-out">
            {canEdit && (
              <>
                {process.inputs.length || 'No'} Input
                {process.inputs.length !== 1 ? 's' : ''}
                <br />
                {process.outputs.length || 'No'} Output
                {process.outputs.length !== 1 ? 's' : ''}
              </>
            )}
          </div>
          <div className="icon">
            {process.taskIconId != null && (
              <img
                src={`/assets/task-icons/${process.taskIconId}.svg`}
                alt="icon"
              />
            )}
          </div>
          <div className="end">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                close();
                deleteItem(apollo, null, process.id).catch((error) => {
                  Sentry.captureException(error);
                });
              }}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </ProcessRowStyle>
      )}
    </Draggable>
  );
};

function useOutputBuffer({
  itemId,
  serverLag,
  duration,
}: {
  itemId: ID;
  serverLag: Seconds | null;
  duration: Seconds;
}) {
  const { outputBuffer, setOutputBuffer } = useServerOutputBuffer({
    itemId,
    serverLag,
  });

  return useSetRangedValue({
    value: outputBuffer,
    minValue: 0,
    maxValue: secondsToHours(duration),
    setValue: (v) => setOutputBuffer(v as Hours | null),
  });
}
