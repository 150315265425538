import React, { useRef, useCallback } from 'react';
import { TimelineBar } from 'src/components/TimelineBar';
import { Resources } from 'src/components/Resources';
import { kioskMode } from 'src/services/auth.service';
import { useCurrentPeriodScale, usePeriod } from 'src/utils/scale';

import { TickBackground } from './Background';
import { ResourceGradientBackground } from './ResourceGradientBackground';
import { KioskAutoMovement } from './KioskAutoMovement';
import { useScreenOffsets } from 'src/utils/screen-offsets';

export const Timeline: React.VFC = () => {
  const period = usePeriod();
  const scale = useCurrentPeriodScale();

  const timeline = useRef<HTMLDivElement>();
  const getScroll = useCallback(
    () => (timeline.current ? timeline.current.scrollTop : 0),
    [timeline],
  );
  const getTop = useCallback(
    () => (timeline.current ? timeline.current.offsetTop : 0),
    [timeline],
  );

  const screenOffsets = useScreenOffsets();

  return (
    <>
      {kioskMode && <KioskAutoMovement />}
      <TimelineBar />
      <TickBackground
        backgroundRef={timeline}
        {...{ scale, period }}
        style={{
          flex: 1,
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'overlay',
        }}
      >
        {screenOffsets.map((offset, idx) => (
          <ResourceGradientBackground key={idx} style={{ left: offset }} />
        ))}
        <Resources scale={scale} getScroll={getScroll} getTop={getTop} />
      </TickBackground>
    </>
  );
};
