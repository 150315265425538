import React from 'react';
import { IconButton } from '@mui/material';
import { Undo as UndoIcon } from '@mui/icons-material';
import { useShortcut } from 'src/utils/hooks';
import { useStore } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { undo } from 'src/server/undo';

export const UndoButton: React.VFC = () => {
  const canUndo = useAppSelector(
    ({ undo }) => undo.entries.length > 0 && !undo.frozen,
  );

  const dispatch = useAppDispatch();
  const store = useStore();

  useShortcut('shift+z', () => {
    undo(store, dispatch);
  });

  return (
    <IconButton
      disabled={!canUndo}
      onClick={() => undo(store, dispatch)}
      size="large"
    >
      <UndoIcon
        fontSize="large"
        style={{ color: canUndo ? '#202252' : '#dfe2e8' }}
      />
    </IconButton>
  );
};
