import styled from 'styled-components';

export const ItemListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 16px 21px 16px;

  .header {
    font-size: 14px;
    margin-bottom: 8px;
  }
`;
