import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Paper } from '@mui/material';
import { ItemState } from 'src/models/item';
import { ItemDropTarget } from 'src/components/ItemDropTarget';
import { drop } from 'src/server/item-drag-drop';
import { useCurrentPeriodScale } from 'src/utils/scale';
import { useStore } from 'react-redux';
import { DragSourceMonitor } from 'react-dnd';
import { Process } from 'src/components/Item/Process';
import { Sink } from 'src/components/Item/Sink';
import { Source } from 'src/components/Item/Source';
import { Run } from 'src/components/Item/Run';
import { DraggableContainer } from 'src/components/DragDrop/DraggableContainer';
import { openClipboard } from 'src/store/planner';
import { openItemSideDrawer } from 'src/store/drawer';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useClipboard } from 'src/server/clipboard';
import { ClipboardQuery } from 'src/generated/graphql';
import { useTimelineResources } from 'src/server/resources';
import { useApolloClient } from '@apollo/client';
import { getInventoryWorkstationIds } from './Item/SpanningItemWrapper';

const EMPTY_SET = new Set<never>();

const CONTAINER_WIDTH = 333;

export const ItemHolder: React.VFC<{ open: boolean }> = ({ open }) => {
  const selectedItemId = useAppSelector(({ drawer }) => drawer.editingItemId);
  const dispatch = useAppDispatch();
  const { data } = useClipboard(dispatch);

  const scale = useCurrentPeriodScale();

  const [isDragging, setIsDragging] = useState(false);
  const onDrag = useCallback(() => {
    setIsDragging(true);
  }, [setIsDragging]);

  const apollo = useApolloClient();

  const openAside = useCallback(
    (id: ID) => {
      if (!isDragging) {
        dispatch(openItemSideDrawer({ id, autoFocus: true }));
      }
    },
    [isDragging, dispatch],
  );

  const { data: resourceData } = useTimelineResources(scale, true);

  const { getState } = useStore();

  const onDrop = useCallback(
    (monitor: DragSourceMonitor) => {
      if (!resourceData) return;
      drop({
        apollo,
        state: getState(),
        dispatch,
        scale,
        monitor,
        resources: resourceData.resources,
      });

      setTimeout(() => {
        setIsDragging(false);
      }, 1000);
    },
    [resourceData, getState, scale, apollo, dispatch],
  );

  const clipboardItems = data?.clipboard.items;
  return (
    <>
      {open && clipboardItems && (
        <ItemDropTarget
          targetKeys={['Process', 'Source', 'Sink', 'Run']}
          dropData={{ type: 'clipboard' }}
        >
          <Container style={{ right: 0 }}>
            <Button
              variant="contained"
              color="secondary"
              className="CloseButton"
              onClick={() => dispatch(openClipboard(false))}
            >
              Close Clipboard
            </Button>
            {clipboardItems.map((item) => (
              <DraggableContainer
                id={item.id}
                key={item.id}
                onDrag={onDrag}
                onDrop={onDrop}
                targetKey={item.__typename!}
                dragData={{
                  itemId: item.id,
                  inventoryWorkstationIds: getInventoryWorkstationIds(item),
                }}
                dragDisabled={item.locked}
                style={{ marginBottom: 4 }}
              >
                <HeldItem
                  item={item}
                  selectedItemId={selectedItemId}
                  onClick={(ev: React.MouseEvent) => {
                    ev.stopPropagation();
                    openAside(item.id);
                  }}
                />
              </DraggableContainer>
            ))}
          </Container>
        </ItemDropTarget>
      )}
    </>
  );
};

const Container = styled(Paper)`
  height: 80vh;
  top: 184px;
  padding: 8px;
  width: ${CONTAINER_WIDTH + 16}px;
  position: fixed;
  z-index: 60;
  bottom: 80px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.clipboard};

  box-shadow: 11px 19px 20px 8px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .CloseButton {
    margin: 20px auto;
  }
`;

const HeldItem: React.VFC<{
  item: ClipboardQuery['clipboard']['items'][0];
  selectedItemId: ID | null;
  onClick: (ev: React.MouseEvent) => void;
}> = ({ item, selectedItemId, onClick }) => {
  const state =
    item.id === selectedItemId ? ItemState.Selected : ItemState.Active;
  switch (item.__typename) {
    case 'Process':
      return (
        <Process
          key={item.id}
          onClick={onClick}
          data={{
            outputBufferOffset: 0,
            hiddenIndicatorOffsets: [],
            duration: item.duration,
            left: 0,
            width: CONTAINER_WIDTH,
            height: 80,
            error: false,
            changed: false,
            title: item.title ?? '',
            subtitle: item.subtitle ?? '',
            colorway: item.colorway,
            state,
            locked: item.locked,
            taskIconId: item.taskIconId ?? null,
          }}
        />
      );
    case 'Sink':
      return (
        <Sink
          key={item.id}
          onClick={onClick}
          data={{
            error: false,
            changed: false,
            title: item.title ?? '',
            subtitle: item.subtitle ?? '',
            colorway: item.colorway,
            state: state,
            locked: item.locked,
            onClipboard: true,
          }}
        />
      );
    case 'Source':
      return (
        <Source
          key={item.id}
          onClick={onClick}
          data={{
            error: false,
            changed: false,
            title: item.title ?? '',
            subtitle: item.subtitle ?? '',
            colorway: item.colorway,
            state: state,
            locked: item.locked,
            onClipboard: true,
          }}
        />
      );
    case 'Run':
      return (
        <Run
          key={item.id}
          onClick={onClick}
          selectedItems={EMPTY_SET}
          data={{
            width: CONTAINER_WIDTH,
            height: 80,
            error: false,
            changed: false,
            title: item.title ?? '',
            state: state,
            colorway: item.colorway,
            hiddenIndicatorOffsets: [],
            outputBufferOffset: 0,
            processes: item.runUsages.map(({ offset, process: p }) => ({
              id: p.id,
              title: p.title,
              error: false,
              changed: false,
              colorway: p.colorway,
              left: (offset / item.duration) * CONTAINER_WIDTH + 2,
              right:
                ((offset + p.duration) / item.duration) * CONTAINER_WIDTH - 2,
              buffer: (p.lag ?? 0) / p.duration,
              taskIconId: p.taskIconId ?? null,
            })),
          }}
        />
      );
    default: {
      // This will become a type error if we forget a case
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = item;
    }
  }

  return null;
};
