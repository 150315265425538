import React from 'react';
import styled, { css } from 'styled-components';
import { timeDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import groupBy from 'lodash/groupBy';
import {
  useCurrentPeriodScale,
  useVisibleDays,
  usePeriodEnd,
  usePeriod,
  usePeriodStart,
} from 'src/utils/scale';
import { Positioning } from 'src/components/Positioning';
import { useNow } from 'src/utils/hooks';
import { MOBILE_MEDIA, useIsMobile } from 'src/utils/media';
import { getTextWidth } from 'src/utils/text';
import { Period } from 'src/store/planner';
import holidays  from 'src/assets/holidays/holidays_23-27.json';

const TIMELINE_NOW_UPDATE_MS = 1 * 60 * 1000;

const weekDayFormat = timeFormat('%a %e  %b');
const monthDayFormat = timeFormat('%e');
const monthWeekFormat = timeFormat('%B W%W');
const extractWeek = timeFormat('%W');
const fullDayFormat = timeFormat('%A %e %B %Y');

export const TimelineHeadings: React.VFC = () => {
  const mobile = useIsMobile();
  const periodStart = usePeriodStart();
  const periodEnd = usePeriodEnd();
  const period = usePeriod();
  const scale = useCurrentPeriodScale();
  const now = useNow(TIMELINE_NOW_UPDATE_MS);
  const today = timeDay(now);
  const visibleDays = useVisibleDays();
  const visibleWeeks = groupBy(visibleDays, extractWeek);

  const getHolidayName = (date: Date) => {
    const dateString = new Date(date.getTime() + 12 * 60 * 60 * 1000).toISOString().substring(0, 10);
    const holiday = holidays.find((holiday) => holiday[0] === dateString);
    return holiday ? holiday[1] : null;
  }

  return (
    <>
      {period === Period.Month && (
        <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
          {Object.entries(visibleWeeks)
            .sort(
              ([, aDays], [, bDays]) => aDays[0].valueOf() - bDays[0].valueOf(),
            )
            .map(([_week, days], idx, arr) => {
              const startDay = idx > 0 ? days[0] : periodStart;
              const endDay =
                idx < arr.length - 1 ? arr[idx + 1][1][0] : periodEnd;
              const width = scale(endDay) - scale(startDay);
              const weekText = monthWeekFormat(days[0]);
              return (
                <Week width={width} key={weekText}>
                  {weekText}
                </Week>
              );
            })}
        </div>
      )}
      <div
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          ...(period === Period.Month ? { marginTop: '-3px' } : {}),
        }}
      >
        {visibleDays.length > 0 &&
          visibleDays.map((day: Date, idx: number, arr: Array<Date>) => {
            const NZDay = new Date(day.getTime() + 12 * 60 * 60 * 1000);
            const width = scale(arr[idx + 1] || periodEnd) - scale(day);
            const dayFormat =
              period === Period.Month ? monthDayFormat : weekDayFormat;

            const short = mobile && period > Period.TwoWeek;
            const isToday = today.getTime() === day.getTime();
            const dayText = isToday && !short ? 'Today' : dayFormat(day);
            const holidayName = getHolidayName(NZDay);

            return (
              <TimelineDay
                width={width}
                title={fullDayFormat(day)}
                today={isToday}
                key={day.getTime()}
              >
                {holidayName ? <HolidayName>{holidayName}</HolidayName> : dayText }
              </TimelineDay>
            );
          })}
      </div>
    </>
  );
};

const sharedStyles = css`
  display: inline-flex;
  text-align: center;
  text-transform: uppercase;
`;

const dayStyles = css`
  ${sharedStyles}
  flex-direction: column;
  justify-content: start;
  font-size: 18px;
  font-weight: 800;
  height: 36px;
`;

const TimelineDay = styled(Positioning)`
  ${dayStyles}

  color: ${(props) =>
    props.today ? props.theme.secondaryAccent : props.theme.timelineText};

  ${(props) =>
    props.today &&
    `
    font-weight: bold;
    font-size: 18px;
  `}

  visibility: ${(props) =>
    (props.width || 0) > getTextWidth(props.children as string, 14)
      ? 'visible'
      : 'hidden'};

  @media ${MOBILE_MEDIA} {
    font-size: 11px;
    visibility: visible;
    word-spacing: 3000px;
  }
`;

const Week = styled(Positioning)`
  ${sharedStyles}
  padding: 0 0.5em;
  text-overflow: clip;
  white-space: nowrap;
  overflow-y: hidden;

  height: 24px;
  font-size: 18px;
  color: ${(props) => props.theme.timelineTextLight};

  visibility: ${(props) =>
    (props.width || 0) > getTextWidth(props.children as string, 14)
      ? 'visible'
      : 'hidden'};

  @media ${MOBILE_MEDIA} {
    height: 12px;
    font-size: 11px;
    text-align: center;
    visibility: visible;
  }
`;

const HolidayName = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #f24b6a;
`;
