import { Paper } from '@mui/material';
import styled from 'styled-components';

export const MovementTimestamp = styled(Paper)`
  position: absolute;
  padding: 0.5em;

  width: 86px;
  top: -50px;
`;
