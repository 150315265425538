import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { ItemState } from 'src/models/item';

const CONTAINER_CLASSES: Record<number, string> = {
  [ItemState.Inactive]: 'inactive',
  [ItemState.Selected]: 'selected',
};

export const ErrorIcon: React.VFC<{
  itemState: ItemState;
  style?: CSSProperties;
}> = ({ itemState, style }) => (
  <ErrorIconStyle className={CONTAINER_CLASSES[itemState]} style={style}>
    <div>
      <div>!</div>
    </div>
  </ErrorIconStyle>
);

export const ErrorIconStyle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  background-color: ${(props) => props.theme.errorIcon};

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &.inactive {
    background-color: #fff;
    color: ${(props) => props.theme.errorIcon};
  }

  &.selected {
    visibility: hidden;
  }
`;
