import { ButtonBase } from '@mui/material';
import { ReactComponent as ChainRemoveIcon } from 'src/assets/icons/chain-remove.svg';
import { ColorwayName, colorways } from 'src/config/theme';
import styled from 'styled-components';

export const ItemBubble: React.FC<{
  remove: (() => void) | null;
  colorway: ColorwayName;
  title: string;
  /** null = none, undefined = N/A */
  taskIconId: ID | null | undefined;
}> = ({ remove, colorway, title, taskIconId }) => (
  <ChainLinkContainer
    style={{
      backgroundColor: colorways[colorway].standardBackground,
      ...(remove
        ? {}
        : {
            display: 'flex',
            justifyContent:
              taskIconId !== undefined ? 'space-between' : 'center',
            padding: taskIconId !== undefined ? '0 2em' : undefined,
          }),
    }}
  >
    {remove && (
      <RemoveChainButton onClick={remove}>
        <ChainRemoveIcon />
      </RemoveChainButton>
    )}
    <div className="name">{title}</div>
    {taskIconId && (
      <img
        src={`/assets/task-icons/${taskIconId}.svg`}
        alt="icon"
        style={{
          maxWidth: 38,
          maxHeight: 38,
          float: 'right',
          marginRight: '1em',
        }}
      />
    )}
  </ChainLinkContainer>
);

const ChainLinkContainer = styled.div`
  flex: 0 0 40px;
  align-items: center;
  padding: 0 7px 0 10px;

  display: flex;

  width: calc(100% - 28px);
  border-radius: 20px;
  background-color: #f0dce7;
  margin-bottom: 8px;

  .name {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.mainAccent};

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const RemoveChainButton = styled(ButtonBase)`
  flex: 0 0 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 6px;

  & svg {
    color: #fff;
  }
`;
