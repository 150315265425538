import React from 'react';
import styled from 'styled-components';
import { Modal, Paper } from '@mui/material';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import {
  PrimaryActionButton,
  SecondaryActionButton,
} from 'src/components/Util';

export interface ConfirmationModalProps {
  confirm: () => void;
  confirmButtonText: string;
  cancel?: () => void;
  cancelButtonText?: string;
  headingText: React.ReactNode | string;
  explanatoryText: React.ReactNode | string;
  showWarningIcon?: boolean;
}

export const ConfirmationModal: React.VFC<ConfirmationModalProps> = ({
  confirm,
  confirmButtonText,
  cancel,
  cancelButtonText = 'Cancel',
  headingText,
  explanatoryText,
  showWarningIcon,
}) => (
  <ModalContainer open>
    <Paper style={{ width: 513 }}>
      <div className="heading">{headingText}</div>
      {showWarningIcon && <WarningIcon className="warning" />}
      <div className="explanation">{explanatoryText}</div>
      <div className="buttons">
        {cancel && (
          <SecondaryActionButton onClick={cancel} text={cancelButtonText} />
        )}
        <PrimaryActionButton onClick={confirm} text={confirmButtonText} />
      </div>
    </Paper>
  </ModalContainer>
);

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiPaper-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 0;
    outline: none;

    & > div {
      padding: 2em;
      text-align: center;
    }

    .heading {
      color: #000;
      background-color: ${(props) => props.theme.lightTint};
      font-size: 16px;
    }

    .warning {
      margin: 43px auto -1.5em auto;
      width: 100%;
    }

    .explanation {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 0em 4em 2em 4em;
    }
  }
`;
