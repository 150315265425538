import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Input } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as Icon } from 'src/assets/icons/description.svg';
import { useSetItemDescription } from 'src/server/drawer';

const DEBOUNCE_MS = 500;

export const Description: React.VFC<{
  itemId: ID;
  description: string;
  disabled: boolean;
}> = ({ itemId, description, disabled }) => {
  const [mutate] = useSetItemDescription();
  const onSetText = useDebouncedCallback((v: string) => {
    mutate({
      variables: {
        input: {
          itemId,
          description: v,
        },
      },
    });
  }, DEBOUNCE_MS);

  useEffect(
    () => () => {
      onSetText.flush();
    },
    [onSetText],
  );

  return (
    <Container>
      <Icon style={{ marginRight: 11 }} />
      <Field
        id="description-side-drawer"
        placeholder={disabled ? 'No Description' : 'Add Description'}
        multiline
        disableUnderline
        spellCheck={false}
        defaultValue={description ?? ''}
        onChange={(e) => onSetText(e.target.value)}
        disabled={disabled}
        className={disabled ? undefined : 'enabled'}
        classes={{
          input: 'input',
          focused: 'focused',
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 48px;
  display: flex;
  align-items: flex-start;
`;

const Field = styled(Input)`
  margin-top: -6px;
  padding: 0;
  width: 100%;

  .input {
    outline: none;
    padding: 7px 5px 4px 5px;
    font-size: 16px;
    color: ${(props) => props.theme.itemDescription};

    ::placeholder {
      color: ${(props) => props.theme.itemDescription};
      opacity: 1;
      font-weight: 500;
    }
  }

  &.enabled {
    &:hover,
    &.focused {
      .input {
        background-color: ${(props) => props.theme.lightTint};
      }
    }
  }
`;
