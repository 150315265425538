import React, { useContext, useMemo } from 'react';
import { DragSourceMonitor } from 'react-dnd';

interface ItemDragDropContextData {
  onDrag: () => void;
  onDrop: (monitor: DragSourceMonitor, permitted: boolean) => void;
}

const ItemDragDropContext = React.createContext<ItemDragDropContextData>(null!);

export const ItemDragDropProvider: React.FC<ItemDragDropContextData> = ({
  children,
  onDrag,
  onDrop,
}) => {
  const value = useMemo(
    () => ({
      onDrag,
      onDrop,
    }),
    [onDrag, onDrop],
  );

  return (
    <ItemDragDropContext.Provider value={value}>
      {children}
    </ItemDragDropContext.Provider>
  );
};

export function useItemDragDropContext() {
  return useContext(ItemDragDropContext);
}
