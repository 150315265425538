import React from 'react';
import { RunLayoutData, ItemInteractionProps } from 'src/models/item';
import { SpanningItemWrapper } from './SpanningItemWrapper';
import { TimeRange } from 'src/models';
import { Run } from './Run';
import { ItemDropTarget } from 'src/components/ItemDropTarget';
import { useCurrentPeriodScale } from 'src/utils/scale';
import { hasChanged, useCompareTime } from 'src/store/planner';

interface ExtraProps {
  resourceInventoryWorkstationId: ID | null;
  hiddenTimes: TimeRange[];
  schedule: TimeRange[];
  resizing: any;
  setResizing: any;
  canEdit: boolean;
  selectedItems: Set<string>;
}

type TimelineRunProps = RunLayoutData & ItemInteractionProps & ExtraProps;

export const TimelineRun: React.VFC<TimelineRunProps> = ({
  top,
  height,
  item,
  processes,
  hiddenIndicatorOffsets,
  outputBufferOffset,
  onClick,
  state,
  resizing,
  setResizing,
  onMouseMove,
  resourceInventoryWorkstationId,
  hiddenTimes,
  schedule,
  selectedItems,
  canEdit,
}) => {
  const scale = useCurrentPeriodScale();

  const isResizing = resizing && resizing.itemId === item.id;

  const startTime = isResizing
    ? resizing!.startTime
    : item.timelineUsage!.startTime;

  let endTime = item.timelineUsage!.endTime;
  if (isResizing) {
    endTime = resizing!.endTime;
    const inDowntime = schedule.find(
      (d) => d.endTime >= endTime && d.startTime <= endTime,
    );

    if (inDowntime) endTime = inDowntime.startTime;
  }

  const left = scale(startTime);
  const right = scale(endTime);

  const compareTime = useCompareTime();

  return (
    <SpanningItemWrapper
      {...({
        top,
        height,
        item,
        hiddenIndicatorOffsets,
        outputBufferOffset,
      } as RunLayoutData)}
      {...{
        resizing,
        setResizing,
        hiddenTimes,
        schedule,
        canEdit,
      }}
    >
      {({ outputBufferOffset, hiddenIndicatorOffsets, changed }) => (
        <ItemDropTarget
          targetKeys={['Process']}
          dropData={{
            type: 'run',
            runId: item.id,
            resourceInventoryWorkstationId,
          }}
        >
          <Run
            onClick={onClick}
            onMouseMove={(i, evt) => {
              evt.stopPropagation();
              if (onMouseMove) {
                onMouseMove(i ?? item, evt);
              }
            }}
            selectedItems={selectedItems}
            data={{
              width: (right ?? 0) - (left ?? 0),
              height,
              error: item.conflictCount !== 0,
              changed,
              title: item.title ?? '',
              state,
              colorway: item.colorway,
              hiddenIndicatorOffsets,
              outputBufferOffset,
              processes: item.runUsages.map((ru, i) => ({
                id: ru.process.id,
                title: ru.process.title,
                left: processes[i].left,
                right: processes[i].right,
                error: ru.process.conflictCount > 0,
                changed: hasChanged(
                  compareTime,
                  ru.process.lastPositioned ?? null,
                ),
                colorway: ru.process.colorway,
                buffer: processes[i].outputBufferOffset ?? 0,
                taskIconId: ru.process.taskIconId ?? null,
              })),
            }}
          />
        </ItemDropTarget>
      )}
    </SpanningItemWrapper>
  );
};
