import React from 'react';
import { ResourceList } from './ResourceList';
import { Title, Subtitle, Divider } from 'src/components/Util';
import { SolidButton } from 'src/components/Util/Buttons';
import { useCreateResource } from 'src/server/resources';

export const Resources: React.VFC = () => {
  const createResource = useCreateResource();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Title>Resources</Title>
          <Subtitle>Add, manage and remove resources.</Subtitle>
        </div>
        <AddResourceButton onClick={() => createResource()} />
      </div>
      <Divider />
      <ResourceList />
    </>
  );
};

const AddResourceButton: React.VFC<{ onClick: () => void }> = ({ onClick }) => (
  <SolidButton onClick={() => onClick()} color="#fff" backgroundColor="#5878ff">
    <span style={{ fontSize: 14, letterSpacing: 2 }}>ADD RESOURCE</span>
  </SolidButton>
);
