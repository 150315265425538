import React from 'react';
import styled from 'styled-components';
import { kioskMode } from 'src/services/auth.service';
import { MOBILE_MEDIA, useIsMobile } from 'src/utils/media';
import { usePeriodEnd, usePeriodStart } from 'src/utils/scale';

import { SnapItems } from './SnapItems';
import { TimelineCalendar } from 'src/components/Calendar';
import { Zoom } from './Zoom';
import { WorkingHoursToggle } from './WorkingHoursToggle';
import { ClipboardIcon } from './ClipboardIcon';
import { HotkeysModal } from './HotkeysModal';
import { UndoButton } from './UndoButton';
import { CompareToggle } from './CompareToggle';
import { TimelineHeadings } from './TimelineHeadings';
import { SearchModal } from './SearchModal';

export const TimelineBar: React.VFC = () => {
  const startDay = usePeriodStart();
  const endDay = usePeriodEnd();
  const mobile = useIsMobile();

  return (
    <TimelineBarContainer>
      <div className="timeline-bar-content">
        {!mobile && (
          <div>
            <SnapItems />
          </div>
        )}
        <SearchModal />

        <TimelineCalendar startDay={startDay} endDay={endDay} />

        <div style={{ display: 'flex' }}>
          <Zoom />
          <div style={{ width: '2em' }} />
          <WorkingHoursToggle />
        </div>

        <div className="rhs-group">
          {!kioskMode && !mobile && <ClipboardIcon />}

          {!mobile && <HotkeysModal />}

          <div>
            <UndoButton />
          </div>

          {!mobile && (
            <div>
              <div style={{ float: 'right' }}>
                <CompareToggle />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="timeline-bar-headings">
        <div style={{ minWidth: '150vw' }}>
          <TimelineHeadings />
        </div>
      </div>
    </TimelineBarContainer>
  );
};

const TimelineBarContainer = styled.div`
  width: 100%;
  min-height: 110px;
  background-color: #fff;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1);
  z-index: 30;

  .timeline-bar-content {
    zoom: 0.9;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${MOBILE_MEDIA} {
      padding: 0 24px;
    }

    & > * {
      margin-right: 1em;
    }

    .clipboard-icon,
    .hotkeys-icon {
      color: ${(props) => props.theme.mainAccent};
      :hover {
        cursor: pointer;
      }
    }
  }

  .timeline-bar-headings {
    width: 100%;
    overflow: hidden;
    height: 56px;
    display: flex;
    flex-wrap: wrap;
  }

  .rhs-group {
    display: flex;
    align-items: center;
  }
`;
