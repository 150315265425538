import { hoursToSeconds } from 'src/utils';

export const TIMELINE_NOW_UPDATE_MS = 1 * 60 * 1000;

export const UNKNOWN_RESOURCE_TYPE_ID = '0' as ID;

export const DEFAULT_SPANNING_ITEM_DURATION = hoursToSeconds(4 as Hours);
export const DEFAULT_LAG: Seconds | null = null;

export const CHANGES_FETCH_COUNT = 250;
export const CHANGES_FETCH_INTERVAL = 15000;
export const ITEM_CHANGES_FETCH_COUNT = 15;
export const ITEM_CHANGES_FETCH_INTERVAL = 15000;

/** 15 minutes is the smallest timeline movement */
export const MOVEMENT_INCREMENT = 15 * 60 * 1000;

export const WEEKDAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const MAX_SCALE_CACHE_SIZE = 5000;

export const TRANSPARENT_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const BACKGROUND_BIG_TICK_COLOR = 'rgba(155,155,155,0.4)';
export const BACKGROUND_SMALL_TICK_COLOR = 'rgba(228,231,236,0.4)';
export const BACKGROUND_WEEKEND_BORDER_TICK_COLOR = '#333';
export const BACKGROUND_WEEKEND_TICK_COLOR = '#ccc';
export const BACKGROUND_HOLIDAY_TICK_COLOR = '#f24b6a';

export const ITEM_RESIZE_HANDLE_WIDTH = 15;

// d3-time-format syntax
export const FORMAT_TIME = '%I:%M %p';
export const FORMAT_DATE = '%B %-d';
export const FORMAT_DATE_YEAR = '%B %-d %Y';

export const MIN_RESOURCE_HEIGHT = 100;

export const SIDEBAR_GAP = 50;
export const SIDEBAR_WIDTH = 500;
