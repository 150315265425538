import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemType } from 'src/models/item';
import { DropData, SimpleDropData } from 'src/server/item-drag-drop';
import { DraggableContainerProps } from './DragDrop/DraggableContainer';

export interface ItemDropTargetProps {
  targetKeys: ItemType[];
  dropData: SimpleDropData;
}

export const ItemDropTarget: React.FC<ItemDropTargetProps> = ({
  children,
  targetKeys,
  dropData,
}) => {
  type DragItem = DraggableContainerProps['dragData'];

  const validDrop = (item: DragItem) => {
    const validResourceDrop =
      dropData.type === 'resource' &&
      item &&
      item.inventoryWorkstationIds.every(
        (x) => x === dropData.inventoryWorkstationId,
      );

    const validRunDrop =
      dropData.type === 'run' &&
      item &&
      item.inventoryWorkstationIds.every(
        (x) => x === dropData.resourceInventoryWorkstationId,
      );

    const validClipboardDrop = dropData.type === 'clipboard';

    return validResourceDrop || validRunDrop || validClipboardDrop;
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: targetKeys,
    canDrop: (data?: { data: DragItem }) => !!data && validDrop(data.data),
    drop: (_item, monitor): DropData<SimpleDropData> | undefined => {
      if (!monitor.isOver({ shallow: true })) return undefined;
      const initialOffset = monitor.getInitialSourceClientOffset();
      const difference = monitor.getDifferenceFromInitialOffset();

      return {
        ...dropData,
        clientOffset: {
          x: initialOffset!.x + difference!.x,
          y: initialOffset!.y + difference!.y,
        },
      };
    },
    collect: (monitor) => {
      const item = monitor.getItem<{ data: DragItem }>()?.data;
      return {
        isOver: monitor.isOver({ shallow: true }) && validDrop(item),
      };
    },
  });

  if (targetKeys.length === 0) {
    return <>{children}</>;
  }

  return (
    <div ref={dropRef} className={isOver ? 'highlighted' : ''}>
      {children}
    </div>
  );
};
