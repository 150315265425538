import React, { useState, useCallback } from 'react';
import { TimeIntervalColumn } from './TimeIntervalSegment';
import { DayColumn } from './DayColumn';
import { TimeRange } from 'src/models';
import styled from 'styled-components';
import { hoursToMilliseconds } from 'date-fns';
import { MOVEMENT_INCREMENT } from 'src/constants';

const SLICES_PER_HOUR = hoursToMilliseconds(1) / MOVEMENT_INCREMENT;
if (!Number.isInteger(SLICES_PER_HOUR)) {
  throw new Error(
    'MOVEMENT_INCREMENT in constants.ts must divide into an hour in an integer number of slices',
  );
}

const HOURS_PER_DAY = 24;
const SLICES_PER_DAY = HOURS_PER_DAY * SLICES_PER_HOUR;
const SLICES_PER_WEEK = 7 * SLICES_PER_DAY;

export interface ScheduleProps {
  dayHeadings: string[];
  states: boolean[];
  daysPast?: number;
  setRange: (v: boolean, start: number, end: number) => void;
}

export const Schedule: React.VFC<ScheduleProps> = ({
  dayHeadings,
  states,
  daysPast,
  setRange,
}) => (
  <div style={{ display: 'flex' }}>
    <TimeIntervalColumn />
    {Array(7)
      .fill(undefined)
      .map((_, i) => (
        <div key={i} style={{ flex: '1 1 0px' }}>
          <Day style={{ width: '100%', marginBottom: 27 }}>
            {dayHeadings[i].startsWith('** ') ? dayHeadings[i].substring(3) : dayHeadings[i] }
          
          </Day>
          <DayColumn
            currentStates={states.slice(
              i * SLICES_PER_DAY,
              (i + 1) * SLICES_PER_DAY,
            )}
            past={!!daysPast && i < daysPast}
            setRange={(v, start, end) => {
              const dayStart = i * SLICES_PER_DAY;
              setRange(v, dayStart + start, dayStart + end);
            }}
            isHoliday={dayHeadings[i].startsWith('** ')}
          />
        </div>
      ))}
  </div>
);

const Day = styled.div`
  display: inline-flex;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  color: ${(props) =>
    (props.children as string).toLowerCase() === 'today'
      ? props.theme.secondaryAccent
      : props.theme.timelineText};
`;

export function scheduleStatesFromRanges(start: Date, ranges: TimeRange[]) {
  const discreteRanges = ranges
    .map((r) => [
      (r.startTime.getTime() - start.getTime()) / MOVEMENT_INCREMENT,
      (r.endTime.getTime() - start.getTime()) / MOVEMENT_INCREMENT,
    ])
    .sort(([a], [b]) => a - b);

  let curRangeIdx = 0;
  const result: boolean[] = [];
  for (let i = 0; i < SLICES_PER_WEEK; i++) {
    if (curRangeIdx >= discreteRanges.length) {
      result.push(true);
      continue;
    }

    if (i >= discreteRanges[curRangeIdx][1]) {
      curRangeIdx += 1;
      if (
        discreteRanges[curRangeIdx] &&
        discreteRanges[curRangeIdx][0] <= i &&
        i <= discreteRanges[curRangeIdx][0]
      ) {
        result.push(false);
      } else result.push(true);
      continue;
    }

    result.push(i < discreteRanges[curRangeIdx][0]);
  }

  return result;
}

export function useSchedule(initialStates: boolean[]) {
  const [savedStates, setSavedStates] = useState(initialStates);
  const [states, setStates] = useState<boolean[]>(savedStates);
  const [editing, setEditing] = useState(false);

  const availableSegments = states.filter((x) => x).length;
  const calculateRanges = useCallback(() => {
    return [true, ...states, true]
      .map((s, i, arr) => [
        s !== (arr[i - 1] === undefined ? true : arr[i - 1]),
        i - 1,
      ])
      .filter(([diff]) => diff)
      .map(
        ([_, i], idx, arr) =>
          (idx % 2 === 0 ? null : [arr[idx - 1][1], i]) as [number, number],
      )
      .filter((x) => x !== null);
  }, [states]);

  const setRange = useCallback(
    (val: boolean, start: number, end: number) => {
      if (!editing) {
        setEditing(true);
      }

      setStates((s) => s.map((v, i) => (start <= i && i <= end ? val : v)));
    },
    [editing, setEditing, setStates],
  );

  const commit = useCallback(() => {
    setSavedStates([...states]);
    setEditing(false);
  }, [states, setSavedStates, setEditing]);

  const revert = useCallback(() => {
    setStates([...savedStates]);
    setEditing(false);
  }, [savedStates, setStates, setEditing]);

  const replace = useCallback(
    (states: boolean[]) => {
      setStates(states);
      setSavedStates(states);
      setEditing(false);
    },
    [setStates, setSavedStates, setEditing],
  );

  return {
    // Boolean indicating whether unsaved changes may be present
    editing,
    // Replaces the "saved" state with the current state
    commit,
    // Replaces the current state with the saved state
    revert,
    // Current state
    states,
    // Set a range of values in the current state
    setRange,
    // The number of segments enabled
    availableSegments,
    // Calculates ranges of uptime from the current state
    calculateRanges,
    // Replace the saved and draft states with the given info
    replace,
  };
}
