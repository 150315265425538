import styled from 'styled-components';
import { ButtonBase, TextField } from '@mui/material';
import { timeFormat } from 'd3-time-format';
import { FORMAT_DATE, FORMAT_TIME } from 'src/constants';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { useState } from 'react';
import React from 'react';

export const DatePickerDisplay: React.FC<{
  value: Date;
  minDate?: Date;
  setValue?: (v: Date) => void;
}> = ({ minDate, value, setValue }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  return (
    <DateTimePicker
      value={value}
      onOpen={() => setOpenDatePicker(true)}
      onClose={() => setOpenDatePicker(false)}
      open={openDatePicker}
      onChange={(v: any) => {
        setValue?.(v!);
      }}
      minDate={minDate}
      disabled={setValue === undefined}
      ampm
      minutesStep={15}
      label="Start"
      disableCloseOnSelect={false}
      renderInput={(params) =>
        setValue ? (
          <div style={{ position: 'relative' }}>
            <TextField
              {...params}
              style={{
                position: 'absolute',
                left: 20,
                bottom: -10,
                visibility: 'hidden',
              }}
            />
            <DateDisplay open={setOpenDatePicker} value={value} />
          </div>
        ) : (
          <StaticDateDisplay value={value} />
        )
      }
    />
  );
};

const timeFormatter = timeFormat(FORMAT_TIME);
const dayFormatter = timeFormat(FORMAT_DATE);

const DateDisplay: React.FC<{ open: (x: boolean) => void; value: Date }> = ({
  open,
  value,
}) => {
  return (
    <DateDisplayStyle onClick={() => open(true)}>
      <div>
        <div className="time">{timeFormatter(value)}</div>
        <div className="day">{dayFormatter(value)}</div>
      </div>
    </DateDisplayStyle>
  );
};

export const StaticDateDisplay: React.FC<{
  value: Date;
}> = ({ value }) => (
  <DateDisplayStyle disabled>
    <div>
      <div className="time">{timeFormatter(value)}</div>
      <div className="day">{dayFormatter(value)}</div>
    </div>
  </DateDisplayStyle>
);

const DateDisplayStyle = styled(ButtonBase)`
  .time {
    font-size: 16px;
    font-weight: bold;
  }

  .day {
    font-size: 12px;
    margin-left: -5px;
  }
`;
