import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { Remove as MinusIcon, Add as PlusIcon } from '@mui/icons-material';
import { CircularOutlinedButton } from 'src/components/Util/Buttons';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Period, periodZoomIn, periodZoomOut } from 'src/store/planner';
import { useIsMobile } from 'src/utils/media';

export const Zoom: React.VFC = () => {
  const dispatch = useAppDispatch();
  const period = useAppSelector((state) => state.planner.period);

  const zoomInDisabled = period === Period.Day;
  const zoomOutDisabled = period === Period.Month;

  const mobile = useIsMobile();
  const selectedItemId = useAppSelector((state) => state.drawer.editingItemId);

  const zoomIn = () => {
    dispatch(periodZoomIn());
  };

  const zoomOut = () => {
    dispatch(periodZoomOut());
  };

  useHotkeys(
    '=',
    () => {
      !selectedItemId && !zoomInDisabled && zoomIn();
    },
    {},
    [zoomInDisabled, zoomIn, selectedItemId],
  );
  useHotkeys(
    'shift+=',
    () => {
      !selectedItemId && !zoomInDisabled && zoomIn();
    },
    {},
    [zoomInDisabled, zoomIn, selectedItemId],
  );
  useHotkeys(
    '-',
    () => {
      !selectedItemId && !zoomOutDisabled && zoomOut();
    },
    {},
    [zoomOutDisabled, zoomOut, selectedItemId],
  );
  useHotkeys(
    'shift+-',
    () => {
      !selectedItemId && !zoomOutDisabled && zoomOut();
    },
    {},
    [zoomOutDisabled, zoomOut, selectedItemId],
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CircularOutlinedButton disabled={zoomOutDisabled} onClick={zoomOut}>
        <Tooltip title="Zoom Out">
          <MinusIcon />
        </Tooltip>
      </CircularOutlinedButton>

      {mobile ? <div style={{ width: 10 }} /> : <ZoomStyle>Zoom</ZoomStyle>}

      <CircularOutlinedButton disabled={zoomInDisabled} onClick={zoomIn}>
        <Tooltip title="Zoom In">
          <PlusIcon />
        </Tooltip>
      </CircularOutlinedButton>
    </div>
  );
};

const ZoomStyle = styled.div`
  font-weight: 500;
  margin: auto 20px;
`;
