import React from 'react';
import styled from 'styled-components';
import { ItemState, ItemInteractionProps } from 'src/models/item';
import { ErrorIcon, ErrorIconStyle } from 'src/components/ErrorIcon';
import { RunContent, RunProcessDisplayData } from './RunContent';
import { colorways, ColorwayName, ItemColorway } from 'src/config/theme';
import { AfterHoursIndicator } from '../AfterHoursIndicator';
import { ChangedHighlight } from '../ChangedHighlight';
import { ErrorDash } from '../ErrorDash';

export interface RunProps {
  onClick?: (event: React.MouseEvent, id?: ID) => void;
  onMouseMove?: ItemInteractionProps['onMouseMove'];
  selectedItems: Set<string>;
  data: RunDisplayData;
}

interface RunDisplayData {
  width: number;
  height: number;
  error: boolean;
  changed: boolean;
  title: string;
  state: ItemState;
  colorway: ColorwayName;
  hiddenIndicatorOffsets: number[];
  outputBufferOffset: number;
  processes: RunProcessDisplayData[];
}

export const Run: React.VFC<RunProps> = ({
  onClick,
  onMouseMove,
  selectedItems,
  data: {
    width,
    height,
    error,
    changed,
    title,
    state,
    colorway,
    hiddenIndicatorOffsets,
    outputBufferOffset,
    processes,
  },
}) => {
  const className = (() => {
    if (state === ItemState.Inactive) return 'inactive';
    if (state === ItemState.Selected) return 'selected';
    return undefined;
  })();

  const heading = (
    <RunTitleAndErrorStyle>
      <RunTitleStyle>{title}</RunTitleStyle>
      {error && (
        <ErrorIcon
          style={{ width: 20, height: 20, marginTop: 3 }}
          itemState={state}
        />
      )}
    </RunTitleAndErrorStyle>
  );

  const content = (
    <RunContent
      onClick={onClick}
      onMouseMove={onMouseMove}
      height={Math.max(35, height - 28)}
      selectedItems={selectedItems}
      processes={processes}
    />
  );

  return (
    <RunStyle
      title={title}
      style={{ width, height }}
      className={className}
      onMouseMove={(e) => onMouseMove?.(null, e)}
      onClick={onClick}
      {...colorways[colorway]}
    >
      <div className="wrapper">
        <div className="inner">
          {/* Forces name and error to disappear if they don't fit */}
          <div style={{ width: 1, height: '100%' }} />
          <div
            style={{ minWidth: 45, width: 'calc(100% - 1px)', height: '100%' }}
          >
            {height >= 80 ? (
              <>
                {heading}
                {content}
              </>
            ) : (
              <>
                {content}
                {heading}
              </>
            )}
          </div>
        </div>
        {error && <ErrorDash borderRadius={8} borderSize={3} grow />}
        {changed && <ChangedHighlight borderRadius={8} borderSize={3} grow />}
        {!!outputBufferOffset && (
          <div
            style={{ width: outputBufferOffset }}
            className="output-buffer"
          />
        )}
      </div>
      {hiddenIndicatorOffsets.map((offset, i) => (
        <AfterHoursIndicator
          key={i}
          style={{ position: 'absolute', top: 0, left: offset }}
          height={height}
        />
      ))}
    </RunStyle>
  );
};

const RunTitleStyle = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: ${(props) => props.theme.mainAccent};
`;

export const RunStyle = styled.div<ItemColorway>`
  border-radius: 8px;
  border: 3px solid ${(props) => props.standardBackground};
  background-color: ${(props) => props.standardBackground}70;

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .inner {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;

    display: flex;
    flex: 1 1;
    flex-flow: row wrap;
    overflow: hidden;
  }

  &.inactive {
    background-color: rgba(255, 255, 255, 0.8);

    .wrapper {
      opacity: 0.2;
    }
  }

  &.selected {
    border: 3px solid ${(props) => props.selectedBorder};
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);

    .output-buffer {
      height: 9px;
    }
  }

  .output-buffer {
    position: absolute;
    bottom: -3px;
    left: -3px;
    height: 6px;
    background-color: ${(props) => props.selectedBorder};
    border-radius: 0 0 0 8px;
  }

  .highlighted > .run-style {
    background-color: red;
  }
`;

const RunTitleAndErrorStyle = styled.div`
  position: relative;
  pointer-events: none;
  height: 25px;
  margin: -2px 3px -2px 10px;
  display: flex;

  ${RunTitleStyle} {
    min-width: 0 !important;
    width: unset;
    white-space: nowrap;
    margin-right: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
  }

  ${ErrorIconStyle} {
    flex-shrink: 0;
    overflow: hidden;
  }
`;
