import React from 'react';
import styled from 'styled-components';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';

interface CustomCheckboxProps {
  checked?: boolean;
  setChecked: (v: boolean) => void;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
}

export type CheckboxProps = CustomCheckboxProps & MuiCheckboxProps;

export const Checkbox: React.VFC<CheckboxProps> = ({
  checked,
  setChecked,
  style,
  inputStyle,
  ...extraProps
}) => (
  <Container
    checked={checked}
    onChange={(e) => setChecked(e.target.checked)}
    style={style}
    inputProps={{ style: inputStyle }}
    {...extraProps}
  />
);

const Container = styled(MuiCheckbox)`
  width: 25px;
  height: 25px;
  border-radius: 0;
  border: 1px solid ${(props) => props.theme.settingsSecondary};
  background-color: ${(props) => props.theme.lightTint} !important;

  svg {
    display: none;
  }

  &.Mui-checked {
    border: 1px solid ${(props) => props.theme.mainAccent};
    background-color: ${(props) => props.theme.secondaryAccent} !important;

    :hover {
      filter: brightness(70%);
    }
  }
`;
