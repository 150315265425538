import React, { useCallback } from 'react';
import { setToday } from 'src/store/planner';
import { useNow } from 'src/utils/hooks';
import { useAppDispatch } from 'src/store/hooks';
import { TIMELINE_NOW_UPDATE_MS } from 'src/constants';

export const KioskAutoMovement: React.VFC = () => {
  const dispatch = useAppDispatch();

  const updater = useCallback(
    (prev: Date, next: Date) => {
      const newDay = prev.getDate() !== next.getDate();
      if (newDay) {
        dispatch(setToday());
      }
    },
    [dispatch],
  );

  useNow(TIMELINE_NOW_UPDATE_MS, updater);

  return null;
};
