import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as Integrations from '@sentry/integrations';
import 'src/index.css';
import { Root } from 'src/components/Root';
import { env, releaseProfile } from 'src/runtime-environment';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { Problem } from 'src/components/Problem';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://385806857dde4cf2990135639a85d47f@o925760.ingest.sentry.io/5934845',
    release: `factory-planner-client@${env.VERSION}`,
    environment: releaseProfile(),
    normalizeDepth: 10,
    // We add them with more detail through apollo-link-sentry
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', '.genia.co.nz'],
      }),
      new Integrations.CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Integrations.Offline(),
    ],
    // We don't have millions of users, better safe than sorry
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={<Problem fullscreen errorText="Uh-oh. The app crashed." />}
      showDialog
    >
      <Root />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
