import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { Checkbox, Typography, Menu, MenuItem } from '@mui/material';
import EyeballIcon from '@mui/icons-material/Visibility';
import { OutlinedButton } from 'src/components/Util/Buttons';
import { resourceQueryPeriod, useCurrentPeriodScale } from 'src/utils/scale';
import { kioskMode } from 'src/services/auth.service';
import { useLocalStorageState } from 'src/utils/hooks';
import { useIsMobile } from 'src/utils/media';
import { useApolloClient } from '@apollo/client';
import {
  useFilterResource,
  useResourceFilter,
  useSimpleResourceList,
} from 'src/server/resources';
import {
  ResourceFilterDocument,
  ResourceFilterQuery,
  TimelineResourcesDocument,
} from 'src/generated/graphql';

export const ResourceFilterDropdown: React.VFC = () => {
  const mobile = useIsMobile();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  // Normally you cannot call hooks conditionally, but here `kioskMode` is
  // constant for the entire application lifetime, so this isn't a problem
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const result = kioskMode ? useLocalResourceFilter() : useUserResourceFilter();

  if (result.type === 'error') {
    return <span style={{ margin: '0.6em 1em' }}>Failed to fetch...</span>;
  }

  if (result.type === 'loading') return null;

  const { toggleAll, handleToggle, refetch, hidden, resources } = result;

  return (
    <div style={{ position: 'relative', marginRight: '2em' }}>
      <OutlinedButton
        onClick={(e) => {
          e.persist();
          const { currentTarget } = e;
          setTimeout(() => setAnchor(currentTarget), 100);
        }}
      >
        <EyeballIcon
          style={{ margin: mobile ? '0 -0.8em 0 -0.8em' : '0 0.5em 0 -0.3em' }}
        />
        {!mobile && 'Resources'}
      </OutlinedButton>
      <Menu
        id="resource-filter"
        anchorEl={anchor}
        open={anchor != null}
        onClose={() => {
          refetch();
          setAnchor(null);
        }}
      >
        <MenuItem onClick={toggleAll}>
          <Checkbox checked={hidden.size === 0} />
          <Typography variant="body1">Select All</Typography>
        </MenuItem>
        {resources.map(({ id, name }) => (
          <MenuItem key={id} onClick={() => handleToggle(id)}>
            <Checkbox checked={!hidden.has(id)} />
            <Typography variant="body1">{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

type ResourceFilterHookResult =
  | { type: 'error' }
  | { type: 'loading' }
  | {
      type: 'success';
      toggleAll: () => void;
      handleToggle: (id: ID) => void;
      refetch: () => void;
      hidden: Set<ID>;
      resources: Array<{ id: ID; name: string }>;
    };

function useUserResourceFilter(): ResourceFilterHookResult {
  const { loading, data, error } = useResourceFilter();
  const filterResource = useFilterResource();
  const apollo = useApolloClient();

  const getResourcesVariables = resourceQueryPeriod(useCurrentPeriodScale());

  if (error) return { type: 'error' };
  if (loading || !data) return { type: 'loading' };

  const resources = [...data.resources].sort(
    (a, b) => a.displayIndex - b.displayIndex,
  );

  const hidden = new Set(data.myResourceFilter.items.map((x) => x.resource.id));

  const toggleAll = () => {
    filterResource({
      variables: {
        input: {
          hide: hidden.size === 0,
          selectAll: true,
        },
      },
    });

    const filterQuery = apollo.readQuery<ResourceFilterQuery>({
      query: ResourceFilterDocument,
    });
    if (filterQuery == null) return;
    const { resources: allResources, myResourceFilter } = filterQuery;

    apollo.writeQuery<ResourceFilterQuery>({
      query: ResourceFilterDocument,
      data: {
        __typename: 'Query',
        resources: allResources,
        myResourceFilter: {
          ...myResourceFilter,
          items:
            hidden.size === 0
              ? allResources.map((res) => ({
                  id: Math.random().toString() as ID,
                  resource: { id: res.id, __typename: 'Resource' },
                  __typename: 'ResourceFilterItem',
                }))
              : [],
        },
      },
    });
  };

  const handleToggle = (id: ID) => {
    const hide = !hidden.has(id);
    filterResource({
      variables: {
        input: {
          resourceId: id,
          hide,
          selectAll: false,
        },
      },
    });

    const filterQuery = apollo.readQuery<ResourceFilterQuery>({
      query: ResourceFilterDocument,
    });
    if (filterQuery == null) return;
    const { resources: allResources, myResourceFilter } = filterQuery;

    apollo.writeQuery<ResourceFilterQuery>({
      query: ResourceFilterDocument,
      data: {
        __typename: 'Query',
        resources: allResources,
        myResourceFilter: {
          ...myResourceFilter,
          items: hide
            ? [
                ...myResourceFilter.items,
                {
                  id: Math.random().toString() as ID,
                  resource: { id, __typename: 'Resource' },
                  __typename: 'ResourceFilterItem',
                },
              ]
            : myResourceFilter.items.filter((x) => x.resource.id !== id),
        },
      },
    });
  };

  const refetch = () => {
    apollo
      .query({
        query: TimelineResourcesDocument,
        variables: getResourcesVariables,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return {
    type: 'success',
    toggleAll,
    handleToggle,
    refetch,
    hidden,
    resources,
  };
}

function useLocalResourceFilter(): ResourceFilterHookResult {
  const { loading, data, error } = useSimpleResourceList();
  const apollo = useApolloClient();

  const getResourcesVariables = resourceQueryPeriod(useCurrentPeriodScale());

  // We use local storage as an easy way of persisting this configuration.
  // The data isn't critical and if lost, would only cause minor inconvenience,
  // and therefore local storage is an acceptable solution
  const [hiddenArr, setHiddenArr] = useLocalStorageState<ID[]>(
    'local-resource-filter',
    [],
  );

  if (error) return { type: 'error' };
  if (loading || !data) return { type: 'loading' };

  const hidden = new Set(hiddenArr);

  const resources = [...data.resources].sort(
    (a, b) => a.displayIndex - b.displayIndex,
  );

  const toggleAll = () => {
    if (hidden.size === 0) {
      setHiddenArr(resources.map((x) => x.id));
      return;
    }

    setHiddenArr([]);
  };

  const handleToggle = (id: ID) => {
    const hide = !hidden.has(id);
    if (hide) {
      setHiddenArr((arr) => arr.concat([id]));
    } else {
      setHiddenArr((arr) => arr.filter((x) => x !== id));
    }
  };

  const refetch = () => {
    // Since this passes through our custom ApolloLink in `apollo.service.ts`,
    // our local resource filter will be applied when we refetch
    apollo
      .query({
        query: TimelineResourcesDocument,
        variables: getResourcesVariables,
        fetchPolicy: 'network-only',
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return {
    type: 'success',
    toggleAll,
    handleToggle,
    refetch,
    hidden,
    resources,
  };
}
