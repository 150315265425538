import React from 'react';
import LeftChevron from '@mui/icons-material/ChevronLeft';
import RightChevron from '@mui/icons-material/ChevronRight';
import ButtonBase from '@mui/material/ButtonBase';
import { ButtonGroup } from 'src/components/Util/Buttons';
import { DoubleChevron } from './DoubleChevron';
import { Tooltip } from '@mui/material';

export interface TimeStepButtonsProps {
  right?: boolean;
  onLargeStep: () => void;
  onSmallStep: () => void;
}

export const TimeStepButtons: React.VFC<TimeStepButtonsProps> = ({
  right,
  onLargeStep,
  onSmallStep,
}) => {
  const large = (
    <Tooltip title={right ? 'Move Ahead 1 Week' : 'Move Back 1 Week'}>
      <ButtonBase onClick={onLargeStep}>
        <div>
          <DoubleChevron right={right} />
        </div>
      </ButtonBase>
    </Tooltip>
  );

  const small = (
    <Tooltip title={right ? 'Move Ahead 1 Day' : 'Move Back 1 Day'}>
      <ButtonBase onClick={onSmallStep}>
        <div>
          {right ? (
            <RightChevron style={{ marginLeft: 2 }} />
          ) : (
            <LeftChevron style={{ marginRight: 2 }} />
          )}
        </div>
      </ButtonBase>
    </Tooltip>
  );

  return (
    <div>
      <ButtonGroup>
        {right ? (
          <>
            {small}
            {large}
          </>
        ) : (
          <>
            {large}
            {small}
          </>
        )}
      </ButtonGroup>
    </div>
  );
};
