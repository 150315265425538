import { MAX_SCALE_CACHE_SIZE } from 'src/constants';

export interface ScaleCacheConfig {
  periodStart: number;
  periodEnd: number;
  width: number;
  workingHours: boolean;
}

export class ScaleCache {
  private config: ScaleCacheConfig | undefined;
  private cache: Map<number, number>;

  constructor() {
    this.cache = new Map<number, number>();
  }

  get(date: Date, scale: (x: Date) => number, cfg: ScaleCacheConfig) {
    if (this.shouldClear(cfg, scale)) {
      this.cache = new Map<number, number>();
    }

    const cached = this.cache.get(date.getTime());
    if (cached) return cached;
    let result = scale(date);
    if (isNaN(result) || !isFinite(result)) result = 0;
    this.set(date.getTime(), result);
    return result;
  }

  private set(key: number, value: number) {
    this.cache.set(key, value);
    // Remove every fouth item if too big, enables more consistent performance
    // than removing everything.
    if (this.cache.size > MAX_SCALE_CACHE_SIZE) {
      const keys = [...this.cache.keys()].filter((_, i) => i % 4 === 0);
      for (const k of keys) {
        this.cache.delete(k);
      }
    }
  }

  private shouldClear(
    cfg: ScaleCacheConfig,
    scale: (x: Date) => number,
  ): boolean {
    const shouldClear =
      this.config && this.config.workingHours !== cfg.workingHours;
    if (shouldClear) {
      this.config = cfg;
      return true;
    }

    const shouldShift =
      this.config &&
      (this.config.periodStart !== cfg.periodStart ||
        this.config.width !== cfg.width ||
        this.config.periodEnd !== cfg.periodEnd);

    if (shouldShift) {
      const old = this.config!;
      const mul =
        (scale(new Date(old.periodEnd)) +
          scale(new Date(cfg.periodStart)) -
          scale(new Date(old.periodStart))) /
        old.width;
      const offset = scale(new Date(old.periodStart));
      for (const [k, v] of this.cache.entries()) {
        const value = v * mul + offset;
        this.cache.set(k, value);
      }
    }

    this.config = cfg;
    return false;
  }
}
