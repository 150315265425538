import React from 'react';
import styled from 'styled-components';
import { Switch as MuiSwitch, Tooltip } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAppDispatch } from 'src/store/hooks';
import { useWorkingHours } from 'src/utils/scale';
import { setWorkingHours } from 'src/store/planner';
import { ReactComponent as AfterHoursIcon } from 'src/assets/icons/after-hours.svg';
import { ReactComponent as DaytimeIcon } from 'src/assets/icons/daytime.svg';

export const WorkingHoursToggle: React.VFC = () => {
  const on = useWorkingHours();
  const dispatch = useAppDispatch();

  useHotkeys(
    'shift+h',
    () => {
      const ae = document.activeElement as any;
      ae!.tagName !== 'INPUT' &&
        ae!.tagName !== 'TEXTAREA' &&
        dispatch(setWorkingHours(!on));
    },
    [on],
  );

  return (
    <div style={{ position: 'relative' }}>
      <IconStyle as={DaytimeIcon} style={{ left: 13 }} />
      <IconStyle as={AfterHoursIcon} style={{ right: 9.5 }} />
      <Tooltip title="Toggle After Hours">
        <Switch
          disableRipple
          checked={on}
          onChange={(e) => dispatch(setWorkingHours(e.target.checked))}
          classes={{
            switchBase: 'switch-base',
            thumb: 'thumb',
            track: 'track',
            checked: 'checked',
          }}
        />
      </Tooltip>
    </div>
  );
};

const IconStyle = styled.div`
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
`;

const Switch = styled(MuiSwitch)`
  z-index: 5; /* Above the icons */
  padding: 0;
  margin: 0;
  width: 81px;
  height: 40px;

  .switch-base {
    padding: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  .thumb {
    width: 32px;
    height: 32px;
    background-color: ${(props) => props.theme.mainAccent};
  }

  .checked {
    transform: translateX(41px);
  }

  .track {
    border-radius: 20px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.21);
    background-color: ${(props) => props.theme.lightTint} !important;
    width: 81px;
    height: 40px;
  }
`;
