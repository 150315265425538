import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Schedule, useSchedule, scheduleStatesFromRanges } from './Schedule';
import {
  GroupTitle,
  Subtitle,
  PrimaryActionButton,
  SecondaryActionButton,
} from 'src/components/Util';
import { ConfirmationModal } from 'src/components/Util/ConfirmationModal';
import { Grid } from '@mui/material';
import { timeMonday } from 'd3-time';
import { Link } from 'react-router-dom';
import { useSetDefaultResourceSchedule } from 'src/server/resources';
import { TimeRange } from 'src/models';
import { WEEKDAYS } from 'src/constants';
import { WeekPicker, formatWeek } from './WeekPicker';
import { Prompt } from 'src/components/Util/Prompt';

export const DefaultResourceSchedule: React.VFC<{
  resourceId: ID;
  resourceName: string;
  schedule: TimeRange[];
}> = ({ resourceId, resourceName, schedule }) => {
  const {
    editing,
    commit,
    revert,
    states,
    setRange,
    availableSegments,
    calculateRanges,
  } = useSchedule(scheduleStatesFromRanges(new Date(0), schedule));

  const mutate = useSetDefaultResourceSchedule();
  const [from, setFrom] = useState(timeMonday.ceil(new Date()));

  const saveSchedule = useCallback(() => {
    const ranges = calculateRanges();
    mutate({
      resourceId,
      from,
      ranges,
    }).then(() => {
      commit();
    });
  }, [resourceId, from, calculateRanges, mutate, commit]);

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  return (
    <div>
      <Prompt when={editing} />
      <Grid
        container
        justifyContent="space-between"
        spacing={8}
        style={{ marginBottom: 45 }}
      >
        <Grid item style={{ maxWidth: 464 }}>
          <GroupTitle>Default Resource Schedule</GroupTitle>
          <Subtitle>
            Click and drag to mark the default available hours for the week. To
            set time on specific dates{' '}
            <Link to={`./${resourceId}/schedule`}>edit the schedule</Link> for
            this resource.
          </Subtitle>
        </Grid>
        {true && <WeekPicker week={from} setWeek={setFrom} />}
        <Grid item style={{ alignSelf: 'flex-end' }}>
          <div style={{ display: 'flex' }}>
            {editing && (
              <SecondaryActionButton onClick={revert} text="Revert" />
            )}
            <div style={{ width: 21 }} />
            <PrimaryActionButton
              disabled={!editing}
              onClick={() => setSaveModalOpen(true)}
              text="SAVE AND APPLY"
            />
          </div>
        </Grid>
      </Grid>
      <Schedule
        dayHeadings={WEEKDAYS}
        states={states}
        daysPast={0}
        setRange={setRange}
      />
      <AvailableHours>
        <div>
          <strong>{availableSegments / 4}</strong> hours marked as available
        </div>
      </AvailableHours>
      {saveModalOpen && (
        <ConfirmationModal
          explanatoryText="You will override any custom scheduling on the resource from this date onwards"
          showWarningIcon
          confirm={() => {
            saveSchedule();
            setSaveModalOpen(false);
          }}
          confirmButtonText="SAVE AND APPLY"
          cancel={() => setSaveModalOpen(false)}
          headingText={
            <>
              <p style={{ marginBottom: 8 }}>
                Set Default Resource Schedule for {resourceName} starting from:
              </p>
              <p>
                <strong>{formatWeek(from)}</strong>
              </p>
            </>
          }
        />
      )}
    </div>
  );
};

const AvailableHours = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 16;
  margin-top: 26;
`;
