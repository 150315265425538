import React, { useState } from 'react';
import { Select } from 'src/components/Input/Select';
import { MenuItem } from '@mui/material';
import { ConfirmationModal } from 'src/components/Util/ConfirmationModal';
import { UNKNOWN_RESOURCE_TYPE_ID } from 'src/constants';
import {
  useResourceTypes,
  useSetResourceResourceType,
} from 'src/server/resources';

export const ResourceTypeSelect: React.VFC<{
  resourceId: ID;
  resourceTypeId: ID;
}> = ({ resourceId, resourceTypeId }) => {
  const [newTypeId, setNewTypeId] = useState<ID | null>(null);

  const { loading, error, data } = useResourceTypes();
  const setResourceType = useSetResourceResourceType();

  if (loading || error || !data) return null;

  return (
    <>
      <Select
        variant="standard"
        value={resourceTypeId}
        onChange={(e) => {
          if (resourceTypeId === UNKNOWN_RESOURCE_TYPE_ID) {
            setResourceType({
              resourceId,
              resourceTypeId: e.target.value as ID,
            });
          } else {
            setNewTypeId(e.target.value as ID);
          }
        }}
      >
        {data.resourceTypes.map((r) => (
          <MenuItem key={r.id} value={r.id}>
            {r.name}
          </MenuItem>
        ))}
      </Select>
      {newTypeId !== null && (
        <ConfirmationModal
          confirm={() => {
            setResourceType({
              resourceId,
              resourceTypeId: newTypeId,
            });
            setNewTypeId(null);
          }}
          confirmButtonText="CHANGE"
          cancel={() => setNewTypeId(null)}
          headingText="Change the resource's type"
          explanatoryText={
            <>
              Data on items on this resource will not be deleted, but the format
              of the data will be different for items created after this point.
            </>
          }
        />
      )}
    </>
  );
};
