import React, { useEffect } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useJumpToItem } from '../utils/item';
import { useDispatch } from 'react-redux';
import { screenCount } from 'src/store/planner';

export const UrlReactor: React.VFC = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const jumpToItem = useJumpToItem();
  const dispatch = useDispatch();

  useEffect(() => {
    const query = qs.parse(search.slice(1));
    navigate(pathname, { replace: true });

    if (query.screens && Number.isInteger(Number(query.screens))) {
      const screens = Number(query.screens);
      dispatch(screenCount(screens));
    }

    if (query.action == null) {
      return;
    }

    if (query.action === 'locate' && query.item != null) {
      jumpToItem(query.item as ID);
    }
  }, [navigate, search, pathname, jumpToItem, dispatch]);

  return null;
};

const makeQueryUrl = (object: Record<string, string>) =>
  `${window.location.origin}/?${qs.stringify(object)}`;

export const makeJumpToIconUrl = (itemId: ID) =>
  makeQueryUrl({ action: 'locate', item: itemId });
