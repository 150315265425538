export const hoursToSeconds = (h: Hours): Seconds => (h * 3600) as Seconds;
export const secondsToHours = (s: Seconds): Hours => (s / 3600) as Hours;

export function clamp(x: number, min: number, max: number) {
  if (x < min) return min;
  if (x > max) return max;
  return x;
}

export function minMax<T>(arr: T[], field: (a: T) => number): [number, number] {
  if (arr.length === 0) return [0, 0];

  const sorted = [...arr].sort((a, b) => field(a) - field(b));
  const first = sorted[0];
  const last = sorted[sorted.length - 1];
  return [field(first), field(last)];
}
