import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useNow(
  intervalMs: number,
  update?: (prev: Date, next: Date) => void,
) {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const sub = setTimeout(() => {
      const newDate = new Date();
      update?.(now, newDate);
      setNow(newDate);
    }, intervalMs);
    return () => {
      clearTimeout(sub);
    };
  }, [update, now, intervalMs]);
  return now;
}

/**
 * Like useState, but pulls from localStorage if present for the initial value.
 * `T` must be able to be faithfully converted to and from JSON.
 */
export function useLocalStorageState<T>(
  key: string,
  init: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue_] = useState(() => {
    const persisted = window.localStorage.getItem(key);
    if (persisted === null) return init;
    return JSON.parse(persisted);
  });

  const setValue = useCallback(
    (v: T | ((prevValue: T) => T)) => {
      if (typeof v === 'function') {
        setValue_((prevState: T) => {
          const set = v as (v: T) => T; // Help out typescript
          const newState = set(prevState);
          window.localStorage.setItem(key, JSON.stringify(newState));
          return newState;
        });
        return;
      }

      setValue_(v);
      window.localStorage.setItem(key, JSON.stringify(v));
    },
    [key],
  );

  return [value, setValue];
}

export function useShortcut(
  shortcut: string,
  fn: () => void,
  deps?: unknown[],
) {
  const handleEvent = () => {
    const ae = document.activeElement as any;
    if (ae!.tagName !== 'INPUT' && ae!.tagName !== 'TEXTAREA') {
      fn();
    }
  };

  useHotkeys(shortcut, handleEvent, deps);
  useHotkeys(`ctrl+${shortcut}`, handleEvent, deps);
}

const NUMBER_INPUT_REGEX = /^([0-9]*\.?[0-9]*)?$/;

export function useSetRangedValue({
  value,
  minValue,
  maxValue,
  setValue,
}: {
  value: number | null;
  minValue?: number;
  maxValue?: number;
  setValue: (value: number | null) => void;
}) {
  const [stringVal, setStringVal] = useState(
    value === null ? '' : value.toString(),
  );

  const onChange = (e: any) => {
    const v = e.target?.value ?? e;
    if (!NUMBER_INPUT_REGEX.test(v)) return;

    const num = parseFloat(v);
    if (maxValue !== undefined && v > maxValue) return;

    setStringVal(v);
    if (!isNaN(num)) setValue(num);
    else setValue(null);
  };

  const changeValue = (amount: number) => {
    const round = amount < 0 ? Math.ceil : Math.floor;
    const newValue = round((value === null ? 0 : value) + amount);

    if (minValue != null && newValue < minValue) {
      setValue(minValue);
      setStringVal('0');
      return;
    }

    if (maxValue != null && newValue > maxValue) {
      setValue(maxValue);
      setStringVal(maxValue.toString());
      return;
    }

    setValue(newValue);
    setStringVal(newValue.toString());
  };

  return {
    changeValue,
    stringVal,
    onChange,
  };
}
