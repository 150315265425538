import { DiscontinuityProvider } from '@d3fc/d3fc-discontinuous-scale';
import mergeRanges from 'merge-ranges';
import { TimeRange } from 'src/models';

class MergedProviders implements DiscontinuityProvider {
  copiedProviders: DiscontinuityProvider[];

  constructor(...providers: DiscontinuityProvider[]) {
    this.copiedProviders = providers.map((p) => p.copy());
    this.clampUp = this.clampUp.bind(this);
    this.clampDown = this.clampDown.bind(this);
  }

  clampUp(date: Date): Date {
    let outputDate = new Date(date.getTime());
    let nextOutputDates = [];
    do {
      nextOutputDates = this.copiedProviders
        .map((p) => p.clampUp(date).getTime())
        .filter((d) => d > outputDate.getTime()); // eslint-disable-line no-loop-func

      if (nextOutputDates.length > 0) {
        outputDate = new Date(Math.max(...nextOutputDates));
      }
    } while (nextOutputDates.length > 0);

    return outputDate.getTime() === date.getTime() ? date : outputDate;
  }

  clampDown(date: Date): Date {
    let outputDate = new Date(date.getTime());
    let nextOutputDates = [];
    do {
      nextOutputDates = this.copiedProviders
        .map((p) => p.clampDown(date).getTime())
        .filter((d) => d < outputDate.getTime()); // eslint-disable-line no-loop-func

      if (nextOutputDates.length > 0) {
        outputDate = new Date(Math.max(...nextOutputDates));
      }
    } while (nextOutputDates.length > 0);

    return outputDate.getTime() === date.getTime() ? date : outputDate;
  }

  distance(startDate: Date, endDate: Date): number {
    const start = startDate.getTime();
    const end = endDate.getTime();
    if (start === end) return 0;
    if (start > end) {
      return -this.distance(endDate, startDate);
    }

    const elapsedTime = end - start;

    // Calculate the distance using all providers, filtering out non gaps
    const distancesWithGaps = [];
    for (const p of this.copiedProviders) {
      const dist = p.distance(startDate, endDate);
      // If a DiscontinuityProvider returns 0 for the distance, also return zero
      if (dist === 0) return 0;
      if (dist !== elapsedTime) distancesWithGaps.push(dist);
    }

    switch (distancesWithGaps.length) {
      case 0:
        return elapsedTime;
      case 1:
        // If only one distance with gap is returned from above
        // return it, as we know the gaps do no overlap.
        return distancesWithGaps[0];
      default: {
        // In this case we do not know if the gaps overlap or not
        // split the time in half recalculate
        const midTime = (start + end) * 0.5;
        const midDate = new Date(midTime);
        return (
          this.distance(this.clampUp(startDate), this.clampDown(midDate)) +
          this.distance(this.clampUp(midDate), this.clampDown(endDate))
        );
      }
    }
  }

  offset(startDate: Date, _ms: any): Date {
    // This function will infinitely loop if _ms is a decimal number
    // Rounding it to remove the decimals resolves this issue
    const ms = Math.round(_ms);
    let outputDate = new Date(startDate.getTime() + ms);
    let expectedDuration = this.distance(startDate, outputDate);

    while (expectedDuration !== ms) {
      const differenceInMs = ms - expectedDuration;

      const clampFunc = differenceInMs < 0 ? this.clampDown : this.clampUp;
      const clampedNextTime = new Date(
        clampFunc(outputDate).getTime() + differenceInMs,
      );
      const nextDifference = this.distance(outputDate, clampedNextTime);

      outputDate = clampedNextTime;
      expectedDuration += nextDifference;
    }
    return outputDate;
  }

  copy(): DiscontinuityProvider {
    return new MergedProviders(...this.copiedProviders);
  }
}

export function mergeDiscontinuityProviders(
  ...providers: DiscontinuityProvider[]
): DiscontinuityProvider {
  return new MergedProviders(...providers);
}

function flatten<T>(arr: Array<T[]>): T[] {
  const result = [];
  for (const innerArr of arr) {
    result.push(...innerArr);
  }
  return result;
}

export function mergeGetHiddenRanges(
  ...funcs: Array<(start: Date, end: Date) => TimeRange[]>
) {
  return (start: Date, end: Date): TimeRange[] => {
    const ranges = flatten(funcs.map((g) => g(start, end))).map(
      ({ startTime, endTime }) => [startTime, endTime] as [Date, Date],
    );

    const merged = mergeRanges(ranges);
    return merged.map(([startTime, endTime]) => ({ startTime, endTime }));
  };
}
