import React, { useCallback } from 'react';

export interface ItemPositionerProps {
  id?: string;
  left: number;
  top: number;
  height: number;
}

export const ItemPositioner: React.FC<ItemPositionerProps> = ({
  id,
  left,
  top,
  children,
}) => {
  const preventClickHandler = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      id={id}
      onClick={preventClickHandler}
      style={{ position: 'absolute', userSelect: 'none', left, top }}
    >
      {children}
    </div>
  );
};
