import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

export const DayColumn: React.VFC<{
  currentStates: boolean[];
  past: boolean;
  setRange: (v: boolean, start: number, end: number) => void;
  isHoliday: boolean;
}> = ({ currentStates, past, setRange, isHoliday }) => {
  const [stateOverride, setStateOverrides] = useState<Array<boolean | null>>(
    Array(4 * 24).fill(null),
  );

  // update stateOverride when currentStates changes
  useEffect(() => {
    setStateOverrides(Array(4 * 24).fill(null));
  }, [currentStates]);

  // useMemo only recomputes states when currentStates or stateOverride change
  const states = useMemo(() => {
    return currentStates.map((s, i) =>
      stateOverride[i] == null ? s : stateOverride[i]!,
    );
  }, [currentStates, stateOverride]);

  const [startDrag, setStartDrag] = useState<StartDrag | null>(null);
  const [endIndex, setEndIndex_] = useState<number | null>(null);


  const commit = () => {
    if (startDrag == null || endIndex == null) return;
    const [start, end] = getBounds(startDrag, endIndex);
    setRange(startDrag.setTo, start, end);
    setStartDrag(null);
    setStateOverrides((s) => s.map(() => null));
  };

  const setEndIndex = (endIndex: number, done: boolean) => {
    if (startDrag === null) return;
    const [start, end] = getBounds(startDrag, endIndex);
    setEndIndex_(endIndex);

    const toSet = startDrag.setTo;
    if (done) {
      setStartDrag(null);
      setRange(toSet, start, end);
      setStateOverrides((s) => s.map(() => null));
    } else {
      setStateOverrides((s) =>
        s.map((_, i) => (start <= i && i <= end ? toSet : null)),
      );
    }
  };

  const handleToggleAllSegments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allChecked = e.target.checked;
    const toSet = Array(4 * 24).fill(allChecked);
    setStateOverrides(toSet);
    setRange(allChecked, 0, 4 * 24 - 1);
  }

  return (
    <div onMouseLeave={commit}>
      <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '0.25rem', color: 'gray'}}>
        <label htmlFor="toggle-all">all</label>
        <input 
          type="checkbox"
          id='toggle-all'
          onChange={handleToggleAllSegments}
          checked={states.every(s => s)}
        />
      </div>
      {Array(4 * 24)
        .fill(undefined)
        .map((_, i) => (
          <Segment
            key={i}
            title={formatTooltip(i)}
            active={states[i]}
            past={past}
            isHoliday={isHoliday}
            onMouseDown={(e) => {
              e.preventDefault();
              setStartDrag({
                setTo: !states[i],
                idx: i,
              });
            }}
            onMouseUp={(e) => {
              e.preventDefault();
              setEndIndex(i, true);
            }}
            onMouseEnter={() => setEndIndex(i, false)}
          />
        ))}
    </div>
  );
};

interface StartDrag {
  setTo: boolean;
  idx: number;
}

const getBounds = (startDrag: StartDrag, endIndex: number) =>
  endIndex > startDrag.idx
    ? [startDrag.idx, endIndex]
    : [endIndex, startDrag.idx];

function formatTooltip(segmentIndex: number): string {
  let hour: string;
  if (segmentIndex < 4) {
    hour = '12';
  } else if (segmentIndex < 13 * 4) {
    hour = Math.floor(segmentIndex / 4).toString();
  } else {
    hour = Math.floor(segmentIndex / 4 - 12).toString();
  }

  let minute = ((segmentIndex % 4) * 15).toString();
  if (minute === '0') minute = '00';

  return `${hour}:${minute} ${segmentIndex >= 12 * 4 ? 'PM' : 'AM'}`;
}

interface SegmentProps {
  active: boolean;
  past: boolean;
  isHoliday: boolean;
}

const Segment = styled.div<SegmentProps>`
  height: 12px;
  margin: 0 3.5px;

  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;

  background-color: ${(props) =>
    props.isHoliday && props.active
      ? '#f24b6a'
      : props.active
      ? props.theme.secondaryAccent
      : props.theme.lightTint};

  ${(props) =>
    props.past &&
    `
    opacity: 0.3;
  `}
`;
