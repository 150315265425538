import { RootState } from '.';
import * as Sentry from '@sentry/react';
import { AuthorizationPolicy } from 'src/generated/graphql';
import { store } from '.';
import { getApolloClient } from 'src/services/apollo.service';
import { authDisabled } from 'src/services/auth.service';
import { UserContext, currentUser } from 'src/server/user';

export interface LoginState {
  readonly user: UserContext | null;
}

const initialState: LoginState = {
  user: null,
};

export const loginReducer = (state = initialState, action: any): LoginState => {
  switch (action.type) {
    case 'AAD_LOGIN_SUCCESS':
      if (state.user) return state;
      currentUser(getApolloClient())
        .then((res) => {
          store.dispatch({
            type: 'USER_FETCH_SUCCESS',
            payload: res,
          });
        })
        .catch(Sentry.captureException);

      return state;
    case 'AAD_LOGOUT_SUCCESS':
      return { ...state, user: null };
    case 'USER_FETCH_SUCCESS':
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export const AccountSelectors = {
  user: (state: RootState) => state.login.user,
  isAdmin: (state: RootState) => UserUtils.isAdmin(state.login.user),
  canEdit: (state: RootState) => UserUtils.canEdit(state.login.user),
};

export const UserUtils = {
  isAdmin: (user: UserContext | null) =>
    authDisabled ||
    (!!user && user.policies.includes(AuthorizationPolicy.Admin)),
  canEdit: (user: UserContext | null) =>
    authDisabled ||
    (!!user && user.policies.includes(AuthorizationPolicy.Write)),
};
