import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Assignment as Icon } from '@mui/icons-material';
import { ItemDropTarget } from 'src/components/ItemDropTarget';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { openClipboard } from 'src/store/planner';
import { ITEM_TYPES } from 'src/models/item';
import { useShortcut } from 'src/utils/hooks';

export const ClipboardIcon: React.VFC = () => {
  const dispatch = useAppDispatch();

  const [clipboardOpened, clipboardLoading] = useAppSelector(({ planner }) => [
    planner.openClipboard,
    planner.clipboardLoading,
  ]);

  useShortcut('shift+c', () => {
    dispatch(openClipboard(true));
  });

  return (
    <ItemDropTarget targetKeys={ITEM_TYPES} dropData={{ type: 'clipboard' }}>
      <Tooltip title={clipboardOpened ? 'Close Clipboard' : 'Open Clipboard'}>
        <span>
          <IconButton
            className="clipboard-icon"
            disabled={clipboardLoading}
            aria-label="delete"
            onClick={() => dispatch(openClipboard(!clipboardOpened))}
            size="large"
          >
            <Icon
              fontSize="large"
              style={{
                color:
                  clipboardOpened || clipboardLoading ? '#dfe2e8' : '#202252',
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
    </ItemDropTarget>
  );
};
