import React from 'react';
import styled from 'styled-components';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactComponent as BackIcon } from 'src/assets/icons/back.svg';
import { Link, useNavigate } from 'react-router-dom';
import { SolidButton } from './Buttons';
import LinearProgress from '@mui/material/LinearProgress';

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
`;

export const Subtitle = styled.h4`
  margin-top: 5px;
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => props.theme.settingsSecondary};

  a {
    color: ${(props) => props.theme.linkColor};
  }
`;

export const TidyLink = styled(Link)`
  color: ${(props) => props.theme.secondaryAccent};
  font-size: 14px;
  text-decoration: none;
`;

export const Divider = styled.div`
  margin: 30px -53px;
  height: 0px;
  border: solid 1px ${(props) => props.theme.lightTint};
`;

export const GroupTitle = styled.h6`
  font-size: 18px;
  font-weight: 500;
`;

export const InputLabel = styled.label`
  font-size: 18px;
`;

export const BasicBackButton: React.VFC<IconButtonProps> = (props) => (
  <IconButton {...props} size="large">
    <BackIcon />
  </IconButton>
);

export const BackButton: React.VFC<IconButtonProps> = (props) => {
  const navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate(-1)} {...props} size="large">
      <BackIcon />
    </IconButton>
  );
};

export const Label = styled(FormControlLabel)`
  font-size: 18px;
`;

export const PrimaryActionButton: React.VFC<{
  onClick: () => void;
  text: string;
  disabled?: boolean;
}> = ({ onClick, text, disabled }) => (
  <SolidButton
    onClick={() => onClick()}
    color="#fff"
    backgroundColor="#5878ff"
    disabled={disabled}
    style={disabled ? { opacity: '0.2' } : undefined}
  >
    <span style={{ fontSize: 14, letterSpacing: 2 }}>{text}</span>
  </SolidButton>
);

export const SecondaryActionButton: React.VFC<{
  onClick: () => void;
  text: string;
  disabled?: boolean;
}> = ({ onClick, text, disabled }) => (
  <SolidButton
    onClick={() => onClick()}
    color="#000"
    backgroundColor="#eff1f7"
    disabled={disabled}
    style={disabled ? { opacity: '0.2' } : undefined}
  >
    <span style={{ fontSize: 16, fontWeight: 500 }}>{text}</span>
  </SolidButton>
);

export const LoadingOverlay: React.VFC = () => (
  <LinearProgress
    style={{ position: 'absolute', width: '100%', top: 0, left: 0 }}
  />
);
