import { TextBox, TextBoxProps } from 'src/components/Input/TextBox';
import { useSetRangedValue } from 'src/utils/hooks';

export interface DurationBoxProps {
  value: Hours;
  setValue: (value: Hours) => void;
  disabled?: boolean;
  roundValue?: number;
}

export const DurationBox: React.FC<
  Omit<TextBoxProps, 'setValue'> & DurationBoxProps
> = ({ value, setValue, disabled, roundValue, ...textBox }) => {
  const { onChange, stringVal } = useSetRangedValue({
    minValue: 0,
    value,
    setValue: (v) => {
      if (v != null) {
        setValue(
          roundValue
            ? ((Math.ceil(v / roundValue) * roundValue) as Hours)
            : (v as Hours),
        );
      }
    },
  });

  return (
    <TextBox
      value={stringVal}
      style={{ width: '4.5em', marginLeft: '0.5em' }}
      setValue={onChange}
      disabled={disabled}
      inputProps={{
        style: { padding: '0.25em 0.5em' },
      }}
      {...textBox}
    />
  );
};
