import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .LoadingText {
    margin-top: 5px;
    font-weight: 500;
  }
`;

export interface LoadingCenteredProps {
  message?: string;
}

export const LoadingCentered: React.VFC<LoadingCenteredProps> = ({
  message,
}) => (
  <Container>
    <CircularProgress />
    {message && <span className="LoadingText">{message}</span>}
  </Container>
);
