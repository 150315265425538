import React from 'react';
import styled from 'styled-components';
import { LinearProgress, Divider } from '@mui/material';
import { changeTypeMap } from '../Changes/ChangeList';
import { timeFormat } from 'd3-time-format';
import { ChangesQuery } from 'src/generated/graphql';
import { ApolloError } from '@apollo/client';

export interface ItemChangesProps {
  error?: ApolloError | undefined;
  data: ChangesQuery | undefined;
}

export const ItemChanges: React.VFC<ItemChangesProps> = ({ error, data }) => {
  const formatDate = timeFormat('%H:%M %b %d, %Y');

  if (error) return <div />;
  if (!data?.changes) {
    return <LinearProgress />;
  }

  return (
    <ChangesContainer>
      <div className="title">History</div>
      {[...data.changes]
        .sort((a, b) => b.date.getTime() - a.date.getTime())
        .map((change) => (
          <div key={change.id}>
            <div className="type">{changeTypeMap[change.changeType]}</div>
            <div className="user">{change.userName}</div>
            <div className="date">{formatDate(change.date)}</div>
            <Divider style={{ marginBottom: 20 }} />
          </div>
        ))}
    </ChangesContainer>
  );
};

const ChangesContainer = styled.div`
  margin-top: 40px;
  color: ${(props) => props.theme.historyText};

  .title {
    margin-bottom: 20px;
    font-weight: 500;
  }

  .type {
    font-weight: 500;
  }
`;
