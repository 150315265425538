import * as msal from '@azure/msal-browser';
import { env } from 'src/runtime-environment';
import { store } from 'src/store';

export const Scopes = {
  AZURE_STORAGE: env.AZURE_AD_STORAGEAPI_IDENTIFIER,
  GRAPH_API: env.AZURE_AD_API_IDENTIFIER,
};

const config: msal.Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${env.AZURE_AD_TENANT_ID}`,
    clientId: env.AZURE_AD_CLIENT_ID,
    // The configured reply url
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
};

let provider: msal.PublicClientApplication | null = null;
export const getProvider = (): msal.PublicClientApplication => {
  if (provider) return provider;

  provider = new msal.PublicClientApplication(config);

  provider.addEventCallback((message) => {
    if (message.eventType === msal.EventType.LOGOUT_SUCCESS) {
      store.dispatch({ type: 'AAD_LOGOUT_SUCCESS' });
    }
  });

  return provider;
};

export const getBackendToken = () => {
  const provider = getProvider();
  return provider
    .acquireTokenSilent({
      scopes: [Scopes.GRAPH_API],
      account: provider.getAllAccounts()[0],
    })
    .catch(async (error) => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return await provider.acquireTokenPopup({
          scopes: [Scopes.GRAPH_API],
          account: provider.getAllAccounts()[0],
        });
      }

      throw error;
    });
};

export const authenticationRequest: msal.RedirectRequest = {
  scopes: [Scopes.GRAPH_API],
  extraScopesToConsent: [Scopes.AZURE_STORAGE],
};

const isKiosk = window.location.host.includes('kiosk');
export const kioskMode = isKiosk;

// Can flick to `true` for development purposes, if/when desired.
// This is useful on mobile where you cannot use localhost & AD login requires
// https on non-localhost hosts.
const disableAuth = false;
export const authDisabled = isKiosk || disableAuth;
