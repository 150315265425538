import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { useApolloClient } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { difference, flatten, uniq } from 'lodash';
import {
  Scalars,
  CreateChainCommandInput,
  Run,
  GetAdditionalItemDocument,
  GetAdditionalItemQuery,
} from '../../generated/graphql';
import { Chain } from '.';
import { Positioning } from 'src/components/Positioning';
import { CreateChainButton } from './CreateChainButton';
import { RemoveChainButton } from './RemoveChainButton';
import { deleteChain, useGetChain } from 'src/server/chains';
import { ItemState, ItemLayoutData, ItemLayoutUtils } from 'src/models/item';
import { Scale } from 'src/utils/scale';
import { MIN_RESOURCE_HEIGHT } from 'src/constants';

const ITEM_VERTICAL_OFFSET = 15;
const PROCESS_MARGIN = 10;

type Item = NonNullable<GetAdditionalItemQuery['item']>;

interface ChainsProps {
  itemId: Scalars['ID'];
  items: { [index: string]: ItemLayoutData };
  resourceTops: Record<string, number>;
  scale: Scale;
  startCreateChain: (data: Partial<CreateChainCommandInput>) => void;
  inProgressChain?: { start: any; end: any };
  setItemStateOverrides: (v: Record<string, ItemState>) => void;
  canEdit: boolean;
}

interface CalcPosResult {
  x: number;
  y: number;
  fromBuffer?: boolean;
}

export const Chains: React.VFC<ChainsProps> = ({
  itemId,
  items,
  resourceTops,
  scale,
  startCreateChain,
  inProgressChain,
  setItemStateOverrides,
  canEdit,
}) => {
  const [additionalItems, setAdditionalItems] = useState<{
    [index: string]: Item;
  }>({});
  const { loading, data } = useGetChain(itemId);
  const client = useApolloClient();

  const differences = useMemo(() => {
    const loadedItems = Object.keys(items);
    const expectedItems = uniq(
      flatten(
        ((data || {}).fullChain || []).map((d) => [
          d.sourceItemId,
          d.targetItemId,
        ]),
      ),
    );
    return difference(expectedItems, loadedItems);
  }, [items, data]);

  useEffect(() => {
    const expectedItems = flatten(
      ((data || {}).fullChain || []).map((d) => [
        d.sourceItemId,
        d.targetItemId,
      ]),
    );

    const map: Record<string, ItemState> = {};
    for (const id of expectedItems) {
      map[id] = ItemState.Active;

      const findParent = Object.values(items).find((x) =>
        ((x.item as Run).runUsages || []).find((x) => x.process.id === id),
      );

      if (findParent) {
        map[findParent.item.id] = ItemState.Active;
      }
    }

    setItemStateOverrides(map);
  }, [data, items, setItemStateOverrides]);

  useEffect(() => {
    const newItems = {} as { [index: string]: any };
    const promises = differences.map((id) =>
      client
        .query({ query: GetAdditionalItemDocument, variables: { id } })
        .then(({ data: { item } }) => {
          newItems[id] = item;
        }),
    );
    Promise.all(promises).then(() => {
      setAdditionalItems(newItems);
    });
  }, [setAdditionalItems, client, differences]);

  if (loading) {
    return (
      <LinearProgress
        variant="query"
        style={{ position: 'absolute', top: 0, width: '100vw' }}
      />
    );
  }

  function generateChain({
    id,
    sourceItemId,
    targetItemId,
  }: {
    id: Scalars['ID'];
    sourceItemId: Scalars['ID'];
    targetItemId: Scalars['ID'];
  }) {
    let _start;
    let end;
    const source = items[sourceItemId];

    const getStartTime = (item: any): Date => {
      if (item.lag != null) {
        return new Date(
          item.timelineUsage!.startTime.getTime() +
            ((item as any).lag ?? 0) * 1000,
        );
      }

      return item.timelineUsage!.startTime;
    };

    if (source) {
      _start = calcPos(source, resourceTops, true);
    } else if (additionalItems[sourceItemId]) {
      const item = additionalItems[sourceItemId];
      const x = scale(getStartTime(item));

      const resourceTop = resourceTops[item.timelineUsage!.resourceId];
      let y =
        resourceTop == null ? null : resourceTop + MIN_RESOURCE_HEIGHT / 2;

      // @ts-ignore
      const fromBuffer = item.lag && item.lag > 0;
      if (fromBuffer && resourceTop) y! += 30;

      _start = { x, y, fromBuffer };
    }

    const target = items[targetItemId];
    if (target) {
      end = calcPos(target, resourceTops, false);
    } else if (additionalItems[targetItemId]) {
      const item = additionalItems[targetItemId];
      const x = scale(getStartTime(item));

      const resourceTop = resourceTops[item.timelineUsage!.resourceId];
      const y =
        resourceTop == null ? null : resourceTop + MIN_RESOURCE_HEIGHT / 2;
      end = { x, y };
    }

    if (!_start || !end) return null;

    const { fromBuffer, ...start } = _start;
    const item = items[sourceItemId === itemId ? targetItemId : sourceItemId];
    return (
      <Fragment key={id}>
        <Chain
          {...{
            start,
            end,
            sourceItemId,
            targetItemId,
            fromBuffer: fromBuffer || false,
          }}
        />
        {(sourceItemId === itemId || targetItemId === itemId) &&
          item &&
          canEdit && (
            <PositionedRemoveChainButton
              {...{
                itemData:
                  items[sourceItemId === itemId ? targetItemId : sourceItemId],
                resourceTops,
                isOutput: targetItemId === itemId,
                onClick: () => {
                  deleteChain(client, { id });
                },
              }}
            />
          )}
      </Fragment>
    );
  }

  const selectedItem = items[itemId] ? items[itemId].item : undefined;
  if (!selectedItem) return null;

  const item = items[itemId];
  return (
    <>
      {data && data.fullChain.map(generateChain)}
      {inProgressChain && (
        <Chain
          {...{
            start: { x: inProgressChain.start.x, y: inProgressChain.start.y },
            end: inProgressChain.end,
            fromBuffer: inProgressChain.start.fromBuffer || false,
            sourceItemId: 'wip-source',
            targetItemId: 'wip-target',
          }}
        />
      )}
      {!inProgressChain && item && canEdit && (
        <>
          <PositionedCreateChainBtn
            {...{
              itemData: item,
              resourceTops,
              isOutput: false,
              onClick: startCreateChain,
            }}
          />
          <PositionedCreateChainBtn
            {...{
              itemData: item,
              resourceTops,
              isOutput: true,
              onClick: startCreateChain,
            }}
          />
        </>
      )}
    </>
  );
};

export function calcPos(
  itemData: ItemLayoutData,
  resourceTops: Record<string, number>,
  isOutput: boolean,
  button?: boolean,
): CalcPosResult {
  const resourceTop = resourceTops[itemData.item.timelineUsage!.resourceId];

  const result: CalcPosResult = isOutput
    ? ItemLayoutUtils.outputTimelinePosition(itemData, resourceTop)
    : ItemLayoutUtils.inputTimelinePosition(itemData, resourceTop);

  if (button) {
    if (isOutput) {
      const r = ItemLayoutUtils.chainCreateButtonRightPosition(
        itemData,
        resourceTop,
      );
      r.y -= ITEM_VERTICAL_OFFSET;
      r.x += PROCESS_MARGIN;
      return r;
    } else {
      result.y -= ITEM_VERTICAL_OFFSET;
      result.x -= 40;
    }
  }

  return result;
}

const PositionedCreateChainBtn: React.VFC<{
  itemData: ItemLayoutData;
  resourceTops: Record<string, number>;
  isOutput: boolean;
  onClick: (data: Partial<CreateChainCommandInput>) => void;
}> = ({ itemData, resourceTops, isOutput, onClick }) => {
  const pos = calcPos(itemData, resourceTops, isOutput, true);
  return (
    <Positioning
      style={{ position: 'absolute', top: pos.y, zIndex: 10 }}
      leftOffset={pos.x}
    >
      <CreateChainButton
        onClick={() =>
          onClick({
            [`${isOutput ? 'source' : 'target'}ItemId`]: itemData.item.id,
          })
        }
      />
    </Positioning>
  );
};

const PositionedRemoveChainButton: React.VFC<{
  itemData: ItemLayoutData;
  resourceTops: Record<string, number>;
  isOutput: boolean;
  onClick: () => void;
}> = ({ itemData, resourceTops, isOutput, onClick }) => {
  const pos = calcPos(itemData, resourceTops, isOutput, true);
  return (
    <Positioning
      style={{ position: 'absolute', top: pos.y, zIndex: 10 }}
      leftOffset={pos.x}
    >
      <RemoveChainButton {...{ onClick }} />
    </Positioning>
  );
};
