import { ApolloClient } from '@apollo/client';
import {
  CreateChainCommandInput,
  CreateChainDocument,
  CreateChainMutationFn,
  DeleteChainCommandInput,
  DeleteChainDocument,
  DeleteChainMutationFn,
  GetItemInputsDocument,
  useGetChainQuery,
} from 'src/generated/graphql';

export function useGetChain(itemId: ID) {
  return useGetChainQuery({
    variables: { itemId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
}

export function createChain(
  apolloClient: ApolloClient<unknown>,
  input: CreateChainCommandInput,
  itemId: ID,
) {
  return (apolloClient.mutate as CreateChainMutationFn)({
    mutation: CreateChainDocument,
    variables: { input },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetItemInputsDocument,
        variables: { id: itemId },
      },
    ],
    // Perhaps the chain already existed
    errorPolicy: 'ignore',
  }).catch(console.error);
}

export function deleteChain(
  apolloClient: ApolloClient<object>,
  input: DeleteChainCommandInput,
) {
  return (apolloClient.mutate as DeleteChainMutationFn)({
    mutation: DeleteChainDocument,
    variables: { input },
  })
    .then(() => apolloClient.resetStore())
    .catch(console.error);
}
