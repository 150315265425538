import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip } from '@mui/material';
import { useShortcut } from 'src/utils/hooks';
import { OutlinedButton } from '../Util/Buttons';
import { SearchModalContents } from './SearchModalContents';

export const SearchModal: React.VFC = () => {
  const [open, setOpen] = useState(false);

  useShortcut(
    'shift+s',
    () => {
      setOpen((open) => !open);
    },
    [],
  );

  return (
    <div>
      <Tooltip title="Search for items (±3 months from now)">
        <span>
          <OutlinedButton
            className="search-icon"
            disabled={open}
            aria-label="delete"
            onClick={() => setOpen(true)}
          >
            <SearchIcon style={{ margin: '0 0.5em 0 -0.3em' }} />
            Search
          </OutlinedButton>
        </span>
      </Tooltip>
      <SearchModalContents open={open} setOpen={setOpen} />
    </div>
  );
};
