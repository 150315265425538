import { DiscontinuityProvider } from '@d3fc/d3fc-discontinuous-scale';
import { timeFormat } from 'd3-time-format';
import { timeDay, timeMillisecond } from 'd3-time';
import { TimeRange } from 'src/models';

const timeStringFormat = timeFormat('%H:%M:%S.%L');
const FORMAT_SPLITTER = /[:.]/g;

// hours, minutes, seconds, ms
const startOfWorkDay_ = '05:00:00.000';
const startOfWorkDay = parse(startOfWorkDay_);
const endOfWorkDay_ = '22:00:00.000';
const endOfWorkDay = parse(endOfWorkDay_);

function parse(date: string) {
  return new Date(`1970-01-01T${date}Z`).getTime();
}

const dayBeginHour = [0, 0, 0, 0];
const startHour = startOfWorkDay_
  .split(FORMAT_SPLITTER)
  .map((str) => Number(str));
const endHour = endOfWorkDay_.split(FORMAT_SPLITTER).map((str) => Number(str));
const dayEndHour = [23, 59, 59, 999];

const arrToMS = ([h, m, s, ms]: number[]): number =>
  ms + 1000 * (s + 60 * (m + h * 60));
const gap =
  arrToMS(dayEndHour) -
  arrToMS(endHour) +
  (arrToMS(startHour) - arrToMS(dayBeginHour)) +
  1;

const startWorkDayMs = arrToMS(startHour);
const endWorkDayMs = arrToMS(endHour);

function msIntoDay(date: Date) {
  const day = new Date(date);
  day.setHours(0, 0, 0, 0);
  return date.getTime() - day.getTime();
}

// TODO: much faster still slightly slow
class WorkingHoursProvider implements DiscontinuityProvider {
  getHiddenRanges(start: Date, end: Date): TimeRange[] {
    // Start a day before to handle any overlaps - intervals before `start` will
    // be ignored by items anyway
    const curDayBegin = timeDay.offset(timeDay.floor(start), -1);
    return timeDay.range(curDayBegin, end).map((day) => {
      const nextDay = timeDay.offset(day, 1);
      return {
        startTime: timeMillisecond.offset(day, endWorkDayMs),
        endTime: timeMillisecond.offset(nextDay, startWorkDayMs),
      };
    });
  }

  clampUp(date: Date): Date {
    const time = msIntoDay(date);
    if (time < startOfWorkDay) {
      const output = new Date(date);
      // @ts-ignore (this variable is in the expected form)
      output.setHours(...startHour);
      return output;
    }
    if (time > endOfWorkDay) {
      const output = timeDay.offset(date, 1);
      // @ts-ignore (this variable is in the expected form)
      output.setHours(...startHour);
      return output;
    }

    return date;
  }

  clampDown(date: Date): Date {
    const time = msIntoDay(date);
    if (time < startOfWorkDay) {
      const output = timeDay.offset(date, -1);
      // @ts-ignore (this variable is in the expected form)
      output.setHours(...endHour);
      return output;
    }
    if (time > endOfWorkDay) {
      const output = new Date(date);
      // @ts-ignore (this variable is in the expected form)
      output.setHours(...endHour);
      return output;
    }

    return date;
  }

  distance(_startDate: Date, _endDate: Date): number {
    const startDate = this.clampUp(_startDate);
    const endDate = this.clampDown(_endDate);

    const onSameDay =
      timeDay(startDate).getTime() === timeDay(endDate).getTime();
    const startTime = timeStringFormat(startDate);
    const endTime = timeStringFormat(endDate);

    const msDifference = endDate.getTime() - startDate.getTime();

    if (onSameDay && startTime >= startOfWorkDay_ && endTime <= endOfWorkDay_) {
      return msDifference;
    }
    // This currently assumes startDate & endDate are in the same timezone
    const daysInBetween = timeDay.count(startDate, endDate);
    return msDifference - daysInBetween * gap;
  }

  offset(startDate: Date, ms: any): Date {
    // TODO: optimise this instead of using search algorithm
    let outputDate = new Date(startDate.getTime() + ms);
    let expectedDuration = this.distance(startDate, outputDate);

    let i = 0;
    while (expectedDuration !== ms) {
      const differenceInMs = ms - expectedDuration;

      const clampFn =
        i % 2 === 0 ? this.clampUp.bind(this) : this.clampDown.bind(this);
      const clampedNextTime = new Date(
        clampFn(outputDate).getTime() + differenceInMs,
      );
      const nextDifference = this.distance(outputDate, clampedNextTime);

      outputDate = clampedNextTime;
      expectedDuration += nextDifference;
      i += 1;
    }
    return outputDate;
  }

  copy(): DiscontinuityProvider {
    return new WorkingHoursProvider();
  }
}

export const WorkingHours = new WorkingHoursProvider();
