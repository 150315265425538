import React from 'react';
import { DragSourceMonitor } from 'react-dnd';
import { DragLayer } from './DragLayer';
import { useDragItem } from './hooks';

export interface DraggableContainerProps {
  id: string;
  targetKey: string;
  dragData: {
    itemId: string;
    inventoryWorkstationIds: Array<ID | null>;
  };
  onDrag: (monitor: DragSourceMonitor) => void;
  onDrop: (monitor: DragSourceMonitor, permitted: boolean) => void;
  dragDisabled: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const DraggableContainer: React.VFC<DraggableContainerProps> = ({
  id,
  targetKey,
  dragData,
  onDrag,
  onDrop,
  dragDisabled,
  style,
  children,
}) => {
  const [info, dragRef] = useDragItem({
    id,
    targetKey,
    dragData,
    onDrag,
    onDrop,
    dragDisabled,
  });

  return (
    <>
      <div
        ref={dragRef}
        style={{
          opacity: info.isDragging ? 0.33 : 1,
          cursor: 'pointer',
          marginBottom: 4,
          ...style,
        }}
      >
        {children}
      </div>
      {info.isDragging && (
        <DragLayer offsetX={info.offset.x} offsetY={info.offset.y}>
          {children}
        </DragLayer>
      )}
    </>
  );
};
