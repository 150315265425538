import React from 'react';
import styled from 'styled-components';

export interface CollapsedProcessProps {
  width: number;
  height: number;
  style?: React.CSSProperties;
}

export const CollapsedProcess = styled.div.attrs<CollapsedProcessProps>(
  ({ style, width, height }) => ({
    style: {
      ...style,
      width,
      height,
    },
  }),
)<CollapsedProcessProps>`
  position: absolute;
  border-radius: 4px;
  background-color: #e0e0ee;
  display: flex;
  padding-left: 4px;
  overflow: hidden;
  align-items: center;
`;
