import { ApolloClient } from '@apollo/client';
import {
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryResult,
  CurrentUserQueryVariables,
} from 'src/generated/graphql';

export type UserContext = NonNullable<
  CurrentUserQueryResult['data']
>['currentUser'];

export async function currentUser(
  client: ApolloClient<unknown>,
): Promise<UserContext> {
  const { data } = await client.query<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >({
    query: CurrentUserDocument,
    fetchPolicy: 'no-cache',
  });

  return data.currentUser;
}
