import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  Title,
  Divider,
  GroupTitle,
  LoadingOverlay,
  BackButton,
  SecondaryActionButton,
  PrimaryActionButton,
} from 'src/components/Util';
import { Schedule, scheduleStatesFromRanges, useSchedule } from './Schedule';
import { timeDay, timeWeek } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { Calendar } from 'src/components/Calendar';
import {
  useResourceSchedule,
  useSetResourceSchedule,
} from 'src/server/resources';
import { TimeRange } from 'src/models';
import { Prompt } from 'src/components/Util/Prompt';
import holidays from 'src/assets/holidays/holidays_23-27.json';

export const EditResourceSchedule: React.VFC = () => {
  const { id } = useParams<{ id: ID }>();

  const today = timeDay.floor(new Date());
  const [from, setFrom] = useState(timeDay.offset(today, -1));

  const { loading, error, data, previousData } = useResourceSchedule({
    id: id!,
    from,
  });

  if (error || (!loading && !data?.resource)) {
    return <div>Failed to fetch resource :(</div>;
  }

  // Show previous data while loading - far less jarring
  const displayData = data ?? previousData;
  return (
    <>
      {loading && <LoadingOverlay />}
      {displayData && (
        <EditResourceScheduleInner
          from={from}
          setFrom={setFrom}
          resource={displayData.resource!}
        />
      )}
    </>
  );
};

const UNSAVED_MOVEMENT_WARNING =
  'You have unsaved changes. Changing your position on the timeline will cause you to lose them.';

const EditResourceScheduleInner: React.VFC<{
  from: Date;
  setFrom: (v: Date) => void;
  resource: {
    id: ID;
    name: string;
    schedule: TimeRange[];
  };
}> = ({ from, setFrom: setFrom_, resource }) => {
  const calculatedStates = scheduleStatesFromRanges(from, resource.schedule);
  const {
    editing,
    commit,
    revert,
    states,
    setRange,
    calculateRanges,
    replace,
  } = useSchedule(calculatedStates);

  useEffect(() => {
    const calculatedStates = scheduleStatesFromRanges(from, resource.schedule);
    replace(calculatedStates);
  }, [resource, replace, from]);

  const mutate = useSetResourceSchedule();

  const today = timeDay.floor(new Date());
  const offsetFromToday = timeDay.count(from, today);
  const dayHeadings = Array(7)
    .fill(undefined)
    .map((_, i) => formatDay(timeDay.offset(from, i)));
    

  const saveSchedule = useCallback(() => {
    const ranges = calculateRanges();
    mutate({
      resourceId: resource.id,
      from,
      ranges,
    }).then(() => {
      commit();
    });
  }, [resource, from, calculateRanges, mutate, commit]);

  const setFrom = (v: Date) => {
    if (!editing || window.confirm(UNSAVED_MOVEMENT_WARNING)) {
      setFrom_(v);
    }
  };

  return (
    <>
      <Prompt when={editing} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackButton style={{ marginRight: 8 }} />
          <Title>{resource.name}</Title>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {editing && <SecondaryActionButton onClick={revert} text="Revert" />}
          <div style={{ width: 21 }} />
          <PrimaryActionButton
            disabled={!editing}
            onClick={saveSchedule}
            text="SAVE AND APPLY"
          />
        </div>
      </div>
      <Divider />
      <Grid
        container
        justifyContent="space-between"
        spacing={8}
        style={{ marginBottom: 45 }}
      >
        <Grid item style={{ maxWidth: 500 }}>
          <GroupTitle>
            Click and drag to mark available hours for the week
          </GroupTitle>
        </Grid>
        <Grid item style={{ alignSelf: 'flex-end' }}>
          <Calendar
            startDay={from}
            endDay={timeDay.offset(from, 6)}
            setToday={() => setFrom(timeDay.offset(today, -1))}
            smallAdjustment={(mult) => setFrom(timeDay.offset(from, mult))}
            largeAdjustment={(mult) => setFrom(timeWeek.offset(from, mult))}
            noResourceFilter
          />
        </Grid>
      </Grid>
      <Schedule
        dayHeadings={dayHeadings}
        states={states}
        daysPast={offsetFromToday < 0 ? 0 : offsetFromToday}
        setRange={setRange}
      />
    </>
  );
};

const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const formatDay_ = timeFormat('%e %B');
const formatDay = (day: Date) => {
  const dateString = day.toLocaleString("en-NZ", {
    timeZone: "Pacific/Auckland",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedDateString = dateString.split("/").reverse().join("-"); 

  const holiday = holidays.find((holiday) => holiday[0] === formattedDateString);

  if (holiday) {
    return `** ${holiday[1]}`;
  }

  const today = new Date().toLocaleString("en-NZ", {
    timeZone: "Pacific/Auckland",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  if (dateString === today) return "TODAY";

  return `${DAYS[day.getDay()]} ${formatDay_(day)}`;
};
