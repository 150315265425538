import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, LinearProgress } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import {
  ResourceItemPermissionsUpdateField,
  useDeleteResource,
  useRenameResource,
  useResourceSettings,
  useSetResourceItemTypesAllowed,
  useSetResourceOverlapsAllowed,
} from 'src/server/resources';
import { TextBox } from 'src/components/Input/TextBox';
import { Checkbox } from 'src/components/Input/Checkbox';
import { ConfirmationModal } from 'src/components/Util/ConfirmationModal';
import { SolidButton } from 'src/components/Util/Buttons';
import {
  Title,
  Divider,
  GroupTitle,
  Subtitle,
  InputLabel,
  BackButton,
  Label,
} from 'src/components/Util';
import { ResourceTypeSelect } from './ResourceTypeSelect';
import { DefaultResourceSchedule } from './DefaultResourceSchedule';

const DEBOUNCE_MS = 300;

export const EditResource: React.VFC = () => {
  const { id } = useParams<{ id: ID }>();
  const { loading, error, data } = useResourceSettings(id!);

  const renameResource = useRenameResource();
  const setResourceName = useDebouncedCallback((v: string) => {
    if (loading || v === '') return;
    renameResource(id!, v);
  }, DEBOUNCE_MS);

  const setAllowedItemTypes = useSetResourceItemTypesAllowed();
  const updateAllowedItemTypes = useDebouncedCallback(
    (updateField: ResourceItemPermissionsUpdateField, allowed: boolean) => {
      setAllowedItemTypes({
        id: id!,
        updateField,
        allowed,
      });
    },
    DEBOUNCE_MS,
  );

  const deleteResource = useDeleteResource();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const setOverlapsAllowed = useSetResourceOverlapsAllowed();

  if (loading) return <LinearProgress />;
  if (error || !data || !data.resource || id == null) {
    return <div>Failed to fetch resource :(</div>;
  }

  const res = data.resource;

  const itemTypeCheckbox = (
    label: string,
    field: ResourceItemPermissionsUpdateField,
  ) => (
    <Grid item xs={6}>
      <Label
        style={{ padding: 13 }}
        label={label}
        control={
          <Checkbox
            style={{ marginRight: 16 }}
            checked={res[field]}
            setChecked={(allowed) => updateAllowedItemTypes(field, allowed)}
          />
        }
      />
    </Grid>
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BackButton style={{ marginRight: 8 }} />
            <Title>{res.name}</Title>
          </div>
          <Subtitle style={{ justifySelf: 'flex-end' }}>ID {res.id}</Subtitle>
        </div>
      </div>
      <Divider />
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <GroupTitle style={{ marginBottom: 50 }}>General Settings</GroupTitle>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <InputLabel>Name</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextBox
                style={{ width: '100%' }}
                defaultValue={res.name}
                setValue={setResourceName}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ marginTop: '1em' }}>
            <Grid item xs={4}>
              <InputLabel>Resource Type</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <ResourceTypeSelect
                resourceId={id}
                resourceTypeId={res.resourceType.id}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <GroupTitle>Items Allowed</GroupTitle>
          <Subtitle>
            Select the types of items that can be added to this resource
          </Subtitle>
          <Grid container style={{ marginTop: '2em' }}>
            {itemTypeCheckbox('Source', 'allowSources')}
            {itemTypeCheckbox('Process', 'allowProcesses')}
            {itemTypeCheckbox('Sink', 'allowSinks')}
            {itemTypeCheckbox('Run', 'allowRuns')}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <GroupTitle>Misc</GroupTitle>
          <Grid container style={{ marginTop: '2em' }}>
            <Grid item xs={6}>
              <Label
                style={{ padding: 13 }}
                label="Allow Overlapping Items"
                control={
                  <Checkbox
                    style={{ marginRight: 16 }}
                    checked={res.allowOverlappingItems}
                    setChecked={(allowed) =>
                      setOverlapsAllowed({
                        variables: {
                          input: {
                            id: res.id,
                            allowed,
                          },
                        },
                      })
                    }
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <DefaultResourceSchedule
        resourceId={id}
        resourceName={res.name}
        schedule={res.defaultSchedule}
      />
      <Divider />
      <DeleteResourceButton onClick={() => setDeleteModalOpen(true)} />
      {deleteModalOpen && (
        <ConfirmationModal
          showWarningIcon
          confirm={() => {
            deleteResource(id);
            navigate(-1); // Go back
          }}
          confirmButtonText="DELETE"
          cancel={() => setDeleteModalOpen(false)}
          headingText="Delete the resource"
          explanatoryText={
            <>
              The resource will no longer be accessible, but data associated
              with this resource can be retrieved later if necessary.
            </>
          }
        />
      )}
    </>
  );
};

const DeleteResourceButton: React.VFC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <SolidButton
    style={{ marginTop: 85 }}
    onClick={() => onClick()}
    color="#fff"
    backgroundColor="#ff002f"
  >
    <span style={{ fontSize: 14, letterSpacing: 2 }}>DELETE RESOURCE</span>
  </SolidButton>
);
