import { Position } from 'src/models';

export const ARC_RADIUS = 4;

export enum ArcDirection {
  NorthEast = 0,
  SouthEast = 1,
  SouthWest = 2,
  NorthWest = 3,
  Unchanged = 4,
}

// The direction, and if it is clockwise
export function getArcDirection(
  prevPoint: Position,
  currPoint: Position,
  nextPoint: Position,
): [ArcDirection, boolean] {
  const verticalFirst = prevPoint.x === currPoint.x;

  if (prevPoint.x === nextPoint.x || prevPoint.y === nextPoint.y) {
    return [ArcDirection.Unchanged, false];
  }

  if (prevPoint.x < nextPoint.x) {
    if (prevPoint.y < nextPoint.y) {
      return [ArcDirection.SouthEast, !verticalFirst];
    }
    return [ArcDirection.NorthEast, verticalFirst];
  }

  if (prevPoint.y < nextPoint.y) return [ArcDirection.SouthWest, verticalFirst];
  return [ArcDirection.NorthWest, !verticalFirst];
}

// 90 degrees
// 0 = small arc
function getArcStr(
  radius: number,
  clockwise: boolean,
  finishXOffset: number,
  finishYOffset: number,
) {
  return ` a${radius},${radius} 90 0,${
    clockwise ? 1 : 0
  } ${finishXOffset},${finishYOffset}`;
}

// Precalculate for efficiency
const arcsMap = (() => {
  const map = new Map<ArcDirection, [string, string]>();
  const set = (dir: ArcDirection, arcs: [string, string]) => {
    map.set(dir, arcs);
  };

  set(ArcDirection.Unchanged, ['', '']);

  set(ArcDirection.NorthEast, [
    getArcStr(ARC_RADIUS, true, ARC_RADIUS, -ARC_RADIUS),
    getArcStr(ARC_RADIUS, false, ARC_RADIUS, -ARC_RADIUS),
  ]);
  set(ArcDirection.SouthEast, [
    getArcStr(ARC_RADIUS, true, ARC_RADIUS, ARC_RADIUS),
    getArcStr(ARC_RADIUS, false, ARC_RADIUS, ARC_RADIUS),
  ]);
  set(ArcDirection.SouthWest, [
    getArcStr(ARC_RADIUS, true, -ARC_RADIUS, ARC_RADIUS),
    getArcStr(ARC_RADIUS, false, -ARC_RADIUS, ARC_RADIUS),
  ]);
  set(ArcDirection.NorthWest, [
    getArcStr(ARC_RADIUS, true, -ARC_RADIUS, -ARC_RADIUS),
    getArcStr(ARC_RADIUS, false, -ARC_RADIUS, -ARC_RADIUS),
  ]);

  return map;
})();

export function arc(prevPoint: Position) {
  return (point: Position) => (nextPoint: Position) => {
    const [arcDirection, clockwise] = getArcDirection(
      prevPoint,
      point,
      nextPoint,
    );
    const [clock, counterClock] = arcsMap.get(arcDirection)!;
    return clockwise ? clock : counterClock;
  };
}
