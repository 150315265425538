import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ChainPath } from './ChainPath';
import { Position } from 'src/models';
import { ErrorIcon } from 'src/components/ErrorIcon';
import { ItemState } from 'src/models/item';
import { ARC_RADIUS } from './arc';
import { minMax } from 'src/utils';

export const CHAIN_STROKE_WIDTH = 2;
const CIRCLE_RADIUS = 4;

// The distance a chain path will move before changing direction in some cases.
export const PATH_LAG = 30;

export interface ChainProps {
  sourceItemId: string;
  targetItemId: string;
  start: ChainPosition;
  end: ChainPosition;
  fromBuffer: boolean;
}

interface ChainPosition {
  x: number;
  y: number | null;
}

export const Chain: React.VFC<ChainProps> = ({
  start: start_,
  end: end_,
  fromBuffer,
  sourceItemId,
  targetItemId,
}) => {
  const fadeStart = start_.y == null;
  const fadeEnd = end_.y == null;
  const signedWidth = end_.x - start_.x;

  const start = {
    x: start_.x,
    y: start_.y ?? end_.y ?? 0,
  };
  const end = {
    x: end_.x,
    y: end_.y ?? (fromBuffer ? start.y + 30 : start.y),
  };

  if (fadeStart) {
    start.x = end.x - Math.min(Math.sign(signedWidth) * 120, signedWidth);
  } else if (fadeEnd) {
    end.x = start.x + Math.min(Math.sign(signedWidth) * 120, signedWidth);
  }

  const offset = {
    x: end.x - start.x,
    y: end.y - start.y,
  };

  const path = generateChainPath(offset, fromBuffer);

  const [minX, maxX] = minMax(path.points, (p) => p.x);
  const [minY, maxY] = minMax(path.points, (p) => p.y);

  const width = maxX - minX;
  const height = maxY - minY;

  const theme = useContext(ThemeContext);
  const color = path.errorIconPosition ? theme.errorPrimary : theme.chainColor;

  const absoluteOffset = {
    x: start.x + minX,
    y: start.y + minY,
  };

  const paddedWidth = width + 2 * CIRCLE_RADIUS;
  const paddedHeight = height + 2 * CIRCLE_RADIUS;

  return (
    <>
      <svg
        style={{
          position: 'absolute',
          left: absoluteOffset.x - CIRCLE_RADIUS,
          top: absoluteOffset.y - CIRCLE_RADIUS,
          width: paddedWidth,
          height: paddedHeight,
          zIndex: 8,
          pointerEvents: 'none',
        }}
        width={paddedWidth}
        height={paddedHeight}
        viewBox={`${minX - CIRCLE_RADIUS} ${
          minY - CIRCLE_RADIUS
        } ${paddedWidth} ${paddedHeight}`}
      >
        {!fadeStart && <ChainCircle x={0} y={0} color={color} />}
        {!fadeEnd && <ChainCircle x={offset.x} y={offset.y} color={color} />}
        <ChainPath
          points={path.points}
          color={color}
          sourceItemId={sourceItemId}
          targetItemId={targetItemId}
          fadeStart={offset.x < 0 ? fadeEnd : fadeStart}
          fadeEnd={offset.x < 0 ? fadeStart : fadeEnd}
        />
      </svg>
      {path.errorIconPosition && (
        <ErrorIcon
          itemState={ItemState.Active}
          style={{
            position: 'absolute',
            zIndex: 9,
            transform: 'translate(-8.5px, -8.5px)',
            left: start.x + path.errorIconPosition.x - CIRCLE_RADIUS,
            top: start.y + path.errorIconPosition.y - CIRCLE_RADIUS,
            pointerEvents: 'none',
          }}
        />
      )}
    </>
  );
};

interface ChainCircleProps {
  x: number;
  y: number;
  color: string;
}

const ChainCircle: React.VFC<ChainCircleProps> = ({ x, y, color }) => (
  <circle cx={x} cy={y} r={CIRCLE_RADIUS} fill={color} />
);

interface ChainPathResult {
  points: Position[];
  errorIconPosition: Position | null;
}

/** A path direct from the start to the end. */
export const directChainPath = (offset: Position): ChainPathResult => ({
  points: [{ x: 0, y: 0 }, offset],
  errorIconPosition:
    offset.x < 0 ? { x: offset.x * 0.5, y: offset.y * 0.5 } : null,
});

/**
 * Start from a buffer, and moving upwards. Do something like the below
 * ```txt
 *       ___
 *      |
 *      |
 *      |
 *   |__|
 * ```
 */
export function makeBufferChainHook(offset: Position): Position[] {
  if (offset.x < 0 || offset.y > 0) {
    throw new Error('Expected offset to be north-east of start');
  }

  return [
    { x: 0, y: 0 },
    { x: 0, y: PATH_LAG },
    { x: Math.min(PATH_LAG, offset.x), y: PATH_LAG },
    { x: Math.min(PATH_LAG, offset.x), y: offset.y },
    offset, // finish
  ];
}

/**
 * Makes a shape like the below, from start to finish:
 * ```txt
 *    x
 *    |_____!_____
 *               |
 *               x
 * ```
 */
export function makeHorizontalSnake(
  start: Position,
  finish: Position,
): ChainPathResult {
  const midX = (start.x + finish.x) * 0.5;
  const midY = (start.y + finish.y) * 0.5;
  return {
    points: [start, { x: start.x, y: midY }, { x: finish.x, y: midY }, finish],
    errorIconPosition: { x: midX, y: midY },
  };
}

export function generateChainPath(
  offset: Position,
  fromBuffer: boolean,
): ChainPathResult {
  if (
    Math.abs(offset.x) < 2 * ARC_RADIUS ||
    Math.abs(offset.y) < 2 * ARC_RADIUS
  ) {
    // For such small offsets, trying to create the different line segments and
    // arcs looks really bad. So instead we create a line direcly from the start
    // to the end.
    return directChainPath(offset);
  }

  if (offset.x < 0) {
    const snake = makeHorizontalSnake(
      { x: fromBuffer ? 0 : PATH_LAG, y: 0 },
      { x: offset.x - PATH_LAG, y: offset.y },
    );

    return {
      points: [
        ...(fromBuffer ? [] : [{ x: 0, y: 0 }]),
        ...snake.points,
        offset,
      ],
      errorIconPosition: snake.errorIconPosition,
    };
  }

  if (offset.y > 0 && fromBuffer) {
    // |
    // |___
    return {
      points: [{ x: 0, y: 0 }, { x: 0, y: offset.y }, offset],
      errorIconPosition: null,
    };
  }

  if (!fromBuffer) {
    // --+
    //   |
    //   +----
    return {
      points: [
        { x: 0, y: 0 },
        { x: PATH_LAG, y: 0 },
        { x: PATH_LAG, y: offset.y },
        offset,
      ],
      errorIconPosition: null,
    };
  }

  // Moving north-east. From a buffer. Create a hook.
  return {
    points: makeBufferChainHook(offset),
    errorIconPosition: null,
  };
}
