import React, { useCallback, useEffect, useRef } from 'react';
import { timeFormat } from 'd3-time-format';
import { roundToNearestMinutes } from 'date-fns';
import { useCurrentPeriodScale } from 'src/utils/scale';
import { MovementTimestamp } from './MovementTimestamp';
import { FORMAT_TIME } from '../../constants';

const timeFormatter = timeFormat(FORMAT_TIME);

export interface DragLayerProps {
  offsetX: number;
  offsetY: number;
  children: React.ReactNode;
}

// For performance reasons we don't follow standard react practice in this
// component, and mutate the DOM element directly.
export const DragLayer: React.VFC<DragLayerProps> = ({
  offsetX,
  offsetY,
  children,
}) => {
  const scale = useCurrentPeriodScale();

  const readyRef = useRef({ ready: true, timeout: null as any });
  const ref = useRef<HTMLDivElement>(null);
  const timestampRef = useRef<HTMLDivElement>(null);
  const handleMouseMove = useCallback(
    (ev: MouseEvent) => {
      if (ref.current == null || !readyRef.current.ready) return;

      readyRef.current.ready = false;

      const timeout = readyRef.current.timeout;
      if (timeout) {
        cancelAnimationFrame(timeout);
      }

      readyRef.current.timeout = requestAnimationFrame(() => {
        const x = ev.clientX + offsetX;
        const y = ev.clientY + offsetY;

        const timestamp = timeFormatter(
          roundToNearestMinutes(scale.invert(x), {
            nearestTo: 15,
          }),
        );

        const container = ref.current;
        if (container) {
          container.style.left = `${x}px`;
          container.style.top = `${y}px`;
        }

        if (timestampRef.current) {
          timestampRef.current.innerText = timestamp;
        }

        setTimeout(() => {
          readyRef.current.ready = true;
        }, 50);
      });
    },
    [scale, offsetX, offsetY],
  );

  useEffect(() => {
    window.addEventListener('dragover', handleMouseMove, {
      capture: true,
      passive: true,
    });
    return () => {
      window.removeEventListener('dragover', handleMouseMove);
    };
  }, [handleMouseMove]);

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        opacity: 1,
        zIndex: 100,
      }}
    >
      <MovementTimestamp ref={timestampRef} />
      {children}
    </div>
  );
};
