import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  ButtonBaseProps,
  IconButton,
  LinearProgress,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DragIcon } from 'src/assets/icons/six-dot-grab.svg';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { Divider, Title } from 'src/components/Util';
import { SolidButton, SolidButtonBaseProps } from 'src/components/Util/Buttons';
import { openItemSideDrawer } from 'src/store/drawer';
import { TextBox } from 'src/components/Input/TextBox';
import { ProcessRowStyle } from './ProcessRowStyle';
import { ProcessRow } from './ProcessRow';
import { AccountSelectors } from 'src/store/login';
import { moveItem } from 'src/server/item-movement';
import { useCreateRunProcess } from 'src/server/items';
import { useApolloClient } from '@apollo/client';
import { useRunEditor } from 'src/server/run-editor';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

export interface RunEditorProps {
  onClose: () => void;
}

export const RunEditor: React.FC<RunEditorProps> = ({ onClose }) => {
  const selectedItemId = useAppSelector(({ drawer }) => drawer.editingItemId);
  const [runId] = useState(selectedItemId);
  const dispatch = useAppDispatch();

  return (
    <Background>
      <Paper
        onClick={() => {
          if (runId == null) return;
          dispatch(openItemSideDrawer({ id: runId, autoFocus: false }));
        }}
      >
        <div className="heading">
          <Title>Edit Run</Title>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ margin: 0 }} />
        <div style={{ flex: 1, overflowY: 'auto' }}>
          <div style={{ margin: '2em 2em 6em 2em' }}>
            <RunEditorContents />
          </div>
        </div>
      </Paper>
    </Background>
  );
};

const RunEditorContents: React.FC = () => {
  const _canEdit = useAppSelector(AccountSelectors.canEdit);
  const selectedItemId = useAppSelector(({ drawer }) => drawer.editingItemId);
  const [runId, setRunId] = useState(selectedItemId);
  const { data, error } = useRunEditor(runId);
  const dispatch = useAppDispatch();
  const apollo = useApolloClient();

  useEffect(() => {
    if (data?.item?.__typename === 'Process') {
      const runId = data.item.runUsage?.runId;
      if (runId == null) return;
      setRunId(runId);
    }
  }, [data]);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (data?.item?.__typename !== 'Run') return;
      if (!result.destination) return;
      moveItem(apollo, dispatch, {
        type: 'run',
        processId: result.draggableId as ID,
        runId,
        index: result.destination.index,
        duration: null,
        endTime: null,
      }).catch((error) => {
        Sentry.captureException(error);
      });
    },
    [apollo, dispatch, data, runId],
  );

  const [createProcess, { loading: creatingNewProcess }] = useCreateRunProcess({
    runId: runId!,
  });

  const create = async (focus: boolean) => {
    const { data } = await createProcess();
    const newProcessId = data?.result.item.id;
    if (focus && newProcessId != null) {
      dispatch(openItemSideDrawer({ id: newProcessId, autoFocus: false }));
    }
  };

  if ((!data && !error) || data?.item?.__typename === 'Process') {
    return <LinearProgress />;
  }
  if (error || data?.item?.__typename !== 'Run') {
    return <div>Failed to fetch data :(</div>;
  }

  const canEdit = _canEdit && !!data.item.timelineUsage;

  const { fields } = data.item.resourceType.processData;

  const sorted = [...data.item.runUsages].sort((a, b) => a.offset - b.offset);
  type Gap = { id: string; offset: number; duration: number };
  type Sorted = typeof sorted[0] & { duration: number };
  type Data = (
    | { type: 'gap'; data: Gap }
    | { type: 'process'; data: Sorted }
  ) & { tabIndex: number };

  let tabIndex = 1;
  const list = sorted.map((s) => {
    const elem: Data = {
      type: 'process',
      tabIndex: tabIndex,
      data: { ...s, duration: s.process.duration },
    };
    tabIndex += 2 + Math.min(2, fields.length);
    return elem;
  });

  const elements = list.map((elem, index, arr) => {
    const { tabIndex } = elem;
    const { id, duration } = elem.data;
    const component = () => {
      if (!data.item) return null;

      const height = 90; //`max(${(85 * duration) / total}%, ${MIN_HEIGHT}px)`;
      if (elem.type === 'process') {
        const process = elem.data.process;
        return (
          <ProcessRow
            key={id}
            index={index}
            fields={fields}
            isSelected={selectedItemId === process.id}
            open={() =>
              dispatch(openItemSideDrawer({ id: process.id, autoFocus: false }))
            }
            close={() =>
              dispatch(openItemSideDrawer({ id: runId!, autoFocus: false }))
            }
            process={process}
            startTabIndex={tabIndex}
            canEdit={canEdit && !data.item.locked}
            tabOutLeft={() => {
              const prev = arr
                .slice(0, index)
                .reverse()
                .find((x) => x.type === 'process');
              if (prev?.type === 'process') {
                dispatch(
                  openItemSideDrawer({
                    id: prev.data.process.id,
                    autoFocus: false,
                  }),
                );
              }
            }}
            tabOutRight={() => {
              const next = arr
                .slice(index + 1)
                .find((x) => x.type === 'process');
              if (next?.type === 'process') {
                dispatch(
                  openItemSideDrawer({
                    id: next.data.process.id,
                    autoFocus: false,
                  }),
                );
              }

              if (!next) {
                create(true);
              }
            }}
          />
        );
      }

      if (duration === 0) return null;
      return (
        <ProcessRowStyle
          key={id}
          style={{ height }}
          selected={false}
          selectedBorder=""
          durationBackground=""
          selectedBackground=""
          standardBackground="#eeeeee"
        >
          <div className="drag">
            <DragIcon />
          </div>
          <div className="name">Gap</div>
          <div className="duration">
            <TextBox
              inputProps={{ tabIndex }}
              style={{ width: '5em' }}
              value={duration / 3600}
              setValue={() => null}
              placeholder="Duration"
            />
          </div>
        </ProcessRowStyle>
      );
    };

    const c = component();
    return c ? <div key={id}>{c}</div> : null;
  });

  const reorder = (children: React.ReactNode) => (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="container">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={
              snapshot.isDraggingOver
                ? { paddingBottom: 85, marginBottom: 2 }
                : undefined
            }
          >
            {children}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  const contents =
    canEdit && !data.item.locked ? (
      reorder(elements.filter((x) => x))
    ) : (
      <div>{elements}</div>
    );

  return (
    <>
      <div style={{ zoom: 0.9 }}>{contents}</div>
      {canEdit && !data.item.locked && (
        <AddProcessButton
          disabled={creatingNewProcess}
          onClick={() => create(true)}
        />
      )}
    </>
  );
};

type P = Partial<ButtonBaseProps & SolidButtonBaseProps>;
const AddProcessButton: React.FC<P> = (props) => (
  <SolidButton
    {...props}
    color="#fff"
    backgroundColor="#5878ff"
    style={{ bottom: '2em', right: '2em', position: 'absolute' }}
  >
    <span style={{ fontSize: 14, letterSpacing: 2 }}>ADD PROCESS</span>
  </SolidButton>
);

const Background = styled.div`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000060;

  display: flex;
  justify-content: center;
  align-items: center;

  .MuiPaper-root {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    margin-right: 448px;
    border-radius: 2px;
    width: 100%;
    height: 85%;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
  }
`;
