import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { timeDay } from 'd3-time';
import { useNow } from 'src/utils/hooks';
import { TRANSPARENT_PIXEL } from 'src/constants';

type IndexableObj = Record<string, any>;

interface StyledProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

interface GenerateBgFn<Props> {
  (props: Props): string;
}

/**
 * This utility function is passed two arguments
 * - an array of keys used to generate the background image
 * - and a function that returns a dataUri of a background image
 * It returns a react component that uses the BackgroundBase styles and
 * adds a background image (that gets recalculated when props change).
 *
 * It is written this way to allow passing the as prop from styled components
 * as well as any props that that components needs.
 */
export function generateBackgroundComponent<P>(
  genProps: string[],
  generate: GenerateBgFn<P>,
  component?: any,
  // This must be constant
  rerenderInterval?: number,
): React.FC<P & StyledProps & IndexableObj> {
  const Background = ({ style, backgroundRef, ...props }: any) => {
    const [bgUrl, setBgUrl] = useState(TRANSPARENT_PIXEL);
    const [bgProps, otherProps] = splitProps<P>(props, genProps);

    // Should be a constant value
    const rerender =
      rerenderInterval != null
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          timeDay.floor(useNow(rerenderInterval)).getTime()
        : 0;

    useEffect(() => {
      const bg = generate(bgProps);
      if (bg !== bgUrl) {
        setBgUrl(bg);
      }
      // We are skipping the exhaustive-deps eslint check
      // as we executing this only with the bgProp values
    }, [...Object.values(bgProps), rerender]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <BackgroundBase
        as={component}
        ref={backgroundRef}
        style={{ ...style, backgroundImage: `url(${bgUrl})` }}
        {...otherProps}
      />
    );
  };

  return Background;
}

const BackgroundBase = styled.div`
  background-repeat: repeat-y;
`;

function splitProps<P>(props: IndexableObj, keys: string[]): [P, IndexableObj] {
  // This function splits an object, based on whether the key is includes within the passed array
  const inKeys = {} as P;
  const otherProps = {} as typeof props;

  Object.keys(props).forEach((key) => {
    const addTo = keys.includes(key) ? inKeys : otherProps;
    addTo[key] = props[key];
  });
  return [inKeys, otherProps];
}
