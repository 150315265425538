import React, { useMemo, useContext } from 'react';
import { timeDay, CountableTimeInterval, TimeInterval } from 'd3-time';
import { scaleDiscontinuous } from '@d3fc/d3fc-discontinuous-scale';
import { scaleTime } from 'd3-scale';
import { useWindowWidth, useWindowHeight } from '@react-hook/window-size';
import {
  mergeDiscontinuityProviders,
  mergeGetHiddenRanges,
} from './mergeDiscontinuityProviders';
import {
  SkipSunday,
  getHiddenRanges as getHiddenSundayRanges,
} from './skipSundayDiscontinuityProvider';
import { WorkingHours } from './workingHoursDiscontinuityProvider';
import { TimeRange } from 'src/models';
import { useAppSelector } from 'src/store/hooks';
import { Period } from 'src/store/planner';
import { ScaleCache, ScaleCacheConfig } from './cache';

export type Scale = {
  (date: Date): number;
  invert: (x: number) => Date;
  domain: () => [Date, Date];
  ticks: (int: TimeInterval) => Date[];
  range: () => [number, number];
};

interface ScaleContextData {
  scale: Scale;
  getHiddenRanges: (start: Date, end: Date) => TimeRange[];
}

export const ScaleContext = React.createContext<ScaleContextData | null>(null);

export const ScaleProvider: React.FC = ({ children }) => {
  const cache = useMemo(() => new ScaleCache(), []);

  const periodStart = usePeriodStart();
  const workingHours = useWorkingHours();
  const periodEnd = usePeriodEnd();
  const period = usePeriod();

  const width = Math.max(useWindowWidth(), 1);

  const [scale, getHiddenRanges] = useBuildScale({
    periodStart,
    periodEnd,
    width,
    workingHours,
    cache,
    period,
  });

  return (
    <ScaleContext.Provider value={{ scale, getHiddenRanges }}>
      {children}
    </ScaleContext.Provider>
  );
};

export function useBuildScale({
  periodStart,
  periodEnd,
  width,
  workingHours,
  cache,
  period,
}: {
  periodStart: Date;
  periodEnd: Date;
  width: number;
  workingHours: boolean;
  cache: ScaleCache;
  period: Period;
}): [any, any] {
  return useMemo(() => {
    const scaler = scaleTime();
    const scale_ = scaleDiscontinuous(scaler)
      .domain([periodStart, periodEnd])
      .range([0, width])
      .clamp(false);

    if (workingHours) {
      scale_.discontinuityProvider(
        mergeDiscontinuityProviders(SkipSunday, WorkingHours),
      );
    }

    const config: ScaleCacheConfig = {
      periodStart: periodStart.getTime(),
      periodEnd: periodEnd.getTime(),
      width,
      workingHours,
    };

    const scale: Scale = ((date: Date) =>
      cache.get(date, scale_, config)) as any;
    scale.invert = scale_.invert;
    scale.domain = scale_.domain;
    scale.ticks = scale_.ticks;
    scale.range = scale_.range;

    return [
      scale,
      workingHours
        ? mergeGetHiddenRanges(
            WorkingHours.getHiddenRanges,
            getHiddenSundayRanges,
          )
        : () => [],
    ] as [Scale, (start: Date, end: Date) => TimeRange[]];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, period, periodStart.getTime(), periodEnd.getTime(), workingHours]);
}

export function resourceQueryPeriod(scale: Scale) {
  const [startTime, endTime] = scale.domain() as [Date, Date];
  const extra = 1.2 * (endTime.getTime() - startTime.getTime());
  return {
    startTime: new Date(startTime.getTime() - extra),
    endTime: new Date(endTime.getTime() + extra),
  };
}

export function useCurrentPeriodScale() {
  const context = useContext(ScaleContext);
  if (!context) throw new Error('No context provider found');
  return context.scale;
}

export function useGetHiddenRanges() {
  const context = useContext(ScaleContext);
  if (!context) throw new Error('No context provider found');
  return context.getHiddenRanges;
}

export function useVisibleDays() {
  const scale = useCurrentPeriodScale();
  return useMemo(() => {
    const [start, end] = scale.domain();
    const allDays = timeDay.range(start, end);
    const visibleDays = allDays.filter(
      (day: Date, idx: number, arr: Array<Date>) => {
        const width = scale(arr[idx + 1] || end) - scale(day);
        return width > 0;
      },
    );
    return visibleDays;
  }, [scale]);
}

export const usePeriod = () => useAppSelector((state) => state.planner.period);
export const usePeriodStart = () =>
  useAppSelector((state) => state.planner.periodStart);
export const useWorkingHours = () =>
  useAppSelector((state) => state.planner.workingHours);

export function usePeriodEnd() {
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const period = usePeriod();
  const periodStart = usePeriodStart();
  return useMemo(() => {
    return dateOffset({
      windowWidth,
      windowHeight,
      periodStart,
      period,
      dir: 1,
    });
  }, [period, periodStart, windowWidth, windowHeight]);
}

function dateOffset(data: {
  windowWidth: number;
  windowHeight: number;
  periodStart: Date;
  period: Period;
  dir: -1 | 1;
}) {
  const screenRatio = data.windowWidth / data.windowHeight;
  const multiplier =
    screenRatio > 1e-4 && screenRatio < 1e3 && screenRatio > 20 / 9
      ? (screenRatio * 9) / 16
      : 1;

  const [timeBasis, defaultMultiplier] = periodFn(
    data.periodStart,
    data.period,
  );
  const nextDate = timeDay.floor(
    timeBasis.offset(
      data.periodStart,
      defaultMultiplier * multiplier * data.dir,
    ),
  );
  // Subtract 1ms from start of next time period to compute end of
  // current time period if moving forward
  nextDate.setTime(nextDate.getTime() - (data.dir === 1 ? 1 : 0));
  return nextDate;
}

function periodFn(
  periodStart: Date,
  period: Period,
): [CountableTimeInterval, number] {
  switch (period) {
    case Period.Month:
      return [
        timeDay,
        // Number of days in month
        new Date(
          periodStart.getFullYear(),
          periodStart.getMonth() + 1,
          0,
        ).getDate(),
      ];
    case Period.Day:
      return [timeDay, 1];
    case Period.TwoDay:
      return [timeDay, 2];
    case Period.ThreeDay:
      return [timeDay, 3];
    case Period.TwoWeek:
      return [timeDay, 14];
    case Period.ThreeWeek:
      return [timeDay, 21];
    case Period.Week:
    default:
      return [timeDay, 7];
  }
}
