import React from 'react';
import styled from 'styled-components';
import { useCurrentPeriodScale } from 'src/utils/scale';
import { useNow } from 'src/utils/hooks';
import { TIMELINE_NOW_UPDATE_MS } from 'src/constants';

export const TimelineMarker: React.VFC<{ height: number }> = ({ height }) => {
  const scale = useCurrentPeriodScale();
  const now = useNow(TIMELINE_NOW_UPDATE_MS);

  return (
    <TimelineMarkerStyle style={{ left: scale(now) }}>
      <div className="line" style={{ height }} />
      <div className="circle" />
    </TimelineMarkerStyle>
  );
};

const TimelineMarkerStyle = styled.div`
  z-index: 30;
  background-color: ${(props) => props.theme.secondaryAccent};
  position: absolute;
  pointer-events: none;
  top: 0;

  .line {
    width: 2px;
    background-color: inherit;
  }

  .circle {
    z-index: 500;
    position: absolute;
    top: -3px;
    left: -5px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: inherit;
  }
`;
