import { ChangesQuery, useChangesQuery } from 'src/generated/graphql';
import {
  CHANGES_FETCH_COUNT,
  CHANGES_FETCH_INTERVAL,
  ITEM_CHANGES_FETCH_COUNT,
  ITEM_CHANGES_FETCH_INTERVAL,
} from 'src/constants';

export type Change = ChangesQuery['changes'][0];

export function useRecentChanges() {
  return useChangesQuery({
    variables: { count: CHANGES_FETCH_COUNT },
    fetchPolicy: 'cache-and-network',
    pollInterval: CHANGES_FETCH_INTERVAL,
  });
}

export function useItemChanges(itemId: ID | null) {
  return useChangesQuery({
    variables: { count: ITEM_CHANGES_FETCH_COUNT, itemId: itemId! },
    fetchPolicy: 'cache-and-network',
    pollInterval: ITEM_CHANGES_FETCH_INTERVAL,
    skip: itemId == null,
  });
}
