import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TopBar } from './TopBar';
import { LoadingCentered } from './LoadingCentered';
import { LoadingFailed } from './LoadingFailed';
import { useIsMobilePortrait } from 'src/utils/media';
import { Routes as RouteNames } from 'src/routes';

import { Planner } from './Planner';
import { Typography } from '@mui/material';
import { EditResourceSchedule } from './Settings/Resources/EditResourceSchedule';
import { EditResource } from './Settings/Resources/EditResource';
import { Resources } from './Settings/Resources';
import { env } from 'src/runtime-environment';

const Settings = React.lazy(() => import('./Settings'));
const Changes = React.lazy(() => import('./Changes'));
const Dashboard = React.lazy(() => import('./Dashboard'));

export const App: React.VFC = () => {
  const portraitMobile = useIsMobilePortrait();
  if (portraitMobile) {
    return (
      <Suspense fallback={<LoadingCentered />}>
        <Dashboard />
      </Suspense>
    );
  }

  return (
    <AppStyle className='parent' style={env.PROFILE === 'staging' ? { border: '5px solid red' } : {}}>
      <TopBar />
      <Routes>
        <Route path={RouteNames.PLANNER} element={<Planner />} />

        <Route
          path={RouteNames.DASHBOARD}
          element={
            <Suspense fallback={<LoadingCentered />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path={RouteNames.SETTINGS}
          element={
            <Suspense fallback={<LoadingCentered />}>
              <Settings />
            </Suspense>
          }
        >
          <Route path="" element={<Navigate to="/settings/resources" />} />
          <Route
            path="resources/:id/schedule"
            element={<EditResourceSchedule />}
          />
          <Route path="resources/:id" element={<EditResource />} />
          <Route path="resources" element={<Resources />} />
        </Route>

        <Route
          path={RouteNames.CHANGES}
          element={
            <Suspense fallback={<LoadingCentered />}>
              <Changes />
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <LoadingFailed style={{ height: 'calc(100vh - 60px)' }}>
              <Typography variant="h6">
                404: Sorry my man. I don't know where that is eh.
              </Typography>
            </LoadingFailed>
          }
        />
      </Routes>
    </AppStyle >
  );
};

const AppStyle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }

  :not(:first-child) {
    flex: 1;
  }
`;
