import React from 'react';
import styled from 'styled-components';

export const TimeIntervalColumn: React.VFC = () => (
  <div style={{ margin: '54px 6px 0 0' }}>
    <TimeIntervalSegment>12 AM</TimeIntervalSegment>
    {Array(12)
      .fill(undefined)
      .map((_, i) => (
        <TimeIntervalSegment key={i}>{i + 1} AM</TimeIntervalSegment>
      ))}
    {Array(11)
      .fill(undefined)
      .map((_, i) => (
        <TimeIntervalSegment key={i + 12}>{i + 1} PM</TimeIntervalSegment>
      ))}
  </div>
);

const TimeIntervalSegment = styled.div`
  border-top: 2px solid ${(props) => props.theme.lightTint};
  color: ${(props) => props.theme.settingsSecondary};
  padding: 0 49px 22px 2px;
`;
