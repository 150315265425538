import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import styled from 'styled-components';
import { ReactComponent as CreateChainIcon } from 'src/assets/icons/chain-link.svg';

export interface CreateChainButtonProps {
  onClick: () => void;
}

export const CreateChainButton: React.VFC<CreateChainButtonProps> = ({
  onClick,
}) => (
  <Button onClick={onClick}>
    <CreateChainIcon fill="#fff" />
  </Button>
);

const Button = styled(ButtonBase)`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.mainAccent};

  & svg {
    width: 14.1px;
    height: 14.1px;
    object-fit: contain;
  }
`;
