import React, { useCallback } from 'react';
import styled from 'styled-components';
import { timeFormat } from 'd3-time-format';
import { ResourceFilterDropdown } from './ResourceFilterDropdown';
import { TimeStepButtons } from './TimeStepButtons';
import { OutlinedButton } from 'src/components/Util/Buttons';
import { useHotkeys } from 'react-hotkeys-hook';
import { Tooltip } from '@mui/material';
import { useIsMobile } from 'src/utils/media';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { smallAdjustment, largeAdjustment, setToday } from 'src/store/planner';

export interface TimelineCalendarProps {
  startDay: Date;
  endDay: Date;
}

export const TimelineCalendar: React.VFC<TimelineCalendarProps> = ({
  startDay,
  endDay,
}) => {
  const dispatch = useAppDispatch();

  const handleSmallAdjustment = useCallback(
    (multiplier?: number) => {
      dispatch(smallAdjustment({ multiplier: multiplier ?? 1 }));
    },
    [dispatch],
  );

  const handleLargeAdjustment = useCallback(
    (multiplier?: number) => {
      dispatch(largeAdjustment({ multiplier: multiplier ?? 1 }));
    },
    [dispatch],
  );

  const handleSetToday = useCallback(() => {
    dispatch(setToday());
  }, [dispatch]);

  return (
    <Calendar
      startDay={startDay}
      endDay={endDay}
      smallAdjustment={handleSmallAdjustment}
      largeAdjustment={handleLargeAdjustment}
      setToday={handleSetToday}
    />
  );
};

export type CalendarProps = {
  startDay: Date;
  endDay: Date;
  smallAdjustment?: (multiplier?: number) => void;
  largeAdjustment?: (multiplier?: number) => void;
  setToday?: () => void;
  noResourceFilter?: boolean;
};

export const Calendar: React.VFC<CalendarProps> = ({
  startDay,
  endDay,
  setToday = () => {},
  smallAdjustment = () => {},
  largeAdjustment = () => {},
  noResourceFilter,
}) => {
  const spansMonths = startDay.getMonth() !== endDay.getMonth();
  const selectedItemId = useAppSelector((state) => state.drawer.editingItemId);

  useHotkeys('shift+t', () => {
    const ae = document.activeElement as any;
    ae!.tagName !== 'INPUT' && ae!.tagName !== 'TEXTAREA' && setToday();
  });

  useHotkeys(
    'left',
    () => {
      if (!selectedItemId) {
        smallAdjustment(-1);
      }
    },
    {},
    [startDay, endDay, selectedItemId],
  );

  useHotkeys(
    'right',
    () => {
      if (!selectedItemId) {
        smallAdjustment();
      }
    },
    {},
    [startDay, endDay, selectedItemId],
  );

  useHotkeys(
    'shift+left',
    () => {
      if (!selectedItemId) {
        largeAdjustment(-1);
      }
    },
    {},
    [startDay, endDay, selectedItemId],
  );

  useHotkeys(
    'shift+right',
    () => {
      if (!selectedItemId) {
        largeAdjustment();
      }
    },
    {},
    [startDay, endDay, selectedItemId],
  );

  const mobile = useIsMobile();

  const today = (
    <Tooltip title="Go to Today">
      <div style={mobile ? { margin: '0 1em' } : { marginLeft: '1em' }}>
        <OutlinedButton onClick={() => setToday()}>Today</OutlinedButton>
      </div>
    </Tooltip>
  );

  const dateRangeText = (
    <DateRangeText>
      {extractDay(startDay)}
      {spansMonths && (
        <>
          {' '}
          <Month>{extractMonthShort(startDay)}</Month>
        </>
      )}
      {' - ' /* eslint-disable-line react/jsx-curly-brace-presence */}
      {extractDay(endDay)}{' '}
      <Month>
        {(spansMonths ? extractMonthShort : extractMonthFull)(endDay)}
      </Month>
    </DateRangeText>
  );

  return (
    <div style={{ display: 'flex' }}>
      {!noResourceFilter && <ResourceFilterDropdown />}

      <TimeStepButtons
        onSmallStep={() => smallAdjustment(-1)}
        onLargeStep={() => largeAdjustment(-1)}
      />
      {mobile ? today : dateRangeText}
      <TimeStepButtons
        right
        onSmallStep={() => smallAdjustment()}
        onLargeStep={() => largeAdjustment()}
      />
      {!mobile && today}
    </div>
  );
};

const extractMonthFull = timeFormat('%B');
const extractMonthShort = timeFormat('%b');
const extractDay = timeFormat('%e');

const Month = styled.span``;

const DateRangeText = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: auto 0.75em;
  width: 8em;
  text-align: center;

  ${Month} {
    font-weight: 300;
  }
`;
