import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { CollapsedProcess } from './CollapsedProcess';
import { ItemInteractionProps } from 'src/models/item';
import { ColorwayName, colorways, Theme } from 'src/config/theme';
import { ItemSubtitleStyle } from '../ItemStyles';
import { getFontSize } from '../Process';

export interface RunContentProps {
  onClick?: (event: React.MouseEvent, id: ID) => void;
  onMouseMove?: ItemInteractionProps['onMouseMove'];
  height: number;
  selectedItems: Set<string>;
  processes: RunProcessDisplayData[];
}

export interface RunProcessDisplayData {
  id: ID;
  title: string;
  error: boolean;
  changed: boolean;
  colorway: ColorwayName;
  left: number;
  right: number;
  buffer: number;
  taskIconId: string | null;
}

export const RunContent: React.VFC<RunContentProps> = ({
  onClick,
  onMouseMove,
  height,
  selectedItems,
  processes,
}) => {
  const theme = useContext(ThemeContext) as Theme;
  const childSelected = processes.some((x) => selectedItems.has(x.id));

  return (
    <RunContentRoot>
      {processes.map((p) => {
        const colorway = colorways[p.colorway];
        const color = selectedItems.has(p.id)
          ? colorway.selectedBackground
          : `${colorway.standardBackground}${childSelected ? '77' : ''}`;
        return (
          <CollapsedProcess
            key={p.id}
            title={p.title}
            className={`process inactive${onMouseMove ? ' interactive' : ''}`}
            onClick={(e) => onClick?.(e, p.id)}
            onMouseMove={(e) => onMouseMove?.(p as any, e)}
            style={{
              left: p.left,
              top: 0,
              border: p.changed
                ? `3px solid ${theme.changedHighlight}`
                : undefined,
              background: p.error
                ? errorBackground(color, theme.errorSecondary)
                : undefined,
              backgroundColor: p.error ? undefined : color,
            }}
            width={p.right - p.left}
            height={height}
          >
            <ItemSubtitleStyle
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: getFontSize(p.right - p.left, p.title ?? '', 8, 14),
              }}
            >
              {p.title ?? ''}
            </ItemSubtitleStyle>
            <MiniOutputBuffer
              color={
                selectedItems.has(p.id)
                  ? colorway.selectedBorder
                  : colorway.durationBackground
              }
              style={{ width: p.buffer }}
            />
            {p.taskIconId && (
              <img
                src={`/assets/task-icons/${p.taskIconId}.svg`}
                style={{
                  maxWidth: p.right - p.left,
                  maxHeight: (height - 12) * 0.9,
                  ...(selectedItems.has(p.id)
                    ? { filter: 'invert(100%)' }
                    : undefined),
                }}
                alt="icon"
              />
            )}
          </CollapsedProcess>
        );
      })}
    </RunContentRoot>
  );
};

function errorBackground(weak: string, strong: string) {
  return `repeating-linear-gradient(45deg, ${weak}, ${weak} 5px, ${strong} 10px, ${strong} 14px)`;
}

const RunContentRoot = styled.div`
  position: relative;
  margin-bottom: 4px;

  .process.interactive:hover {
    filter: brightness(70%);
  }

  .process {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`;

const MiniOutputBuffer = styled.div<{ color: string }>`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.color};
  height: 4px;
`;
