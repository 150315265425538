import React from 'react';
import { Source } from './Source';
import { Sink } from './Sink';
import { ItemPositioner } from './ItemPositioner';
import { PointItemLayoutData, ItemInteractionProps } from 'src/models/item';
import { DraggableContainer } from 'src/components/DragDrop/DraggableContainer';
import { useHasChanged } from 'src/store/planner';
import { useItemDragDropContext } from 'src/components/Resources/TimelineContext';

export const TimelinePointItem: React.VFC<
  PointItemLayoutData & ItemInteractionProps
> = ({ left, top, height, item, state, onClick, onMouseMove, canEdit }) => {
  const { onDrag, onDrop } = useItemDragDropContext();
  const changed = useHasChanged(item.lastPositioned ?? null);

  let ItemEl;
  if (item.__typename === 'Source') ItemEl = Source;
  else if (item.__typename === 'Sink') ItemEl = Sink;
  else {
    throw new Error(`Invalid point item type: '${(item as any).__typename}'`);
  }

  const data = {
    title: item.title ?? '',
    subtitle: item.subtitle ?? '',
    error: item.conflictCount !== 0,
    changed,
    locked: item.locked,
    colorway: (item as any).colorway,
    state,
    onClipboard: false,
  };

  const itemElement = (
    <ItemEl
      data={data}
      onClick={onClick}
      onMouseMove={(evt: React.MouseEvent) => {
        evt.stopPropagation();
        if (onMouseMove) {
          onMouseMove(item as any, evt);
        }
      }}
    />
  );

  return (
    <ItemPositioner
      id={`item-${item.id}`}
      left={left}
      top={top}
      height={height}
    >
      {item.locked || !canEdit ? (
        itemElement
      ) : (
        <DraggableContainer
          id={item.id}
          targetKey={item.__typename}
          dragData={{
            itemId: item.id,
            inventoryWorkstationIds: [],
          }}
          onDrag={onDrag}
          onDrop={onDrop}
          dragDisabled={item.locked}
        >
          {itemElement}
        </DraggableContainer>
      )}
    </ItemPositioner>
  );
};
