import React from 'react';
import { Timeline } from 'src/components/Timeline';
import { ItemSideDrawer } from 'src/components/ItemSideDrawer';
import { ItemHolder } from 'src/components/ItemHolder';
import { ScaleProvider } from 'src/utils/scale';
import { kioskMode } from 'src/services/auth.service';
import { useAppSelector } from 'src/store/hooks';

export const Planner: React.VFC = () => {
  const clipboardOpened = useAppSelector(
    ({ planner }) => planner.openClipboard,
  );

  return (
    <ScaleProvider>
      <Timeline />
      <ItemSideDrawer />
      {!kioskMode && <ItemHolder open={clipboardOpened} />}
    </ScaleProvider>
  );
};
