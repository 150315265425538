import React, { useCallback, useEffect, useMemo, useState } from 'react';
import deepEqual from 'fast-deep-equal';
import { useStore } from 'react-redux';
import { DragSourceMonitor } from 'react-dnd';
import {
  CreateChainCommandInput,
  TimelineResourcesQuery,
} from 'src/generated/graphql';
import {
  Scale,
  useCurrentPeriodScale,
  useGetHiddenRanges,
} from 'src/utils/scale';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { AppDispatch } from 'src/store';
import {
  deselectAllItems,
  selectItems,
  toggleSelectItem,
} from 'src/store/planner';
import {
  addChain,
  clearChain,
  closeItemSideDrawer,
  openItemSideDrawer,
} from 'src/store/drawer';
import { AccountSelectors } from 'src/store/login';

import { TimelineMarker } from 'src/components/Timeline/TimelineMarker';
import { calculateResources } from './calculations';
import { ResourceActions, useResourcesReducer } from './reducer';
import { ViewState } from './position-items';
import { CreateItemButtons } from './CreateItemButtons';
import { keyBy } from 'lodash';
import { Resource } from './Resource';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { drop } from 'src/server/item-drag-drop';
import { ItemState, ItemType } from 'src/models/item';
import ResourceItems from './ResourceItems';
import { useHotkeys } from 'react-hotkeys-hook';
import { calcPos, Chains } from 'src/components/Chain/Chains';
import { createChain } from 'src/server/chains';
import { ItemDragDropProvider } from './TimelineContext';
import { useScreenOffsets } from 'src/utils/screen-offsets';

export interface ResourcesDisplayProps {
  resources: TimelineResourcesQuery['resources'];
  getScroll: () => number;
  getTop: () => number;
}

const ResourcesDisplayComponent: React.VFC<ResourcesDisplayProps> = ({
  resources,
  getScroll,
  getTop,
}) => {
  const apollo = useApolloClient();
  const scale = useCurrentPeriodScale();
  const reduxDispatch = useAppDispatch();
  const [state, dispatch] = useResourcesReducer();

  const editingItemId = useAppSelector(({ drawer }) => drawer.editingItemId);
  const addingChain = useAppSelector(({ drawer }) => drawer.addingChain);
  const canEdit = useAppSelector(AccountSelectors.canEdit);

  const { isDragging, onDrag, onDrop } = useDragDrop({
    apollo,
    dispatch: reduxDispatch,
    scale,
    resources,
  });

  const closeChain = useCallback(() => {
    if (state.chainStart) {
      dispatch(ResourceActions.closeChain());
    }
    if (addingChain) {
      reduxDispatch(clearChain());
    }
  }, [state, dispatch, addingChain, reduxDispatch]);

  const closeAll = useCallback(() => {
    reduxDispatch(deselectAllItems());

    closeChain();
    if (state.createItemLocation) {
      dispatch(ResourceActions.closeCreateItem());
    }

    if (editingItemId) {
      reduxDispatch(closeItemSideDrawer());
    }
  }, [closeChain, state, dispatch, editingItemId, reduxDispatch]);

  const getHiddenTimes = useGetHiddenRanges();
  const { itemMap, itemData, calculations, resourceTops, totalHeight } =
    useMemo(() => {
      const result = calculateResources({
        scale,
        getHiddenTimes,
        getViewState: (rid) =>
          state.resourceViewStates[rid] ?? ViewState.Normal,
        resources,
      });
      const itemMap = keyBy(result.itemData, (v) => v.item.id);
      return { itemMap, ...result };
    }, [resources, state.resourceViewStates, scale, getHiddenTimes]);

  const onSelectItem = useCallback(
    (id: ID, modifier: 'shift' | 'ctrl' | null, autoFocus = false) => {
      if (isDragging) return;

      if (modifier == null) {
        closeAll();
      }

      if (modifier === 'shift' && editingItemId != null) {
        let from = itemMap[editingItemId];
        let to = itemMap[id];
        if (!from || !to) return;

        const resourceId = from.item.timelineUsage!.resourceId;
        if (resourceId !== to.item.timelineUsage!.resourceId) return;

        if (
          from.item.timelineUsage!.startTime > to.item.timelineUsage!.startTime
        ) {
          const tmp = from;
          from = to;
          to = tmp;
        }

        const resource = resources.find((x) => x.id === resourceId);
        if (!resource) return;

        const toSelect = resource.items
          .filter(
            (x) =>
              x.timelineUsage!.startTime >=
                from.item.timelineUsage!.startTime &&
              x.timelineUsage!.endTime <= to.item.timelineUsage!.endTime,
          )
          .map((x) => x.id);

        reduxDispatch(selectItems(toSelect));
        reduxDispatch(openItemSideDrawer({ id, autoFocus }));
        return;
      }

      reduxDispatch(openItemSideDrawer({ id, autoFocus }));
      reduxDispatch(toggleSelectItem(id));
    },
    [closeAll, resources, itemMap, editingItemId, isDragging, reduxDispatch],
  );

  const allowedTypesForResource = useCallback(
    (id) => {
      const resource = resources.find((r) => r.id === id)!;
      const permitted: ItemType[] = [];
      if (resource.allowProcesses) permitted.push('Process');
      if (resource.allowRuns) permitted.push('Run');
      if (resource.allowSinks) permitted.push('Sink');
      if (resource.allowSources) permitted.push('Source');
      return permitted;
    },
    [resources],
  );

  const displayCreateItem = useCallback(
    (id: ID, x: number, y: number) => {
      if (!canEdit) return;
      dispatch(
        ResourceActions.openCreateItem({
          resourceId: id,
          allowedTypes: allowedTypesForResource(id),
          pos: { x, y: y + getScroll() },
        }),
      );
    },
    [allowedTypesForResource, dispatch, getScroll, canEdit],
  );

  const cycleResourceViewState = useCallback(
    (id: ID) => (ev: React.MouseEvent) => {
      ev.preventDefault();
      closeAll();
      const resourceIndex = resources.findIndex((x) => x.id === id);
      dispatch(
        ResourceActions.cycleResourceViewState({
          id,
          expandable: calculations[resourceIndex].layout.stacked,
        }),
      );
    },
    [closeAll, dispatch, resources, calculations],
  );

  const resourceOnMouseOver = useCallback(
    (evt: React.MouseEvent) => {
      dispatch(
        ResourceActions.updateEndOfChain({
          pos: {
            x: evt.clientX,
            y: evt.clientY + getScroll() - getTop(),
          },
        }),
      );
    },
    [dispatch, getScroll, getTop],
  );

  const selectedItems = useAppSelector((state) => state.planner.selectedItems);

  const [itemStateOverrides, setItemStateOverrides] = useState<
    Record<ID, ItemState>
  >({});

  const [escPressed, setEscPressed] = useState(false);

  useHotkeys(
    'esc',
    () => {
      if (editingItemId && selectedItems.size > 0) {
        setEscPressed(true);
        closeChain();
      } else {
        closeAll();
      }
    },
    [closeChain, selectedItems, editingItemId],
  );

  const isValidToFinishChain = useCallback(
    (item: { id: ID; __typename: ItemType }) => {
      const chainStart = state.chainStart!;
      if (chainStart.itemId === item.id) return false;

      let sourceItem = (
        itemData.find((i) => i.item.id === chainStart.itemId)!.item as any
      ).__typename;

      let targetItem = item.__typename;
      if (!chainStart.isOutput) {
        [sourceItem, targetItem] = [targetItem, sourceItem];
      }

      // Cannot start with a Sink or end with a Source, unless
      // the chain is from Sink to a Source
      const invalid = (sourceItem === 'Sink') !== (targetItem === 'Source');
      return !invalid;
    },
    [state, itemData],
  );

  const finishCreateChain = useCallback(
    (item: { id: ID; __typename: ItemType }) => {
      if (!state.chainStart) return;
      if (!isValidToFinishChain(item)) {
        closeChain();
        return;
      }

      const ids = [state.chainStart.itemId, item.id];
      const [sourceItemId, targetItemId] = state.chainStart.isOutput
        ? ids
        : ids.reverse();
      closeChain();
      createChain(
        apollo,
        {
          sourceItemId,
          targetItemId,
        },
        editingItemId!,
      ).then(() => {
        apollo.resetStore();
      });
    },
    [state, apollo, editingItemId, isValidToFinishChain, closeChain],
  );

  const itemOnMouseOver = useCallback(
    (item: { id: ID; __typename: ItemType }, evt: React.MouseEvent) => {
      if (isValidToFinishChain(item)) {
        const pos = calcPos(
          itemData.find((i) => i.item.id === item.id)!,
          resourceTops,
          !state.chainStart!.isOutput,
        );
        dispatch(
          ResourceActions.updateEndOfChainWithItem({ itemId: item.id, pos }),
        );
      } else {
        const pos = { x: evt.clientX, y: evt.clientY + getScroll() - getTop() };
        dispatch(ResourceActions.updateEndOfChain({ pos }));
      }
    },
    [
      isValidToFinishChain,
      dispatch,
      itemData,
      getScroll,
      getTop,
      state.chainStart,
      resourceTops,
    ],
  );

  // chain actions & callbacks
  const startCreateChain = useCallback(
    (chainData: Partial<CreateChainCommandInput>) => {
      const isOutput = Object.keys(chainData)[0] === 'sourceItemId';
      const itemId = Object.values(chainData)[0] as ID;
      const item = itemData.find((i) => i.item.id === itemId);
      if (!item) return;
      const pos = calcPos(item, resourceTops, isOutput);

      if (pos) {
        dispatch(
          ResourceActions.startChain({
            itemId,
            pos,
            isOutput,
          }),
        );
        reduxDispatch(addChain(isOutput ? 'output' : 'input'));
      }
    },
    [dispatch, reduxDispatch, itemData, resourceTops],
  );

  useEffect(() => {
    if (addingChain && !state.chainStart && !escPressed) {
      const type = addingChain === 'input' ? 'targetItemId' : 'sourceItemId';
      startCreateChain({
        [type]: editingItemId,
      });
    }
  }, [addingChain, state, editingItemId, startCreateChain, escPressed]);

  const screenOffsets = useScreenOffsets();

  return (
    <>
      <TimelineMarker height={totalHeight + 40} />
      <ItemDragDropProvider onDrag={onDrag} onDrop={onDrop}>
        {resources.map((resource, idx) => {
          const { id, name, inventoryWorkstationId, schedule } = resource;
          const { layout, viewState, hiddenTimes } = calculations[idx];
          return (
            <Resource
              key={id}
              height={layout.resourceHeight}
              cycleViewState={cycleResourceViewState}
              inventoryWorkstationId={inventoryWorkstationId ?? null}
              {...{
                allowedItems: allowedTypesForResource(id),
                displayCreateItem,
                onClick: closeAll,
                id,
                name,
                scale,
                schedule,
                viewState,
                screenOffsets,
                onMouseMove: state.chainStart ? resourceOnMouseOver : undefined,
              }}
            >
              <ResourceItems
                onSelectItem={onSelectItem}
                createChain={state.chainStart ? finishCreateChain : undefined}
                onMouseMove={state.chainStart ? itemOnMouseOver : undefined}
                selectedItems={selectedItems}
                resourceInventoryWorkstationId={inventoryWorkstationId ?? null}
                hiddenTimes={hiddenTimes}
                schedule={schedule}
                items={layout.items}
                itemStateOverrides={itemStateOverrides}
              />
            </Resource>
          );
        })}
      </ItemDragDropProvider>
      {editingItemId != null && !isDragging && (
        <Chains
          itemId={editingItemId}
          items={itemMap}
          {...{
            resourceTops,
            scale,
            startCreateChain,
            setItemStateOverrides,
            canEdit,
            // eslint-disable-next-line no-nested-ternary
            inProgressChain: state.chainStart
              ? state.chainStart.isOutput
                ? {
                    start: state.chainStart.pos,
                    end: state.chainEnd
                      ? state.chainEnd.pos
                      : state.chainStart.pos,
                  }
                : {
                    end: state.chainStart.pos,
                    start: state.chainEnd
                      ? state.chainEnd.pos
                      : state.chainStart.pos,
                  }
              : undefined,
          }}
        />
      )}
      {state.createItemLocation && (
        <CreateItemButtons
          {...state.createItemLocation}
          onCreated={(id) => {
            dispatch(ResourceActions.closeCreateItem());
            onSelectItem(id, null, true);
          }}
          schedule={
            resources.find(
              (x) => x.id === state.createItemLocation!.resourceId,
            )!.schedule
          }
        />
      )}
    </>
  );
};

export const ResourcesDisplay = React.memo(
  ResourcesDisplayComponent,
  deepEqual,
);

function useDragDrop({
  resources,
  scale,
  apollo,
  dispatch,
}: {
  resources: TimelineResourcesQuery['resources'];
  scale: Scale;
  apollo: ApolloClient<unknown>;
  dispatch: AppDispatch;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const { getState } = useStore();

  const onDrag = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onDrop = useCallback(
    (monitor: DragSourceMonitor, permitted: boolean) => {
      setIsDragging(false);
      if (!permitted) return;
      drop({
        apollo,
        state: getState(),
        dispatch,
        scale,
        monitor,
        resources,
      });
    },
    [apollo, getState, dispatch, scale, resources],
  );

  return {
    isDragging,
    onDrag,
    onDrop,
  };
}
