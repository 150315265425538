import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { useAppDispatch } from 'src/store/hooks';
import { useCompareTime, updateCompareTime } from 'src/store/planner';
import { timeHour, timeSecond } from 'd3-time';

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
const INTERVAL_MS = IS_DEVELOPMENT ? 5000 : 30000;

function currentCompareTime(hours: number) {
  if (IS_DEVELOPMENT) {
    // Speed it up in dev to make testing easier
    return timeSecond.offset(new Date(), -hours);
  }

  return timeHour.offset(new Date(), -hours);
}

export const CompareToggle: React.VFC = () => {
  const dispatch = useAppDispatch();
  const compareTime = useCompareTime();

  const [compareHours, setCompareHours] = useState(24);

  const comparing = compareTime != null;
  useEffect(() => {
    if (!comparing) return;

    const update = () => {
      dispatch(updateCompareTime(currentCompareTime(compareHours)));
    };

    update();
    const updateLoop = setInterval(update, INTERVAL_MS);

    return () => {
      clearInterval(updateLoop);
    };
  }, [dispatch, comparing, compareHours]);

  return (
    <div style={{ position: 'relative' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={comparing}
            color="secondary"
            onChange={(e) => {
              dispatch(
                updateCompareTime(
                  e.target.checked ? currentCompareTime(compareHours) : null,
                ),
              );
            }}
          />
        }
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant="standard"
              sx={{ minWidth: 50 }}
              value={compareHours}
              onChange={(e) => {
                setCompareHours(e.target.value as number);
              }}
            >
              {Array(24)
                .fill(undefined)
                .map((_, idx) => (
                  <MenuItem key={idx + 1} value={idx + 1}>
                    {idx + 1}
                  </MenuItem>
                ))}
            </Select>
            <Typography>Hour Compare</Typography>
          </div>
        }
      />
    </div>
  );
};

const Select = styled(MuiSelect)`
  width: 40px;
  margin-right: 0.5em;

  .MuiSelect-select {
    padding: 2px;

    &:focus {
      background-color: #f9f9f9 !important;
    }
  }

  .MuiSelect-icon {
    color: #5878ff;
  }
`;
