import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useReducer } from 'react';
import { Position } from 'src/models';
import { ItemType } from 'src/models/item';
import { ViewState } from './position-items';

// TODO: consider separating chains?
interface ResourcesState {
  /* maps id to a view state override - if not present, then viewed normally */
  resourceViewStates: Record<ID, ViewState>;
  createItemLocation?: CreateItemLocation;
  chainStart?: ChainStart;
  chainEnd?: ChainEnd;
}

export interface CreateItemLocation {
  resourceId: ID;
  allowedTypes: ItemType[];
  pos: Position;
}

interface ChainStart {
  itemId: ID;
  pos: Position;
  isOutput: boolean;
}

interface ChainEnd {
  itemId?: ID;
  pos: Position;
}

const initialState: ResourcesState = {
  resourceViewStates: {},
};

const resourcesSlice = createSlice({
  initialState,
  name: 'resources',
  reducers: {
    cycleResourceViewState(
      state,
      action: PayloadAction<{ id: ID; expandable: boolean }>,
    ) {
      const { id, expandable } = action.payload;
      const map: Record<ViewState, ViewState> = {
        [ViewState.Normal]: expandable
          ? ViewState.Expanded
          : ViewState.Compressed,
        [ViewState.Expanded]: ViewState.Normal,
        [ViewState.Compressed]: ViewState.Normal,
      };

      state.resourceViewStates[id] =
        map[state.resourceViewStates[id] ?? ViewState.Normal];
    },
    openCreateItem(state, action: PayloadAction<CreateItemLocation>) {
      state.createItemLocation = action.payload;
    },
    closeCreateItem(state) {
      state.createItemLocation = undefined;
    },
    startChain(state, action: PayloadAction<ChainStart>) {
      state.chainStart = action.payload;
    },
    updateEndOfChainWithItem(state, action: PayloadAction<ChainEnd>) {
      state.chainEnd = action.payload;
    },
    updateEndOfChain(state, action: PayloadAction<{ pos: Position }>) {
      state.chainEnd = action.payload;
    },
    closeChain(state) {
      state.chainStart = undefined;
      state.chainEnd = undefined;
    },
  },
});

export function useResourcesReducer() {
  return useReducer(resourcesSlice.reducer, initialState);
}

export const ResourceActions = resourcesSlice.actions;
