import React from 'react';
import styled from 'styled-components';

export interface ProblemProps {
  fullscreen?: boolean;
  errorText?: string;
}

const ProblemStyling = styled.div<{ fullscreen: number | undefined }>`
  display: flex;
  justify-content: center;

  ${(props) =>
    props.fullscreen === 1 &&
    `
    width: 100vw;
    height: 100vh;
    align-items: center;
  `}
`;

export const Problem: React.VFC<ProblemProps> = ({
  fullscreen,
  errorText = 'Something went wrong',
}) => (
  <ProblemStyling fullscreen={fullscreen ? 1 : undefined}>
    <div>
      {errorText}{' '}
      <span role="img" aria-label="sad-face">
        😢
      </span>
    </div>
  </ProblemStyling>
);
