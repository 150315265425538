import React, { useMemo } from 'react';
import { arc, ARC_RADIUS } from './arc';
import { CHAIN_STROKE_WIDTH } from '.';
import { Position } from 'src/models';

export interface ChainPathProps {
  sourceItemId: string;
  targetItemId: string;
  points: Position[];
  color: string;
  fadeStart: boolean;
  fadeEnd: boolean;
}

/**
 * Draws an SVG chain link/path between the two items specified,
 * using a list of points the path will pass through. Those points are
 * determined higher up the component tree and depend on the locations of the
 * connected items and other information.
 */
export const ChainPath: React.VFC<ChainPathProps> = ({
  sourceItemId,
  targetItemId,
  points,
  color,
  fadeStart,
  fadeEnd,
}) => {
  const dStr = useMemo(() => getPathStr(points), [points]);
  if (points.length === 0) return null;

  const id = `${sourceItemId}-${targetItemId}`;
  const fade = fadeStart || fadeEnd;
  return (
    <>
      {fade && (
        <defs>
          <linearGradient
            id={id}
            x1={fadeStart ? '100%' : '0%'}
            y1="0%"
            x2={fadeEnd ? '100%' : '0%'}
            y2="0%"
          >
            <stop offset="0%" style={{ stopColor: color, stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: color, stopOpacity: 0 }} />
          </linearGradient>
        </defs>
      )}
      <path
        d={dStr}
        stroke={fade ? `url(#${id})` : color}
        fillOpacity={0}
        strokeWidth={CHAIN_STROKE_WIDTH}
      />
    </>
  );
};

const lineTo = (x: number, y: number) => ` L${x} ${y}`;

function getPathStrInner(index: number, points: Position[]): string {
  const prevPoint = points[index - 1];
  const currentPoint = points[index];
  const nextPoint = points[index + 1];

  if (currentPoint == null) return '';
  if (nextPoint == null) return lineTo(currentPoint.x, currentPoint.y);

  const vertical = prevPoint.x === currentPoint.x;
  const northbound = prevPoint.y < currentPoint.y;
  const eastbound = prevPoint.x < currentPoint.x;
  const arcShift = northbound || eastbound ? -ARC_RADIUS : ARC_RADIUS;

  const arcStr = arc(prevPoint)(currentPoint)(nextPoint);
  const lineStr = vertical
    ? lineTo(currentPoint.x, currentPoint.y + arcShift)
    : lineTo(currentPoint.x + arcShift, currentPoint.y);

  return lineStr + arcStr + getPathStrInner(index + 1, points);
}

export const getPathStr = (points: Position[]) => {
  if (points.length === 0) return '';

  return `M${points[0].x} ${points[0].y}${getPathStrInner(1, points)} l0 1`;
};
