import { useEffect } from 'react';
import { QueryResult } from '@apollo/client';
import { AppDispatch } from 'src/store';
import { clipboardLoading } from 'src/store/planner';
import {
  ClipboardQuery,
  ClipboardQueryVariables,
  ItemMovedFromClipboardDocument,
  ItemMovedFromClipboardSubscription,
  ItemMovedToClipboardDocument,
  ItemMovedToClipboardSubscription,
  useClipboardQuery,
} from 'src/generated/graphql';

export function useClipboard(dispatch: AppDispatch) {
  const { data, subscribeToMore, loading } = useClipboardQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      dispatch(clipboardLoading(false));
    },
  });

  useEffect(() => {
    if (loading) {
      dispatch(clipboardLoading(true));
    }
  }, [loading, dispatch]);

  useEffect(() => {
    return subscribeToClipboard(subscribeToMore);
  }, [subscribeToMore]);

  return { data };
}

type ClipboardSubscribeToMore = QueryResult<
  ClipboardQuery,
  ClipboardQueryVariables
>['subscribeToMore'];

function subscribeToClipboard(subscribeToMore: ClipboardSubscribeToMore) {
  const subscribed = [
    subscribeToMore({
      document: ItemMovedToClipboardDocument,
      updateQuery: (prev, { subscriptionData }: any) => {
        if (!prev || !subscriptionData?.data) return prev;
        const { itemMovedToClipboard } =
          subscriptionData.data as ItemMovedToClipboardSubscription;
        const itemsMoved = itemMovedToClipboard.items;

        return {
          ...prev,
          clipboard: {
            ...prev.clipboard,
            items: prev.clipboard.items.concat(
              itemsMoved.map((c) => ({
                ...c,
                id: c.id,
                title: c.title,
                subtitle: c.subtitle,
                colorway: c.colorway,
              })),
            ),
          },
        };
      },
    }),
    subscribeToMore({
      document: ItemMovedFromClipboardDocument,
      updateQuery: (prev, { subscriptionData }: any) => {
        if (!prev || !subscriptionData?.data) return prev;
        const { itemMovedFromClipboard } =
          subscriptionData.data as ItemMovedFromClipboardSubscription;
        return {
          ...prev,
          clipboard: {
            ...prev.clipboard,
            items: prev.clipboard.items.filter(
              (c) => c.id !== itemMovedFromClipboard.items[0].id,
            ),
          },
        } as any;
      },
    }),
  ];

  return () => {
    for (const unsub of subscribed) {
      unsub();
    }
  };
}
