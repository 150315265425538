import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as ClockIcon } from 'src/assets/icons/clock.svg';
import { hoursToSeconds } from 'src/utils';
import { DatePickerDisplay } from './DatePickerDisplay';
import { DurationBox } from './DurationBox';

export interface SimpleDatePickerProps {
  disabled: boolean;
  disableStartTime: boolean;
  startTime: Date;
  endTime?: Date;
  setStartTime?: (v: Date) => void;
  setEndTime?: (v: Date) => void;
  duration?: Hours;
  setDuration?: (s: Seconds) => void;
  hideDuration: boolean;
}

export const SimpleDatePicker: React.FC<SimpleDatePickerProps> = ({
  disabled,
  disableStartTime,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  duration,
  setDuration,
  hideDuration,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: hideDuration ? 'center' : 'space-between',
        marginTop: 37,
      }}
    >
      <div
        style={{
          width: hideDuration ? undefined : '100%',
          display: 'flex',
        }}
      >
        <ClockIcon style={{ marginRight: 10 }} />
        <DatePickerDisplay
          value={startTime}
          setValue={disabled || disableStartTime ? undefined : setStartTime}
        />
        {endTime && (
          <>
            <DividingLine />
            <DatePickerDisplay
              value={endTime}
              setValue={disabled ? undefined : setEndTime}
              minDate={startTime}
            />
            {!hideDuration && (
              <Duration
                style={{
                  marginLeft: 'auto',
                  color: duration! <= 0 ? theme.errorPrimary : undefined,
                }}
              >
                {setDuration ? (
                  <DurationBox
                    disabled={disabled}
                    value={round(duration!)}
                    setValue={(s) => setDuration(hoursToSeconds(s))}
                    roundValue={0.25}
                  />
                ) : (
                  round(duration!)
                )}
                h
              </Duration>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Like toFixed but trailing zeroes are removed for cleanliness
const round = (value: Hours) =>
  Number((Math.round(value * 100) / 100).toFixed(2)) as Hours;

const DividingLine = styled.div`
  width: 15.5px;
  height: 1px;
  margin: 11px 12px 0 12px;
  border: solid 1px #979797;
`;

const Duration = styled.div`
  align-self: center;
  font-size: 20px;
`;
