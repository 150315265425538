import { ApolloClient } from '@apollo/client';
import {
  CloneItemCommandInput,
  CloneItemDocument,
  CloneItemMutationFn,
  ExtendItemUsingDurationCommandDocument,
  ExtendItemUsingDurationCommandInput,
  ExtendItemUsingDurationCommandMutationFn,
  ResourceTypeField,
  SetItemLockedCommandInput,
  SetItemLockedDocument,
  SetItemLockedMutationFn,
  UpdateItemLocalFieldsDocument,
  UpdateItemLocalFieldsQuery,
  useGetItemFieldsQuery,
  useGetItemQuery,
  useSetItemColorwayMutation,
  useSetItemCustomFieldMutation,
  useSetItemDescriptionMutation,
  useSetItemLagMutation,
  useSetProcessTaskIconMutation,
} from 'src/generated/graphql';

export function useSetProcessTaskIcon() {
  return useSetProcessTaskIconMutation();
}

export function useSetItemDescription() {
  return useSetItemDescriptionMutation();
}

export function useSetItemLag() {
  return useSetItemLagMutation();
}

export function extendItemUsingDuration(
  apolloClient: ApolloClient<unknown>,
  input: ExtendItemUsingDurationCommandInput,
) {
  return (apolloClient.mutate as ExtendItemUsingDurationCommandMutationFn)({
    mutation: ExtendItemUsingDurationCommandDocument,
    variables: { input },
  });
}

export function useGetItemFields(itemId: ID) {
  return useGetItemFieldsQuery({
    variables: { itemId },
    fetchPolicy: 'cache-and-network',
  });
}

export function useSetItemCustomField() {
  const [setItemCustomField] = useSetItemCustomFieldMutation();
  return ({
    itemId,
    fieldDefinitions,
    field,
    value,
  }: {
    itemId: ID;
    fieldDefinitions: Omit<ResourceTypeField, 'type'>[];
    field: string;
    value: string;
  }) => {
    setItemCustomField({
      variables: {
        input: { itemId, name: field, value },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        setItemCustomField: true,
      },
      update: (proxy) => {
        const cached = proxy.readQuery<UpdateItemLocalFieldsQuery>({
          query: UpdateItemLocalFieldsDocument,
          variables: { itemId },
        });

        if (cached?.item == null) {
          return;
        }

        const { item } = cached;

        const title =
          fieldDefinitions[0] && field === fieldDefinitions[0].name
            ? value
            : item.title;
        const subtitle =
          fieldDefinitions[1] && field === fieldDefinitions[1].name
            ? value
            : item.subtitle;

        proxy.writeQuery<UpdateItemLocalFieldsQuery>({
          query: UpdateItemLocalFieldsDocument,
          variables: { itemId },
          data: {
            __typename: 'Query',
            item: {
              ...item,
              title,
              subtitle,
              customFields: item.customFields.map((c) =>
                c.name === field ? { ...c, value } : c,
              ),
            },
          },
        });
      },
    });
  };
}

export function useSetColorway() {
  return useSetItemColorwayMutation();
}

export function setItemLocked(
  apolloClient: ApolloClient<unknown>,
  input: SetItemLockedCommandInput,
) {
  return (apolloClient.mutate as SetItemLockedMutationFn)({
    mutation: SetItemLockedDocument,
    variables: { input },
  });
}

export function cloneItem(
  apolloClient: ApolloClient<unknown>,
  input: CloneItemCommandInput,
) {
  return (apolloClient.mutate as CloneItemMutationFn)({
    mutation: CloneItemDocument,
    variables: { input },
  });
}

export function useGetItem(itemId: ID) {
  return useGetItemQuery({
    variables: { id: itemId },
    fetchPolicy: 'cache-and-network',
  });
}
