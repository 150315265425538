import { useEffect } from 'react';
import { DragSourceMonitor, useDrag, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

export function useDragItem(input: {
  id: number | string;
  targetKey: string;
  dragData: object;
  onDrag: (monitor: DragSourceMonitor) => void;
  onDrop: (monitor: DragSourceMonitor, permitted: boolean) => void;
  dragDisabled: boolean;
}) {
  const [props, dragRef, preview] = useDrag({
    type: input.targetKey,
    item: (monitor) => {
      input.onDrag(monitor);
      return {
        id: input.id,
        data: input.dragData,
      };
    },
    end: (_, monitor) => {
      input.onDrop(monitor, monitor.didDrop());
    },
    canDrag: (_) => !input.dragDisabled,
    collect: (monitor) => {
      const source = monitor.getInitialSourceClientOffset();
      const absolute = monitor.getInitialClientOffset();
      if (source == null || absolute == null) {
        return {
          isDragging: false as const,
          offset: null,
        };
      }

      return {
        isDragging: monitor.isDragging(),
        offset: subtract(source, absolute),
      };
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // We only want to run on mount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [props, dragRef] as const;
}

function subtract(a: XYCoord, b: XYCoord): XYCoord {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
  };
}
