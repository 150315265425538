import styled from 'styled-components';
import { ItemColorway } from 'src/config/theme';

export const ProcessRowStyle = styled.div<ItemColorway & { selected: boolean }>`
  zoom: 0.85;
  width: 100%;
  height: 85px;
  margin-bottom: 2px;
  background-color: ${(props) => props.standardBackground};
  border-radius: 4px;
  ${(props) =>
    props.selected &&
    `
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  border: 3px solid ${props.selectedBorder};`};
  /* Fake border which doesn't double up on edges */
  box-shadow: 0 0 0 2px ${(props) => props.theme.lightTint};

  display: grid;
  align-items: center;
  grid-template-columns: 1fr 8fr 8fr 7% 7% 4fr;

  &:hover {
    filter: brightness(95%);
  }

  .drag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .name {
    font-size: 20px;
    font-weight: bold;

    .MuiInput-root:first-child {
      margin-right: 6px;
    }
  }

  .duration {
    display: flex;

    div:first-child {
      margin-right: 6px;
    }

    div {
      position: relative;
    }

    input {
      text-align: right;
      padding-right: 20px;
    }

    .unit {
      position: absolute;
      right: 5px;
      top: 15px;
    }

    .MuiTypography-root {
      font-size: 0.9em;
    }
  }

  .in-out {
    text-align: left;
  }

  .icon {
    display: flex;
    justify-content: flex-end;

    img {
      max-height: 60px;
      max-width: 60px;
    }
  }

  .end {
    display: flex;
    justify-content: flex-end;
    padding: 0 27px;

    :before {
      content: '';
      position: relative;
      left: -10px;
      top: 7px;
      width: 2px;
      height: 28px;
      background-color: ${(props) => props.theme.lightTint};
    }
  }
`;
