import { useSearchQuery, SearchQueryVariables } from 'src/generated/graphql';

export function useSearch(input: SearchQueryVariables) {
  const { data, previousData } = useSearchQuery({
    variables: input,
    skip: input.searchQuery === '',
  });

  return { data, previousData };
}
