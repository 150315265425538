import styled from 'styled-components';
import { line } from 'd3-shape';
import { scaleTime } from 'd3-scale';
import {
  timeHour,
  timeDay,
  timeMonday,
  timeMinute,
  timeSaturday,
  timeSunday,
} from 'd3-time';
import { Period } from 'src/store/planner';
import { generateBackgroundComponent } from './backgroundUtils';
import { Scale } from 'src/utils/scale';
import {
  BACKGROUND_BIG_TICK_COLOR,
  BACKGROUND_SMALL_TICK_COLOR,
  BACKGROUND_WEEKEND_BORDER_TICK_COLOR,
  BACKGROUND_WEEKEND_TICK_COLOR,
  BACKGROUND_HOLIDAY_TICK_COLOR,
} from 'src/constants';
import holidays from 'src/assets/holidays/holidays_23-27.json';

export interface TickBackgroundProps {
  scale: Scale;
  period: Period;
}

function isWeekend(date: Date) {
  return date.getDay() === 0 || date.getDay() === 6;
}

function isHoliday(date: Date) {
  const dateString = new Date(date.getTime() + 12 * 60 * 60 * 1000).toISOString().substring(0, 10);  
  const holiday = holidays.find((holiday) => holiday[0] === dateString);
  return holiday ? true : false;
}

const getTicks = (period: Period, scale: Scale) => {
  const fullScale = scaleTime().domain(scale.domain());

  let bigTicks: number[];
  let smallTicks: number[] = [];
  const weekendTicks: number[] = [];
  const holidayTicks: number[] = [];
  if (period === Period.Day || period === Period.TwoDay) {
    bigTicks = fullScale.ticks(timeHour).map(scale);
    const allTicks = scale.ticks(timeMinute.every(15)!);
    for (const tick of allTicks) {
      if (isWeekend(tick)) {
        weekendTicks.push(scale(tick));
      } else if (isHoliday(tick)) {
        holidayTicks.push(scale(tick));
      } else {
        smallTicks.push(scale(tick));
      }
    }
  } else if (period === Period.Month) {
    bigTicks = fullScale.ticks(timeMonday).map(scale);
    smallTicks = fullScale.ticks(timeDay).map(scale);
  } else {
    bigTicks = fullScale.ticks(timeDay).map(scale);
    const allTicks = scale.ticks(timeHour);
    for (const tick of allTicks) {
      if (isWeekend(tick)) {
        weekendTicks.push(scale(tick));
      } else if (isHoliday(tick)) {
        holidayTicks.push(scale(tick));
      } else {
        smallTicks.push(scale(tick));
      }
    }
  }

  const weekendBorderTicks = [
    ...fullScale.ticks(timeSaturday).map(scale),
    ...fullScale.ticks(timeSunday).map(scale),
    ...fullScale.ticks(timeMonday).map(scale),
  ];

  return [
    bigTicks,
    smallTicks.filter((tick) => bigTicks.indexOf(tick) === -1),
    weekendBorderTicks,
    weekendTicks,
    holidayTicks,
  ];
};

const TickBackgroundUnstyled = generateBackgroundComponent<TickBackgroundProps>(
  ['scale', 'period'],
  ({ scale, period }) => {
    const [bigTicks, smallTicks, weekendBorderTicks, weekendTicks, holidayTicks] = getTicks(
      period,
      scale,
    );

    const canvas = document.createElement('canvas');
    const [, width] = scale.range();
    canvas.width = width;
    canvas.height = 1;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const colorTickPairs: [string, number[]][] = [
        [BACKGROUND_SMALL_TICK_COLOR, smallTicks],
        [BACKGROUND_BIG_TICK_COLOR, bigTicks],
        [BACKGROUND_WEEKEND_BORDER_TICK_COLOR, weekendBorderTicks],
        [BACKGROUND_WEEKEND_TICK_COLOR, weekendTicks],
        [BACKGROUND_HOLIDAY_TICK_COLOR, holidayTicks],
      ];
      for (const [color, ticks] of colorTickPairs) {
        ctx.beginPath();
        ctx.strokeStyle = color;
        for (const tick of [...new Set(ticks)]) {
          line().context(ctx)([
            [tick, 0],
            [tick, canvas.height],
          ]);
        }
        ctx.stroke();
      }
    }
    return canvas.toDataURL();
  },
);

export const TickBackground = styled(TickBackgroundUnstyled)`
  .resources {
    border-bottom: solid 0.5px ${(props) => props.theme.resourceDivider};
  }

  .highlighted > .resources {
    box-shadow: 0 0 9px 0 rgba(150, 150, 150, 0.5);
    & > .MuiGrid-root > span {
      font-weight: bold;
    }
  }
`;
