import React, { useEffect } from 'react';

export interface PromptProps {
  when: boolean;
}

export const Prompt: React.VFC<PromptProps> = ({ when }) => {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [when]);

  return null;
};
