import { QueryResult } from '@apollo/client';
import { produce } from 'immer';
import {
  ItemChangedDocument,
  ItemConflictStatusChangedDocument,
  ItemCreatedDocument,
  ItemCreatedSubscription,
  ItemCreatedSubscriptionResult,
  ItemDeletedDocument,
  ItemDeletedSubscription,
  ItemMovedDocument,
  ItemMovedSubscription,
  Process,
  ResourceChangedDocument,
  ResourceCreatedDocument,
  ResourceCreatedSubscription,
  ResourceDeletedDocument,
  ResourceDeletedSubscription,
  ResourceScheduleChangedDocument,
  TimelineResourcesQuery,
} from 'src/generated/graphql';
import { assumeItemType } from '../models/item';

type ItemMap = Map<ID, { resourceId: ID }>;
type TimelineItem = TimelineResourcesQuery['resources'][0]['items'][0];
type CreatedItem = ItemCreatedSubscription['itemCreated']['items'][0];
type MovedItem = ItemMovedSubscription['itemMoved']['items'][0];
type DeletedItem = ItemDeletedSubscription['itemDeleted']['items'][0];

function getItemMap(data: TimelineResourcesQuery): ItemMap {
  const items: ItemMap = new Map();

  for (const resource of data.resources) {
    for (const item of resource.items) {
      if (item.timelineUsage == null) continue;

      items.set(item.id, { resourceId: item.timelineUsage.resourceId });
      if (item.__typename === 'Run') {
        for (const { process } of item.runUsages) {
          items.set(process.id, {
            resourceId: process.timelineUsage!.resourceId,
          });
        }
      }
    }
  }

  return items;
}

function addItems(prev: TimelineResourcesQuery, items: CreatedItem[]) {
  return produce(prev, (draft) => {
    for (const item of items.filter((x) => !(x as Process).runUsage)) {
      // Not guaranteed to have a timelineUsage. Consider duplicating an item on
      // the clipboard. This would produce the `itemCreated` event, but since it
      // appears on the clipboard, it doesn't have a `timelineUsage`. I'm not a
      // huge fan of what the current events are and when they occur. When we
      // rebuild/refactor the backend it would be good to rethink these a bit.
      if (item.timelineUsage == null) {
        continue;
      }

      const { resourceId } = item.timelineUsage!;
      const resource = draft.resources.find((r) => r.id === resourceId)!;
      if (!resource) continue;
      resource.items.push(item);
    }

    const runs = draft.resources.flatMap((x) =>
      x.items.filter((x) => x.__typename === 'Run'),
    );
    const map = new Map(runs.map((x) => [x.id, x]));

    for (const item of items) {
      if (item.__typename !== 'Process' || item.runUsage == null) {
        continue;
      }

      const { runId } = item.runUsage;
      const run = map.get(runId);
      if (!run) continue;
      if (run.__typename !== 'Run') continue;

      run.lastPositioned = new Date();
      run.runUsages.push({
        __typename: 'RunUsage',
        id: item.runUsage.id,
        offset: item.runUsage.offset,
        process: item,
      });
    }
  });
}

type StrongRemoveItem =
  | CreatedItem
  | Exclude<
      TimelineItem,
      { __typename: 'Process' | 'Source' | 'Sink' }
    >['runUsages'][0]['process'];

function strongRemoveItems(
  prev: TimelineResourcesQuery,
  itemIds: ID[],
): [StrongRemoveItem[], TimelineResourcesQuery] {
  const removed: StrongRemoveItem[] = [];

  const filter = (item: TimelineItem) => {
    if (!itemIds.includes(item.id)) {
      if (item.__typename === 'Run') {
        const runUsages = item.runUsages.filter((usage) => {
          if (!itemIds.includes(usage.process.id)) return true;
          removed.push(usage.process);
          return false;
        });
        return [{ ...item, runUsages } as TimelineItem];
      }
      return [item];
    }

    removed.push(item);
    return [];
  };

  const state: TimelineResourcesQuery = {
    ...prev,
    resources: prev.resources.map((r) => ({
      ...r,
      items: r.items.flatMap(filter),
    })),
  };
  return [removed, state];
}

function removeItems(
  prev: TimelineResourcesQuery,
  items: DeletedItem[],
  itemMap: ItemMap,
) {
  return produce(prev, (draft) => {
    for (const item of items) {
      const toDelete = itemMap.get(item.id);
      if (toDelete === undefined) continue;
      const rid = toDelete.resourceId;
      const resource = draft.resources.find((r) => r.id === rid);
      if (!resource) continue;
      resource.items = resource.items.filter((i) => {
        if (i.__typename === 'Run') {
          i.runUsages = i.runUsages.filter((x) => x.process.id !== item.id);
        }
        return i.id !== item.id;
      });
    }
  });
}

function moveItems(prev: TimelineResourcesQuery, items: MovedItem[]) {
  const [removed, state] = strongRemoveItems(
    prev,
    items.map((i) => i.id),
  );
  const map = new Map(items.map((x) => [x.id, x]));
  return addItems(
    state,
    removed.flatMap((item): CreatedItem[] => {
      const moved = map.get(item.id);
      if (moved == null || item.__typename !== moved.__typename) {
        return [];
      }

      if (item.__typename === 'Process') {
        assumeItemType<'Process'>(moved);
        return [{ ...item, ...moved }];
      }

      if (item.__typename === 'Run') {
        assumeItemType<'Run'>(moved);
        return [{ ...item, ...moved }];
      }

      if (item.__typename === 'Source') {
        assumeItemType<'Source'>(moved);
        return [{ ...item, ...moved }];
      }

      if (item.__typename === 'Sink') {
        assumeItemType<'Sink'>(moved);
        return [{ ...item, ...moved }];
      }

      return [];
    }),
  );
}

export function subscribeToTimeline(
  subscribeToMore: QueryResult['subscribeToMore'],
  refetch: () => void,
) {
  const toUnsubscribe: Array<() => void> = [];

  function massage(
    orig: (prev: TimelineResourcesQuery, data: any) => TimelineResourcesQuery,
  ) {
    return (prev: any, data: any) => {
      if (prev.resources) return orig(prev, data);
      const { resources } = orig(
        { __typename: 'Query', resources: [prev.resource] },
        data,
      );
      return { resource: resources[0] };
    };
  }

  toUnsubscribe.push(
    subscribeToMore({
      document: ItemCreatedDocument,
      updateQuery: massage((prev, { subscriptionData }: any) => {
        if (!prev || !subscriptionData?.data) return prev;
        const { data } = subscriptionData as ItemCreatedSubscriptionResult;
        const { items } = data!.itemCreated;
        // Handle any racing
        const itemIds = items.map((i) => i.id);
        const [, state] = strongRemoveItems(prev, itemIds);
        return addItems(state, items);
      }),
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ItemDeletedDocument,
      updateQuery: massage((prev, { subscriptionData }: any) => {
        if (!prev || !subscriptionData?.data) return prev;
        const { itemDeleted } =
          subscriptionData.data as ItemDeletedSubscription;
        return removeItems(prev, itemDeleted.items, getItemMap(prev));
      }),
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ItemMovedDocument,
      updateQuery: massage((prev, { subscriptionData }: any) => {
        if (!prev || !subscriptionData?.data) return prev;
        const { itemMoved } = subscriptionData.data as ItemMovedSubscription;
        return moveItems(prev, itemMoved.items);
      }),
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ItemChangedDocument,
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ItemConflictStatusChangedDocument,
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ResourceCreatedDocument,
      updateQuery: massage((prev, { subscriptionData }: any) => {
        if (!prev || !subscriptionData?.data) return prev;
        const { resourceCreated } =
          subscriptionData.data as ResourceCreatedSubscription;
        return {
          ...prev,
          resources: prev.resources.concat(
            resourceCreated.resources.map((r) => ({
              ...r,
              items: [],
              schedule: [],
            })),
          ),
        };
      }),
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ResourceDeletedDocument,
      updateQuery: massage((prev, { subscriptionData }: any) => {
        if (!prev || !subscriptionData?.data) return prev;
        const { resourceDeleted } =
          subscriptionData.data as ResourceDeletedSubscription;
        const ids = resourceDeleted.resources.map((r) => r.id);
        return {
          ...prev,
          resources: prev.resources.filter((r) => !ids.includes(r.id)),
        };
      }),
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ResourceChangedDocument,
    }),
  );

  toUnsubscribe.push(
    subscribeToMore({
      document: ResourceScheduleChangedDocument,
      updateQuery: (prev) => {
        refetch();
        return prev;
      },
    }),
  );

  return () => {
    toUnsubscribe.forEach((fun) => fun());
  };
}
