import styled from 'styled-components';

export const ItemTitleStyle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  color: ${(props) => props.theme.mainAccent};
`;

export const ItemSubtitleStyle = styled.div`
  opacity: 0.7;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.theme.mainAccent};
`;

export const PointItemTextWrapperStyle = styled.div`
  position: absolute;
  left: 20px;
  top: 0;
  transform: translateY(-50%);
  white-space: nowrap;
`;
