import { useWindowWidth } from '@react-hook/window-size';
import { useScreenCount } from 'src/store/planner';

export function useScreenOffsets() {
  const width = useWindowWidth();
  const screens = useScreenCount();
  return Array(screens)
    .fill(null)
    .map((_, index) => width * (index / screens));
}
