import React from 'react';
import styled from 'styled-components';
import { ButtonBase } from '@mui/material';
import { ReactComponent as CreateChainIcon } from 'src/assets/icons/chain-link.svg';
import { ColorwayName } from 'src/config/theme';
import { displayItemType, ItemType } from 'src/models/item';
import { ItemListContainer } from '../ItemListContainer';
import { ItemBubble } from './ItemBubble';

export interface InputOutputPanelProps {
  direction: 'input' | 'output';
  items: RequiredItemFields[];
  onRemoveItem: (id: ID) => void;
  onAddItem: () => void;
  adding: boolean;
  canEdit: boolean;
}

export interface RequiredItemFields {
  id: ID;
  __typename: ItemType;
  title?: string;
  colorway: ColorwayName;
}

export const InputOutputPanel: React.FC<InputOutputPanelProps> = ({
  adding,
  direction,
  items,
  onRemoveItem,
  onAddItem,
  canEdit,
}) => (
  <ItemListContainer>
    <div className="header">{direction === 'input' ? 'Input' : 'Output'}</div>
    {items.map(({ id, title, colorway, __typename }) => (
      <ItemBubble
        key={id}
        colorway={colorway}
        remove={canEdit ? () => onRemoveItem(id) : null}
        title={title || `${displayItemType(__typename)} #${id}`}
        taskIconId={null}
      />
    ))}
    {canEdit && <AddLink {...{ adding }} onClick={onAddItem} />}
  </ItemListContainer>
);

const AddLink: React.FC<{ adding: boolean; onClick: () => void }> = ({
  adding,
  onClick,
}) => (
  <AddLinkContainer onClick={onClick}>
    <div className="emblem">{!adding && <CreateChainIcon />}</div>
    <div className="text">{adding ? 'Select Item' : 'Add Link'}</div>
  </AddLinkContainer>
);

const AddLinkContainer = styled(ButtonBase)`
  display: flex;
  flex: 0 0 40px;
  justify-content: space-between;
  padding: 0 15px 0 5px;

  width: 138px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);

  .text {
    opacity: 0.42;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 14px;
    text-transform: uppercase;
    color: ${(props) => props.theme.mainAccent};
  }

  .emblem {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: ${(props) => props.theme.mainAccent};

    &:empty {
      width: 0;
    }

    & svg {
      width: 14.1px;
      height: 14.1px;
      object-fit: contain;
    }
  }
`;
