interface RetryInput {
  maxAttempts: number;
  delayMs: (failureCount: number, maxAttempts: number) => number;
  action: () => Promise<void> | void;
}

export async function retry({
  maxAttempts,
  delayMs,
  action,
}: RetryInput): Promise<void> {
  for (let i = 0; i < maxAttempts; i++) {
    try {
      return await action();
    } catch (e) {
      const givenUp = i === maxAttempts - 1;
      if (givenUp) {
        console.error(e);
      }
    }

    const delay = delayMs(i + 1, maxAttempts);
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
}
