import styled from 'styled-components';
import { Select as MuiSelect } from '@mui/material';

export const Select = styled(MuiSelect)`
  width: 100%;

  .MuiSelect-select {
    border: solid 1px #e2e4eb;
    padding: 14px 10px 12px 10px;

    &:focus {
      background-color: #f9f9f9 !important;
    }
  }

  .MuiSelect-icon {
    color: #5878ff;
    margin-right: 0.5em;
  }

  &::before,
  &::after {
    display: none !important;
  }
`;
