import React from 'react';
import { CircularOutlinedButton } from 'src/components/Util/Buttons';
import { Tooltip } from '@mui/material';
import {
  FirstPage as SnapLeftIcon,
  LastPage as SnapRightIcon,
} from '@mui/icons-material';
import { useSnapItems } from 'src/server/item-movement';
import { useShortcut } from 'src/utils/hooks';

export const SnapItems: React.VFC = () => {
  const { snapLeft, snapRight, selectedItems } = useSnapItems();

  useShortcut('shift+s', snapLeft, [snapLeft]);
  useShortcut('shift+r', snapRight, [snapRight]);

  const disabled = selectedItems.size === 0;
  const buttonStyle = disabled
    ? { filter: 'brightness(97%)' }
    : { backgroundColor: '#5878ff', color: '#fff' };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        title={
          disabled
            ? 'At least one item must be selected to snap'
            : 'Snap items to the left'
        }
      >
        <div>
          <CircularOutlinedButton
            disabled={disabled}
            style={buttonStyle}
            onClick={snapLeft}
          >
            <SnapLeftIcon />
          </CircularOutlinedButton>
        </div>
      </Tooltip>

      <div style={{ fontWeight: 500, margin: 'auto 20px' }}>Snap</div>

      <Tooltip
        title={
          disabled
            ? 'At least one item must be selected to snap'
            : 'Snap items to the right'
        }
      >
        <div>
          <CircularOutlinedButton
            disabled={disabled}
            style={buttonStyle}
            onClick={snapRight}
          >
            <SnapRightIcon />
          </CircularOutlinedButton>
        </div>
      </Tooltip>
    </div>
  );
};
