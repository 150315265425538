import React from 'react';
import styled from 'styled-components';
import { ItemState } from 'src/models/item';
import {
  ItemTitleStyle,
  ItemSubtitleStyle,
  PointItemTextWrapperStyle,
} from './ItemStyles';
import { colorways, ColorwayName, ItemColorway } from 'src/config/theme';
import { ReactComponent as LockedIcon } from 'src/assets/icons/locked-item.svg';

export interface SinkProps {
  onClick?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  data: SinkDisplayData;
}

interface SinkDisplayData {
  title: string;
  subtitle: string;
  error: boolean;
  changed: boolean;
  locked: boolean;
  state: ItemState;
  colorway: ColorwayName;
  onClipboard: boolean;
}

export const Sink: React.VFC<SinkProps> = ({
  onClick,
  onMouseMove,
  data: {
    title,
    subtitle,
    error,
    changed,
    locked,
    state,
    colorway,
    onClipboard,
  },
}) => (
  <div
    onClick={onClick}
    onMouseMove={onMouseMove}
    style={{
      position: 'relative',
      height: 20,
      margin: onClipboard ? 30 : undefined,
      opacity: state === ItemState.Inactive ? 0.5 : undefined,
    }}
  >
    <SinkCircle
      className={state === ItemState.Selected ? 'selected' : undefined}
      {...colorways[colorway]}
    />
    {error && (
      <ErrorCircle>
        <div className="error-dash bottom" />
        <div className="error-dash top" />
      </ErrorCircle>
    )}
    {changed && <ChangedHighlightCircle />}
    <PointItemTextWrapperStyle>
      <ItemTitleStyle
        style={{
          display: 'flex',
          color: onClipboard ? 'white' : 'inherit',
        }}
      >
        {locked && (
          <LockedIcon style={{ marginRight: 5, width: 17, height: 24 }} />
        )}
        <span>{title}</span>
      </ItemTitleStyle>
      <ItemSubtitleStyle style={{ color: onClipboard ? 'white' : 'inherit' }}>
        {subtitle}
      </ItemSubtitleStyle>
    </PointItemTextWrapperStyle>
  </div>
);

const SinkCircle = styled.div<ItemColorway>`
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.standardBackground};
  border: 3px solid ${(props) => props.selectedBackground};

  &.selected {
    background-color: ${(props) => props.selectedBackground};
    border: 3px solid ${(props) => props.selectedBorder};
  }
`;

const ErrorCircle = styled.div`
  .error-dash {
    position: absolute;
    top: 0;
    left: 0;
    width: 31px;
    height: 31px;
    transform: translate(-50%, -50%);
    border-radius: 15.5px;
    z-index: -1;
  }

  .top {
    border: 3px dashed ${(props) => props.theme.errorPrimary};
  }

  .bottom {
    border: 3px solid ${(props) => props.theme.errorSecondary};
  }
`;

const ChangedHighlightCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 31px;
  height: 31px;
  transform: translate(-50%, -50%);
  border-radius: 15.5px;
  z-index: -1;
  border: 3px solid ${(props) => props.theme.changedHighlight};
`;
