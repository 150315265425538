import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Button } from '@mui/material';
import { useSetProcessTaskIcon } from 'src/server/drawer';

const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const TaskIcons: React.VFC<{
  resourceTypeId: ID;
  processId: ID;
  taskIconId: ID;
  disabled: boolean;
}> = ({ resourceTypeId, processId, taskIconId, disabled }) => {
  const [mutate] = useSetProcessTaskIcon();
  const set = (id: ID | null) => {
    mutate({
      variables: {
        input: {
          processId,
          taskIconId: id,
        },
      },
    });
  };

  const { data } = useQuery('task-icons', () =>
    axios
      .get('/assets/task-icons/data.json')
      .then((x) => x.data as { data: Record<ID, ID[]> }),
  );

  if (!data) return null;

  const options = data.data[resourceTypeId];

  return (
    <Container>
      <Button
        disabled={disabled}
        onClick={() => set(null)}
        style={{
          margin: '0.5em',
          width: 64,
          height: 64,
          backgroundColor: taskIconId == null ? '#ccc' : undefined,
        }}
      >
        <span>No Icon</span>
      </Button>
      {options?.map((id) => (
        <Button
          disabled={disabled}
          key={id}
          onClick={() => set(id)}
          style={{
            margin: '0.5em',
            width: 64,
            height: 64,
            backgroundColor: taskIconId === id ? '#ccc' : undefined,
          }}
        >
          <img
            key={id}
            src={`/assets/task-icons/${id}.svg`}
            alt={`icon ${id}`}
            style={{
              maxWidth: 50,
              maxHeight: 50,
            }}
          />
        </Button>
      ))}
    </Container>
  );
};
