import React, { useEffect } from 'react';
import { TextBox } from 'src/components/Input/TextBox';
import { ColorPicker } from './ColorPicker';
import { ColorwayName } from 'src/config/theme';
import { useDebouncedCallback } from 'use-debounce';
import { useHotkeys } from 'react-hotkeys-hook';
import { ItemType } from 'src/models/item';
import { Colorway, ResourceTypeField } from 'src/generated/graphql';
import {
  useGetItemFields,
  useSetColorway,
  useSetItemCustomField,
} from 'src/server/drawer';

const DEBOUNCE_MS = 500;

export interface MainItemFieldsProps {
  colorway: ColorwayName | null;
  itemId: ID;
  disabled: boolean;
  itemType: ItemType;
  inRun: boolean;
  fieldDefinitions: Omit<ResourceTypeField, 'type'>[];
  onCloseDrawer: () => void;
  autoFocus: boolean;
}

export const MainItemFields: React.FC<MainItemFieldsProps> = ({
  colorway,
  itemId,
  disabled,
  inRun,
  fieldDefinitions,
  onCloseDrawer,
  autoFocus,
}) => {
  const fieldsSet = useGetItemFields(itemId);

  useHotkeys(
    'esc',
    () => {
      onCloseDrawer();
    },
    { filter: () => true },
    [],
  );

  const setItemCustomField = useSetItemCustomField();
  const serverSetField = useDebouncedCallback(
    (field: string, value: string) => {
      setItemCustomField({
        itemId,
        fieldDefinitions,
        field,
        value,
      });
    },
    DEBOUNCE_MS,
  );

  useEffect(
    () => () => {
      serverSetField.flush();
    },
    [serverSetField],
  );

  const [setColorway_] = useSetColorway();
  const setColorway = (v: ColorwayName) => {
    setColorway_({
      variables: {
        input: {
          itemId,
          colorway: v as Colorway,
        },
      },
    });
  };

  if (fieldsSet.error || !fieldsSet.data?.item) {
    return null;
  }

  const fieldValue = (field: string) => {
    const server = fieldsSet.data?.item?.customFields;
    if (server == null) return '';
    const result = server.find((s: any) => s.name === field);
    return result?.value ?? '';
  };

  const setField = (field: string, value: string) => {
    serverSetField(field, value);
  };

  const colorPicker = (alone: boolean) =>
    colorway &&
    setColorway && (
      <ColorPicker
        colorway={colorway}
        disabled={disabled}
        setColorway={setColorway}
        style={alone ? undefined : { position: 'absolute', right: 9, top: 10 }}
      />
    );

  const fields = fieldDefinitions.map((f: any) => f.name) as string[];
  if (inRun && fields.length === 2) return null;
  return (
    <form
      spellCheck={false}
      style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}
    >
      {fields.slice(inRun ? 2 : 0).map((fieldName, i) =>
        i === 0 ? (
          <div key={fieldName} style={{ position: 'relative' }}>
            <TextBox
              defaultValue={fieldValue(fieldName)}
              setValue={(v) => setField(fieldName, v)}
              placeholder={fieldName}
              style={{ width: '100%' }}
              inputStyle={{ paddingRight: 50 }}
              disabled={disabled}
              autoFocus={autoFocus}
            />
            {colorPicker(false)}
          </div>
        ) : (
          <TextBox
            key={fieldName}
            defaultValue={fieldValue(fieldName)}
            setValue={(v) => setField(fieldName, v)}
            disabled={disabled}
            placeholder={fieldName}
          />
        ),
      )}
      {fields.length === 0 && (
        <div style={{ position: 'relative' }}>{colorPicker(true)}</div>
      )}
    </form>
  );
};
