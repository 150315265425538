import styled from 'styled-components';

export const ResourceGradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  /* Ensure it fully covers the timeline height, with a bit extra on each end
     for good measure */
  height: calc(100% + 30px);

  z-index: 29;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 5%,
    #fff 20%
  );

  pointer-events: none;
`;
