import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { TidyLink } from 'src/components/Util';
import { Link } from 'react-router-dom';

import { ReactComponent as DragIcon } from 'src/assets/icons/six-dot-grab.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';

import { ReactComponent as SourceIcon } from 'src/assets/icons/symbol-source.svg';
import { ReactComponent as ProcessIcon } from 'src/assets/icons/symbol-process.svg';
import { ReactComponent as SinkIcon } from 'src/assets/icons/symbol-sink.svg';
import { ReactComponent as RunIcon } from 'src/assets/icons/symbol-run.svg';
import { Draggable } from 'react-beautiful-dnd';

export interface ResourceListItemProps {
  id: ID;
  index: number;
  name: string;
  allowSources: boolean;
  allowProcesses: boolean;
  allowSinks: boolean;
  allowRuns: boolean;
}

export const ResourceListItem: React.VFC<ResourceListItemProps> = ({
  id,
  index,
  name,
  allowSources,
  allowProcesses,
  allowSinks,
  allowRuns,
}) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ItemContainer ref={provided.innerRef} {...provided.draggableProps}>
          <div className="drag" {...provided.dragHandleProps}>
            <DragIcon />
          </div>
          <div className="name">{name}</div>
          <div className="item-types">
            {allowSources && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SourceIcon style={{ margin: '-2px 13px 0 0' }} />
                Source
              </div>
            )}
            {allowProcesses && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProcessIcon style={{ margin: '-2px 16px 0 3px' }} />
                Process
              </div>
            )}
            {allowSinks && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SinkIcon style={{ margin: '-2px 13px 0 0' }} />
                Sink
              </div>
            )}
            {allowRuns && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RunIcon style={{ margin: '-2px 13px 0 0' }} />
                Run
              </div>
            )}
          </div>
          <div />
          <TidyLink
            className="schedule"
            to={`/settings/resources/${id}/schedule`}
          >
            Edit Schedule
          </TidyLink>
          <div className="end-icons">
            <IconButton
              component={Link}
              to={`/settings/resources/${id}`}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </div>
        </ItemContainer>
      )}
    </Draggable>
  );
};

const ItemContainer = styled.div`
  width: 100%;
  height: 58px;
  margin-bottom: 2px;
  background-color: white;
  /* Fake border which doesn't double up on edges */
  box-shadow: 0 0 0 2px ${(props) => props.theme.lightTint};

  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 8fr 13fr 20% 4fr 2fr;

  .drag {
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    color: ${(props) => props.theme.calmText};
  }

  .item-types {
    display: flex;
    & > div {
      margin-right: 25px;
    }
  }

  .schedule {
    text-align: right;
  }

  .end-icons {
    display: flex;
    justify-content: flex-end;
    padding: 0 27px;

    :before {
      content: '';
      position: relative;
      left: -10px;
      top: 7px;
      width: 2px;
      height: 28px;
      background-color: ${(props) => props.theme.lightTint};
    }
  }
`;
