import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@mui/material';
import { colorways, ColorwayName } from 'src/config/theme';
import { useSearch } from '../../server/search';
import { FORMAT_DATE_YEAR, FORMAT_TIME } from 'src/constants';
import { timeFormat } from 'd3-time-format';
import { ReactComponent as SourceIcon } from 'src/assets/icons/symbol-source.svg';
import { ReactComponent as ProcessIcon } from 'src/assets/icons/symbol-process.svg';
import { ReactComponent as SinkIcon } from 'src/assets/icons/symbol-sink.svg';
import { ReactComponent as RunIcon } from 'src/assets/icons/symbol-run.svg';
import { useJumpToItem } from '../../utils/item';
import { useThrottleCallback } from '@react-hook/throttle';

const timeFormatter = timeFormat(FORMAT_TIME);
const dayFormatter = timeFormat(FORMAT_DATE_YEAR);

export interface SearchModalContentsProps {
  open: boolean;
  setOpen: (x: boolean) => void;
}

export const SearchModalContents: React.VFC<SearchModalContentsProps> = ({
  open,
  setOpen,
}) => {
  const [months, setMonths] = useState(3);
  const MONTHS_AHEAD = 3;
  const [throttledSearch, setSearch] = useState('');
  const setThrottledSearch = useThrottleCallback(setSearch, 3);
  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setThrottledSearch(event.target.value);
    },
    [setThrottledSearch],
  );
  const jumpToItem = useJumpToItem();

  const searchResults = useSearch({
    searchQuery: throttledSearch,
    take: 20,
    months: months,
  });

  const ref = useRef<HTMLInputElement>(null);
  setTimeout(() => ref.current && ref.current.focus(), 0);

  const renderIcon = (item: 'Source' | 'Sink' | 'Process' | 'Run') => {
    switch (item) {
      case 'Source':
        return <SourceIcon />;
      case 'Process':
        return <ProcessIcon />;
      case 'Run':
        return <RunIcon />;
      case 'Sink':
        return <SinkIcon />;
    }
  };

  const results = searchResults.data ?? searchResults.previousData;
  const items = results?.searchItems?.items;
  const handleClose = useCallback(() => {
    setOpen(false);
    setSearch('');
  }, [setOpen]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle style={{ background: '#e9e9e9' }}>Search</DialogTitle>
      <DialogContent>
        <SearchModalStyle>
          <div className="header">
            <input
              ref={ref}
              placeholder="Enter item..."
              className="search"
              defaultValue=""
              autoFocus
              onChange={onSearchChange}
              spellCheck={false}
            />
            <div className="search-config">
              Searching items <div className="months">{months} months</div>
              previous,
              <div className="months">{MONTHS_AHEAD} months</div> ahead
              <div className="buttons">
                <Button
                  className="add"
                  color="primary"
                  variant="contained"
                  onClick={() => setMonths(months + 3)}
                >
                  Increase
                </Button>
                <Button
                  className="reset"
                  color="secondary"
                  variant="contained"
                  disabled={months === 3}
                  onClick={() => setMonths(3)}
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>

          {items?.length ? (
            <List>
              {items.map((x) => {
                return (
                  <ListItemStyle
                    colorway={x.item.colorway}
                    key={x.item.id}
                    onClick={() => {
                      handleClose();
                      jumpToItem(x.item.id);
                    }}
                  >
                    <div className="header">
                      <div className="icon">
                        {renderIcon(x.item.__typename)}
                      </div>

                      <span className="title">{x.item.title}</span>
                      <span className="resource-name">
                        {x.item.timelineUsage?.resource.name}
                      </span>
                      <div className="start-time">
                        <span className="time">
                          {timeFormatter(
                            x.item.timelineUsage?.startTime as Date,
                          )}
                        </span>

                        {dayFormatter(x.item.timelineUsage?.startTime as Date)}
                      </div>
                    </div>

                    <div className="custom-fields">
                      <span className="name">{x.customField.name}:</span>
                      <span className="value">{x.customField.value}</span>
                    </div>
                  </ListItemStyle>
                );
              })}
            </List>
          ) : throttledSearch === '' ? (
            <Alert className="info-alert" severity="info">
              Please enter a search term.
            </Alert>
          ) : (
            items != null && (
              <Alert className="info-alert" severity="warning">
                Sorry, no items could be found.
              </Alert>
            )
          )}
        </SearchModalStyle>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface StyleProps {
  colorway: ColorwayName;
}

const ListItemStyle = styled.div<StyleProps>`
  color: ${(props) => props.theme.mainAccent};
  background: ${(props) => colorways[props.colorway]['standardBackground']};
  margin-bottom: 30px;
  padding: 20px 30px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 3px 4px 6px 0px #888888a1;
  :hover {
    filter: brightness(95%);
  }
  .header {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .icon {
      margin-right: 15px;
    }
    .resource-name,
    .title,
    .start-time {
      width: 33%;
    }

    .resource-name {
      padding-left: 60px;
    }

    .title {
      font-weight: 600;
    }

    .start-time {
      .time {
        margin-right: 20px;
      }
    }
  }

  .custom-fields {
    display: inline-flex;
    .name {
      margin-right: 10px;
    }
  }
  .info-alert {
    margin-top: 10px;
  }
`;

const SearchModalStyle = styled.div`
  height: 50vh;

  .header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0px;
  }

  .search {
    margin: 12px;
    padding: 0 10px;
    width: 250px;
    outline: none;
    border: 1px solid #bbb;
    border-radius: 3px;
  }

  .search-config {
    display: flex;
    flex-direction: row;
    align-items: center;
    .months {
      width: auto;
      background: #e9e9e9;
      padding: 5px;
      margin: 0px 10px;
      border-radius: 8px;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      .add {
        margin-bottom: 10px;
      }
    }
  }
`;
