import React from 'react';
import { Resizable } from './Resizable';
import {
  DraggableContainer,
  DraggableContainerProps,
} from './DraggableContainer';

export type DraggableResizableContainerProps = DraggableContainerProps & {
  onResize: (leftDelta: number, rightDelta: number) => void;
  onResizeStop: () => void;
  left: number;
  right: number;
  height: number;
  resizeDisabled: boolean;
};

export const DraggableResizableContainer: React.VFC<DraggableResizableContainerProps> =
  ({
    onResize,
    onResizeStop,
    resizeDisabled,
    left,
    right,
    height,
    children,
    ...dragProps
  }) => (
    <DraggableContainer {...dragProps}>
      <Resizable
        width={right - left}
        height={height}
        disabled={resizeDisabled}
        onResize={onResize}
        onResizeStop={onResizeStop}
      >
        {children}
      </Resizable>
    </DraggableContainer>
  );
