import React from 'react';
import { ProcessLayoutData, ItemInteractionProps } from 'src/models/item';
import { Process } from './Process';
import { SpanningItemWrapper } from './SpanningItemWrapper';
import { TimeRange } from 'src/models';

type Props = ProcessLayoutData &
  ItemInteractionProps & {
    resizing: any;
    setResizing: any;
    hiddenTimes: TimeRange[];
    schedule: TimeRange[];
  };

export const TimelineProcess: React.VFC<Props> = ({
  left,
  top,
  height,
  right,
  item,
  onClick,
  state,
  resizing,
  setResizing,
  onMouseMove,
  hiddenIndicatorOffsets,
  outputBufferOffset,
  hiddenTimes,
  schedule,
  canEdit,
}) => (
  <SpanningItemWrapper
    {...({
      left,
      top,
      height,
      right,
      item,
      hiddenIndicatorOffsets,
      outputBufferOffset,
    } as ProcessLayoutData)}
    {...{
      resizing,
      setResizing,
      hiddenTimes,
      schedule,
      canEdit,
    }}
  >
    {({
      left,
      width,
      outputBufferOffset,
      hiddenIndicatorOffsets,
      duration,
      changed,
    }) => (
      <Process
        onClick={onClick}
        onMouseMove={(evt: React.MouseEvent) => {
          evt.stopPropagation();
          onMouseMove?.(item, evt);
        }}
        data={{
          left,
          width,
          outputBufferOffset,
          hiddenIndicatorOffsets,
          height,
          error: item.conflictCount !== 0,
          changed,
          title: item.title ?? '',
          subtitle: item.subtitle ?? '',
          colorway: item.colorway,
          state,
          locked: item.locked,
          taskIconId: item.taskIconId ?? null,
          duration,
        }}
      />
    )}
  </SpanningItemWrapper>
);
