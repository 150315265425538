import { TimeRange } from 'src/models';
import { Item, ItemLayoutData, isSpanningItem } from 'src/models/item';
import { Scale } from 'src/utils/scale';
import { TimelineResourcesQuery } from 'src/generated/graphql';
import { positionItems, ViewState } from './position-items';

type Resource = TimelineResourcesQuery['resources'][0];

export function calculateResources({
  scale,
  resources,
  getHiddenTimes,
  getViewState,
}: {
  scale: Scale;
  resources: Resource[];
  getHiddenTimes: (start: Date, end: Date) => TimeRange[];
  getViewState: (resourceId: ID) => ViewState;
}) {
  const itemData: ItemLayoutData[] = [];

  let totalHeight = 0;
  const resourceTops: Record<string, number> = {};

  const calculations = resources.map(({ id, items, schedule }) => {
    // Some items may have been put onto a clipboard, and an event would have
    // been sent, causing the item's timeline usage to be wiped. Shortly after,
    // a deletion event gets sent, but we need to handle the scenario before
    // that.
    items = items.filter((x) => x.timelineUsage);

    const spanning = items.filter((x) => isSpanningItem(x as Item));
    const earliest = Math.min(
      ...spanning.map((m) => m!.timelineUsage!.startTime.getTime()),
    );
    const latest = Math.max(
      ...spanning.map((m) => m!.timelineUsage!.endTime.getTime()),
    );
    const hiddenTimes = getHiddenTimes(new Date(earliest), new Date(latest));

    const viewState = getViewState(id);
    const layout = positionItems(items as Item[], viewState, {
      scale,
      hiddenTimes,
      downtimes: schedule,
    });

    itemData.push(...layout.items);
    resourceTops[id] = totalHeight;
    totalHeight += layout.resourceHeight;

    return {
      hiddenTimes,
      viewState,
      layout,
    };
  });

  return {
    itemData,
    calculations,
    resourceTops,
    totalHeight,
  };
}
