import * as Sentry from '@sentry/react';
import { getApolloClient } from 'src/services/apollo.service';
import { UndoActions } from 'src/store/undo';
import { AppDispatch, Store } from 'src/store';
import { moveItem } from './item-movement';
import { restoreItem } from './item-delete';

export async function undo(store: Store, dispatch: AppDispatch) {
  const apollo = getApolloClient();
  const { frozen, entries } = store.getState().undo;
  if (frozen) return;

  dispatch(UndoActions.freeze());

  if (entries.length > 0) {
    const entry = entries[entries.length - 1];
    switch (entry.type) {
      case 'move-item-on-timeline':
        await moveItem(apollo, null, {
          type: 'timeline',
          itemId: entry.itemId,
          resourceId: entry.former.resourceId,
          startTime: entry.former.startTime,
          assertState: entry.validForState,
        })
          .then((e) => {
            if (e.type !== 'concurrency-error') dispatch(UndoActions.pop());
            else dispatch(UndoActions.invalidate());
          })
          .catch((error) => {
            Sentry.captureException(error);
            dispatch(UndoActions.pop());
          });
        break;
      case 'multi-move-item-on-timeline':
        await moveItem(apollo, null, {
          type: 'timeline-multi',
          movements: entry.movements.map((movement) => ({
            itemId: movement.itemId,
            startTime: movement.former.startTime,
            assertState: movement.validForState,
          })),
        })
          .then((e) => {
            if (e.type !== 'concurrency-error') dispatch(UndoActions.pop());
            else dispatch(UndoActions.invalidate());
          })
          .catch((error) => {
            Sentry.captureException(error);
            dispatch(UndoActions.pop());
          });
        break;
      case 'delete-item':
        await restoreItem(apollo, entry.itemId)
          .then(() => dispatch(UndoActions.pop()))
          .catch((error) => {
            Sentry.captureException(error);
            dispatch(UndoActions.pop());
          });
        break;
      default:
        break;
    }
  }

  dispatch(UndoActions.unfreeze());
}
