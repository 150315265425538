import { ApolloClient } from '@apollo/client';
import { AppDispatch } from 'src/store';
import { UndoActions } from 'src/store/undo';
import {
  DeleteItemDocument,
  DeleteItemMutationFn,
  RestoreItemDocument,
  RestoreItemMutationFn,
} from 'src/generated/graphql';

export async function deleteItem(
  client: ApolloClient<unknown>,
  dispatch: AppDispatch | null,
  id: ID,
) {
  const result = await (client.mutate as DeleteItemMutationFn)({
    mutation: DeleteItemDocument,
    variables: {
      input: { id },
    },
  });

  const payload = result.data?.deleteItem;
  if (payload && !payload.success) {
    window.alert(payload.message);
    return;
  }

  dispatch?.(
    UndoActions.push({
      type: 'delete-item',
      itemId: id,
    }),
  );
}

export async function restoreItem(client: ApolloClient<unknown>, id: ID) {
  await (client.mutate as RestoreItemMutationFn)({
    mutation: RestoreItemDocument,
    variables: {
      input: { id },
    },
  });
}
