import React from 'react';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import styled, { css } from 'styled-components';

const baseButtonCss = css`
  height: 40px;
  border-radius: 20px;
  padding: 0 2em;
  font-size: 16px;
  font-weight: 500;

  & div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const whiteButtonCss = css`
  ${baseButtonCss}
  background-color: #fff;
  border: solid 2px ${(props) => props.theme.lightButtonBorder};
  color: #9495ae;
`;

export const OutlinedButtonBase = styled(ButtonBase)`
  ${whiteButtonCss}
`;

export const CircularOutlinedButtonBase = styled(ButtonBase)`
  ${whiteButtonCss}
  width: 40px;
  padding: 0;
`;

export interface SolidButtonBaseProps {
  color: string;
  backgroundColor: string;
}

export const SolidButtonBase = styled(
  ({ backgroundColor: _, ...rest }: SolidButtonBaseProps & ButtonBaseProps) => (
    <ButtonBase {...rest} />
  ),
)`
  ${baseButtonCss}
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`;

export const OutlinedButton: React.FC<ButtonBaseProps> = ({
  children,
  ...props
}) => (
  <OutlinedButtonBase {...props}>
    <div>{children}</div>
  </OutlinedButtonBase>
);

export const CircularOutlinedButton: React.FC<ButtonBaseProps> = ({
  children,
  ...props
}) => (
  <CircularOutlinedButtonBase {...props}>
    <div>{children}</div>
  </CircularOutlinedButtonBase>
);

export const SolidButton: React.FC<ButtonBaseProps & SolidButtonBaseProps> = ({
  children,
  color,
  backgroundColor,
  ...props
}) => (
  <SolidButtonBase color={color} backgroundColor={backgroundColor} {...props}>
    <div>{children}</div>
  </SolidButtonBase>
);

export const ButtonGroup = styled.div`
  & button {
    ${whiteButtonCss}

    padding: 0;
    width: 43px;

    :first-child {
      border-radius: 20px 0 0 20px;
      border-right: 1px solid ${(props) => props.theme.lightButtonBorder};
    }

    :last-child {
      border-radius: 0 20px 20px 0;
      border-left: 1px solid ${(props) => props.theme.lightButtonBorder};
    }
  }
`;
