import React from 'react';
import styled from 'styled-components';
import { InputOutputPanel, RequiredItemFields } from './InputOutputPanel';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { addChain } from 'src/store/drawer';
import { useHotkeys } from 'react-hotkeys-hook';

export interface InputOutputProps {
  inputs: RequiredItemFields[];
  onRemoveInput: (id: ID) => void;
  onRemoveOutput: (id: ID) => void;
  outputs: RequiredItemFields[];
  canEdit: boolean;
}

export const InputOutput: React.FC<InputOutputProps> = ({
  inputs,
  outputs,
  onRemoveInput,
  onRemoveOutput,
  canEdit,
}) => {
  const addingChain = useAppSelector(({ drawer }) => drawer.addingChain);
  const dispatch = useAppDispatch();

  const addInput = (e: KeyboardEvent) => {
    if (addingChain === 'output') {
      return;
    }
    e.preventDefault();
    dispatch(addChain('input'));
  };

  const addOutput = (e: KeyboardEvent) => {
    if (addingChain === 'input') {
      return;
    }
    e.preventDefault();
    dispatch(addChain('output'));
  };

  useHotkeys('up', addInput, {}, [addingChain]);
  useHotkeys('down', addOutput, {}, [addingChain]);
  // shift' for consistency with other hotkeys
  useHotkeys('shift+up', addInput, {}, [addingChain]);
  useHotkeys('shift+down', addOutput, {}, [addingChain]);

  if (!canEdit && inputs.length === 0 && outputs.length === 0) {
    return <div style={{ height: 10 }} />;
  }

  return (
    <InputOutputContainer>
      <InputOutputPanel
        direction="input"
        items={inputs}
        onAddItem={() => dispatch(addChain('input'))}
        onRemoveItem={(id) => onRemoveInput(id)}
        adding={addingChain === 'input'}
        canEdit={canEdit}
      />
      <div className="vertical-divider" />
      <InputOutputPanel
        direction="output"
        items={outputs}
        onAddItem={() => dispatch(addChain('output'))}
        onRemoveItem={(id) => onRemoveOutput(id)}
        adding={addingChain === 'output'}
        canEdit={canEdit}
      />
    </InputOutputContainer>
  );
};

const InputOutputContainer = styled.div`
  display: flex;
  border: solid 2px ${(props) => props.theme.lightTint};
  border-left-width: 0;
  border-right-width: 0;

  margin: 0 -24px; /* Undo padding in drawer so borders reach edges */
  margin-top: 25px;
  padding: 0 6px;

  .vertical-divider {
    height: 100%;
    border: solid 1px ${(props) => props.theme.lighterTint};
  }
`;
