import * as Sentry from '@sentry/react';
import { UndoActions } from 'src/store/undo';
import gql from 'graphql-tag';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { roundToNearestMinutes } from 'date-fns';
import {
  MoveItemOnTimelineDocument,
  MoveItemOnTimelineMutationFn,
  MoveItemsOnTimelineDocument,
  MoveItemsOnTimelineMutationFn,
  MoveItemToClipboardDocument,
  MoveItemToClipboardMutationFn,
  MoveProcessInRunDocument,
  MoveProcessInRunMutationFn,
  RunEditorDocument,
  RunEditorQuery,
  RunEditorQueryVariables,
  TimelineResourcesQuery,
} from 'src/generated/graphql';
import { MOVEMENT_INCREMENT } from 'src/constants';
import { TimeRange } from 'src/models';
import { AppDispatch } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getDuration } from 'src/utils/scale/rangeDuration';
import { ITEM_TYPES } from 'src/models/item';
import { useCurrentPeriodScale } from 'src/utils/scale';
import { useCallback } from 'react';
import { useTimelineResources } from './resources';

export interface TimelineMovement {
  type: 'timeline';
  itemId: ID;
  resourceId: ID | null;
  startTime: Date;
  assertState?: {
    resourceId: ID;
    startTime: Date;
  };
}

export interface MultiTimelineMovement {
  type: 'timeline-multi';
  movements: Array<{
    itemId: ID;
    startTime: Date;
    assertState?: {
      resourceId: ID;
      startTime: Date;
    };
  }>;
}

export interface TimelineShiftMovement {
  initiator: {
    itemId: ID;
    startTime: Date;
  };
  followers: ID[];
  resources: Array<{ id: ID; schedule: TimeRange[] }>;
}

export interface ClipboardMovement {
  type: 'clipboard';
  itemId: ID;
}

export interface RunMovement {
  type: 'run';
  processId: ID;
  runId: ID | null;
  /**
   * If null, will place after the end of last process in the run. if -1, will
   * keep current index if it has one. To enable optimistic updates, do not
   * provide null.
   */
  index: number | null;

  duration: Seconds | null;
  endTime: Date | null;
}

export type Movement =
  | TimelineMovement
  | MultiTimelineMovement
  | ClipboardMovement
  | RunMovement;

const CURRENT_POSITION_FRAGMENT = gql`
  fragment CurrentItemPosition on Item {
    timelineUsage {
      startTime
      resourceId
    }
  }
`;

export type MoveItemResult =
  | { type: 'success' }
  | { type: 'concurrency-error' };

export async function moveItem(
  client: ApolloClient<unknown>,
  dispatch: AppDispatch | null,
  movement: Movement,
): Promise<MoveItemResult> {
  switch (movement.type) {
    case 'timeline':
      return moveItemOnTimeline(client, dispatch, movement);
    case 'timeline-multi':
      return moveItemsOnTimeline(client, dispatch, movement);
    case 'clipboard':
      await (client.mutate as MoveItemToClipboardMutationFn)({
        mutation: MoveItemToClipboardDocument,
        variables: {
          input: { itemId: movement.itemId },
        },
      });
      return { type: 'success' };
    case 'run':
      await (client.mutate as MoveProcessInRunMutationFn)({
        mutation: MoveProcessInRunDocument,
        variables: {
          input: {
            processId: movement.processId,
            runId: movement.runId,
            duration:
              movement.duration != null
                ? (Math.round(movement.duration) as Seconds)
                : null,
            endTime: movement.endTime,
            index: movement.index,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          moveProcessInRun: true,
        },
        update(cache) {
          if (movement.runId == null) return;
          if (movement.index === -1) {
            // -1 means to keep the item at the current index. So we do nothing
            return;
          }

          if (movement.index == null) {
            // App doesn't need optimistic UI for this currently
            return;
          }

          const variables: RunEditorQueryVariables = { runId: movement.runId };
          const result = cache.readQuery<RunEditorQuery>({
            query: RunEditorDocument,
            variables,
          });
          if (result?.item == null || result.item.__typename !== 'Run') {
            return;
          }

          const run = result.item;

          const moving = run.runUsages.find(
            (x) => x.process.id === movement.processId,
          );

          if (moving == null) return;
          const reordered = run.runUsages
            .slice(0)
            .sort((a, b) => a.offset - b.offset)
            .filter((x) => x.process.id !== movement.processId);
          // Don't bother calculating the perfect value - the server will tell
          // us soon enough. It just needs to be in the right range relative to
          // the others, so the processes appear in the right order
          const offsetA = reordered[movement.index - 1]?.offset ?? -1;
          const offsetB = reordered[movement.index]?.offset ?? 0;
          const offset = (offsetA + offsetB) * 0.5;
          reordered.splice(movement.index, 0, {
            ...moving,
            offset: offset as Seconds,
          });

          cache.writeQuery<RunEditorQuery>({
            query: RunEditorDocument,
            variables,
            data: {
              __typename: 'Query',
              item: {
                ...run,
                runUsages: reordered,
              },
            },
          });
        },
      });
      return { type: 'success' };
  }
}

async function moveItemOnTimeline(
  client: ApolloClient<unknown>,
  dispatch: AppDispatch | null,
  movement: TimelineMovement,
): Promise<MoveItemResult> {
  const item = dispatch
    ? ITEM_TYPES.map((item) =>
        client.readFragment({
          id: `${item}:${movement.itemId}`,
          fragment: CURRENT_POSITION_FRAGMENT,
        }),
      ).find((x) => x)
    : undefined;

  const { data } = await (client.mutate as MoveItemOnTimelineMutationFn)({
    mutation: MoveItemOnTimelineDocument,
    variables: {
      input: {
        itemId: movement.itemId,
        startTime: movement.startTime,
        resourceId: movement.resourceId,
        assertState: movement.assertState,
      },
    },
  });

  const ret: MoveItemResult =
    data?.moveItemOnTimeline?.error?.__typename === 'OptimisticConcurrencyError'
      ? { type: 'concurrency-error' }
      : { type: 'success' };

  // NOTE: this will automatically ignore the scenario of moving from the
  // clipboard to the timeline.
  if (!item?.timelineUsage) return ret;

  dispatch?.(
    UndoActions.push({
      type: 'move-item-on-timeline',
      itemId: movement.itemId,
      former: {
        resourceId: item.timelineUsage.resourceId,
        startTime: item.timelineUsage.startTime,
      },
      validForState: {
        resourceId: movement.resourceId ?? item.timelineUsage.resourceId,
        startTime: movement.startTime,
      },
    }),
  );

  return ret;
}

async function moveItemsOnTimeline(
  client: ApolloClient<unknown>,
  dispatch: AppDispatch | null,
  movement: MultiTimelineMovement,
): Promise<MoveItemResult> {
  const items = dispatch
    ? new Map(
        movement.movements.map((movement) => [
          movement.itemId,
          ITEM_TYPES.map((item) =>
            client.readFragment({
              id: `${item}:${movement.itemId}`,
              fragment: CURRENT_POSITION_FRAGMENT,
            }),
          ).find((x) => x),
        ]),
      )
    : undefined;

  let result;
  try {
    const { data } = await (client.mutate as MoveItemsOnTimelineMutationFn)({
      mutation: MoveItemsOnTimelineDocument,
      variables: {
        input: {
          movements: movement.movements.map((movement) => ({
            itemId: movement.itemId,
            startTime: movement.startTime,
            assertState: movement.assertState,
          })),
        },
      },
    });
    result = data?.moveItemsOnTimeline;
  } catch (error) {
    Sentry.captureException(error);
    return { type: 'concurrency-error' };
  }

  const ret: MoveItemResult =
    result?.error?.__typename === 'OptimisticConcurrencyError'
      ? { type: 'concurrency-error' }
      : { type: 'success' };

  dispatch?.(
    UndoActions.push({
      type: 'multi-move-item-on-timeline',
      movements: movement.movements.map((movement) => {
        const item = items!.get(movement.itemId);
        return {
          itemId: movement.itemId,
          former: {
            startTime: item.timelineUsage.startTime,
          },
          validForState: {
            resourceId: item.timelineUsage.resourceId,
            startTime: movement.startTime,
          },
        };
      }),
    }),
  );

  return ret;
}

export async function shiftItemsOnTimeline(
  client: ApolloClient<unknown>,
  dispatch: AppDispatch | null,
  movement: TimelineShiftMovement,
): Promise<MoveItemResult> {
  const { initiator, followers } = movement;
  const items = dispatch
    ? new Map(
        [initiator.itemId, ...followers].map((id) => [
          id,
          ITEM_TYPES.map((item) =>
            client.readFragment({
              id: `${item}:${id}`,
              fragment: CURRENT_POSITION_FRAGMENT,
            }),
          ).find((x) => x),
        ]),
      )
    : undefined;

  if (items == null || [...items.values()].find((x) => !x.timelineUsage)) {
    return { type: 'concurrency-error' };
  }

  const initiatorItem = items.get(initiator.itemId);
  const initiatorPos = initiatorItem.timelineUsage.startTime;
  const initiatorResource = movement.resources.find(
    (x) => x.id === initiatorItem.timelineUsage.resourceId,
  );
  // multiply by 1000 to go from ms to s
  const distance =
    getDuration(
      initiatorPos,
      initiator.startTime,
      initiatorResource!.schedule,
    ) * 1000;

  return await moveItem(client, dispatch, {
    type: 'timeline-multi',
    movements: [initiator.itemId, ...followers].map((itemId) => {
      const item = items.get(itemId);
      const itemPos = item.timelineUsage.startTime;
      const itemResource = movement.resources.find(
        (x) => x.id === item.timelineUsage.resourceId,
      );
      return {
        itemId,
        startTime: moveDuration(itemPos, distance, itemResource!.schedule),
        assertState: undefined, // TODO
      };
    }),
  });
}

export function moveOutOfCollision(
  unroundedTime: Date,
  downtimes: TimeRange[],
) {
  let candidate = roundToNearestMinutes(unroundedTime, { nearestTo: 15 });
  const downtimeCollision = downtimes.find(
    (d) => d.startTime <= candidate && candidate <= d.endTime,
  );

  if (downtimeCollision) candidate = downtimeCollision.endTime;
  return candidate;
}

export function moveDuration(
  start: Date,
  movementMs: number,
  /** NOTE: must be sorted */ downtimes: TimeRange[],
): Date {
  if (movementMs < 0) {
    return greedyLeft(start, -movementMs, downtimes);
  }
  return greedyRight(start, movementMs, downtimes);
}

export function greedyLeft(
  currentStart: Date,
  movementMs: number,
  // NOTE: `downtimes` is sorted
  downtimes: TimeRange[],
) {
  currentStart = new Date(currentStart);

  let remainingMovement = movementMs;
  const reversedDowntimes = [...downtimes].reverse();

  // Skip irrelevant downtimes
  let i = 0;
  for (; i < reversedDowntimes.length; i++) {
    const downtime = reversedDowntimes[i];
    if (currentStart >= downtime.endTime) {
      break;
    }
  }
  // If distance is lower than how far item still wants to move,
  // then move item to the start of that downtime and repeat
  for (; i < reversedDowntimes.length; i++) {
    const closest = reversedDowntimes[i];
    const closeDiff = currentStart.getTime() - closest.endTime.getTime();
    if (closeDiff < remainingMovement) {
      remainingMovement -= closeDiff;
      currentStart.setTime(closest.startTime.getTime());
    } else {
      currentStart.setTime(currentStart.getTime() - remainingMovement);
      remainingMovement = 0;
    }

    if (remainingMovement <= 0) break;
  }

  currentStart.setTime(currentStart.getTime() - remainingMovement);
  if (
    reversedDowntimes[i] &&
    currentStart.getTime() === reversedDowntimes[i].startTime.getTime()
  ) {
    currentStart.setTime(currentStart.getTime() - MOVEMENT_INCREMENT);
  }
  return currentStart;
}

export function greedyRight(
  currentStart: Date,
  movementMs: number,
  // NOTE: `downtimes` is sorted
  downtimes: TimeRange[],
) {
  currentStart = new Date(currentStart);

  let remainingMovement = movementMs;

  // Skip irrelevant downtimes
  let i = 0;
  for (; i < downtimes.length; i++) {
    const downtime = downtimes[i];
    if (currentStart < downtime.startTime) {
      break;
    }
  }
  // If distance is lower than how far item still wants to move,
  // then move item to the start of that downtime and repeat
  for (; i < downtimes.length; i++) {
    const closest = downtimes[i];
    const closeDiff = closest.startTime.getTime() - currentStart.getTime();
    if (closeDiff <= remainingMovement) {
      remainingMovement -= closeDiff;
      currentStart.setTime(closest.endTime.getTime());
    } else {
      currentStart.setTime(currentStart.getTime() + remainingMovement);
      remainingMovement = 0;
    }

    if (remainingMovement <= 0) break;
  }

  currentStart.setTime(currentStart.getTime() + remainingMovement);
  return currentStart;
}

export function snapLeft() {}

export function useSnapItems() {
  const apollo = useApolloClient();
  const dispatch = useAppDispatch();
  const selectedItems = useAppSelector((s) => s.planner.selectedItems);

  const scale = useCurrentPeriodScale();
  const [screenStartDate, screenEndDate] = scale.domain();
  const { data } = useTimelineResources(scale, true);

  const snapLeft = useCallback(() => {
    if (!data?.resources) return;
    moveItem(apollo, dispatch, {
      type: 'timeline-multi',
      movements: snapLeftMovements(
        data.resources,
        screenStartDate,
        selectedItems,
      ),
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, [data, apollo, dispatch, selectedItems, screenStartDate]);

  const snapRight = useCallback(() => {
    if (!data?.resources) return;
    moveItem(apollo, dispatch, {
      type: 'timeline-multi',
      movements: snapRightMovements(
        data.resources,
        screenEndDate,
        selectedItems,
      ),
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, [data, apollo, dispatch, selectedItems, screenEndDate]);

  return {
    snapLeft,
    snapRight,
    selectedItems,
  };
}

function snapLeftMovements(
  resources: TimelineResourcesQuery['resources'],
  screenStartDate: Date,
  selectedItems: Set<ID>,
) {
  const movements: Array<{ itemId: ID; start: Date }> = [];
  for (const res of resources) {
    const sorted = [...res.items].sort(
      (a, b) =>
        a.timelineUsage!.startTime.getTime() -
        b.timelineUsage!.startTime.getTime(),
    );

    let lastEnd;
    for (const item of sorted) {
      if (lastEnd != null && lastEnd < screenStartDate) {
        // Don't snap to something that is beyond the left screen border
        lastEnd = undefined;
      }

      const toSnap = selectedItems.has(item.id) && !item.locked;
      if (lastEnd && toSnap) {
        movements.push({
          itemId: item.id,
          start: lastEnd,
        });

        // Get gap between RHS of left item and RHS of right item, considering
        // downtimes. That is, if a process were to be placed filling the gap,
        // `distance` here is the duration that process would have.
        const distance =
          getDuration(lastEnd, item.timelineUsage!.startTime, res.schedule) *
          1000;

        // Figure out the new end of the current process, by moving the process
        // to the left by the amount required to close the gap
        lastEnd = moveDuration(
          item.timelineUsage!.endTime,
          -distance,
          res.schedule,
        );
      } else {
        lastEnd = item.timelineUsage!.endTime;
      }
    }
  }

  return movements.map(({ itemId, start }) => ({
    itemId,
    startTime: start,
    assertState: undefined,
  }));
}

function snapRightMovements(
  resources: TimelineResourcesQuery['resources'],
  screenEndDate: Date,
  selectedItems: Set<ID>,
) {
  const movements: Array<{ itemId: ID; start: Date }> = [];
  for (const res of resources) {
    // Right to left
    const sorted = [...res.items].sort(
      (a, b) =>
        b.timelineUsage!.endTime.getTime() - a.timelineUsage!.endTime.getTime(),
    );

    let lastStart;
    for (const item of sorted) {
      if (lastStart != null && lastStart > screenEndDate) {
        // Don't snap to something that is beyond the right screen border
        lastStart = undefined;
      }

      const toSnap = selectedItems.has(item.id) && !item.locked;
      if (lastStart && toSnap) {
        // Get gap between RHS of left item and LHS or right item, considering
        // downtimes. That is, if a process were to be placed filling the gap,
        // `distance` here is the duration that process would have
        const distance =
          getDuration(item.timelineUsage!.endTime, lastStart, res.schedule) *
          1000;

        // Figure out the new start time, if the process were shifted to the
        // right by the given duration to close the gap
        lastStart = moveDuration(
          item.timelineUsage!.startTime,
          distance,
          res.schedule,
        );

        movements.push({
          itemId: item.id,
          start: lastStart,
        });
      } else {
        lastStart = item.timelineUsage!.startTime;
      }
    }
  }

  return movements.map(({ itemId, start }) => ({
    itemId,
    startTime: start,
    assertState: undefined,
  }));
}
