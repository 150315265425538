import React, { useState } from 'react';
import styled from 'styled-components';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useShortcut } from 'src/utils/hooks';

export const HotkeysModal: React.VFC = () => {
  const [open, setOpen] = useState(false);

  useShortcut(
    'shift+k',
    () => {
      setOpen((open) => !open);
    },
    [],
  );

  return (
    <>
      <div>
        <Tooltip title="Open Keyboard Shortcuts">
          <IconButton
            className="hotkeys-icon"
            disabled={open}
            aria-label="delete"
            onClick={() => setOpen(true)}
            size="large"
          >
            <KeyboardIcon
              fontSize="large"
              style={{
                color: open ? '#dfe2e8' : '#202252',
              }}
            />
          </IconButton>
        </Tooltip>
      </div>

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ background: '#e9e9e9' }}>
          Keyboard Shortcuts
        </DialogTitle>
        <DialogContent>
          <HotkeysModalStyle>
            <div className="row">
              <div className="timeline-column">
                <h2>Timeline Shortcuts</h2>
                <List>
                  {TIMELINE_SHORTCUTS.map(([name, keys], idx) => (
                    <ListItem key={idx}>
                      <ListItemText primary={`${name}:`} />
                      <div className="key-style-row">
                        {keys
                          .flatMap((key, i) => [
                            <React.Fragment key={2 * i}>+</React.Fragment>,
                            <span key={2 * i + 1} className="key">
                              {key}
                            </span>,
                          ])
                          .slice(1)}
                      </div>
                    </ListItem>
                  ))}
                </List>
              </div>
              <div className="item-column">
                <h2>Item Shortcuts</h2>
                <List>
                  {ITEM_SHORTCUTS.map(([name, keys], idx) => (
                    <ListItem key={idx}>
                      <ListItemText primary={`${name}:`} />
                      <div className="key-style-row">
                        {keys
                          .flatMap((key, i) => [
                            <React.Fragment key={2 * i}>+</React.Fragment>,
                            <span key={2 * i + 1} className="key">
                              {key}
                            </span>,
                          ])
                          .slice(1)}
                      </div>
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
          </HotkeysModalStyle>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const HotkeysModalStyle = styled.div`
  .row {
    display: flex;
    margin: 20px;
  }

  .timeline-column,
  .item-column {
    flex: 50%;
    margin: 10px;
  }

  .key {
    padding: 5px;
    background: #e9e9e9;
    border-radius: 8px;
    border: 1px #d3d3d3 solid;
    box-shadow: 0px 1px 2px 0px;
    margin: 0 8px;
  }
`;

type Shortcut = [string, string[]];

const TIMELINE_SHORTCUTS: Shortcut[] = [
  ['Open Clipboard', ['Shift', 'C']],
  ['Open Shortcuts', ['Shift', 'K']],
  ['Go to Today', ['Shift', 'T']],
  ['After hours Toggle', ['Shift', 'H']],
  ['Zoom In', ['+']],
  ['Zoom Out', ['-']],
  ['Move Timeline Left', ['Left']],
  ['Move Timeline Right', ['Right']],
  ['Big Move Timeline Left', ['Shift', 'Left']],
  ['Big Move Timeline Right', ['Shift', 'Right']],
  ['Close Sidebar', ['ESC', 'ESC']],
  ['Undo', ['Shift', 'Z']],
  ['Search', ['Shift', 'S']],
];

const ITEM_SHORTCUTS: Shortcut[] = [
  ['Duplicate Item', ['Shift', 'D']],
  ['Lock Item', ['Shift', 'L']],
  ['Delete Item', ['Delete']],
  ['Add Input', ['Up']],
  ['Add Output', ['Down']],
  ['Move Item Left', ['Left']],
  ['Move Item Right', ['Right']],
  ['Snap Item(s) Left', ['Shift', 'S']],
  ['Snap Item(s) Right', ['Shift', 'R']],
  ['Deselect Input', ['ESC']],
  ['Select in Range', ['Shift', 'Left Click']],
  ['Toggle Select', ['Ctrl', 'Left Click']],
];
