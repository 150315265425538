import React from 'react';
import styled from 'styled-components';
import { Repeat } from 'src/components/Repeat';

export interface AfterHoursIndicatorProps {
  height: number;
  style?: React.CSSProperties;
}

export const AfterHoursIndicator: React.VFC<AfterHoursIndicatorProps> = ({
  height,
  style,
}) => {
  // Each triangle is 12px high, and we want to add some gap between.
  // This ends up looking quite reasonable. Result is floored so triangles don't
  // extend over the edge of a spanning item
  const count = Math.floor(height / (12 + 6));
  return (
    <Line style={{ height, ...style }}>
      <div>
        <Repeat times={count}>
          <LeftTriangle />
        </Repeat>
      </div>
    </Line>
  );
};

const Line = styled.div`
  border-right: 1px solid black;
  opacity: 0.1;

  display: flex;
  align-items: center;
  z-index: 10;
  transform: translateX(-8px);
`;

const LeftTriangle = styled.div`
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-right: 7.5px solid black;
  margin: 6px 0;
`;
