import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { ItemSideDrawerContents } from './ItemSideDrawerContents';
import { RunEditor } from 'src/components/RunEditor';
import { SIDEBAR_GAP, SIDEBAR_WIDTH } from 'src/constants';
import { useWindowWidth } from '@react-hook/window-size';
import Draggable from 'react-draggable';

export const ItemSideDrawer: React.VFC = () => {
  const [editRun, setEditRun] = useState(false);
  const startEditRun = useCallback(() => setEditRun(true), []);
  const [addingChain, itemId] = useAppSelector(({ drawer }) => [
    drawer.addingChain,
    drawer.editingItemId,
  ]);

  const open = itemId != null && !addingChain;
  const [drawerOffset, setDrawerOffset] = useState(0);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (open) {
      const itemEl = document.getElementById(`item-${itemId}`);
      if (!itemEl) return;
      const rhsPos = itemEl.offsetLeft + itemEl.offsetWidth + SIDEBAR_GAP;
      const remWidth = windowWidth - rhsPos;
      if (remWidth < SIDEBAR_WIDTH) {
        const lhsPos = itemEl.offsetLeft - SIDEBAR_GAP - SIDEBAR_WIDTH;
        if (lhsPos < 0) {
          setDrawerOffset(windowWidth - SIDEBAR_WIDTH);
          return;
        }
        setDrawerOffset(lhsPos);
      } else {
        setDrawerOffset(rhsPos);
      }
    }
  }, [itemId, open, windowWidth]);

  const runEditor = open && editRun && !addingChain;
  return (
    <>
      {runEditor && <RunEditor onClose={() => setEditRun(false)} />}

      <Draggable key={itemId} handle='.handle' bounds='.parent'>

        <div
          style={
            open ?
              {
                position: 'absolute',
                height: '90%',
                width: SIDEBAR_WIDTH,
                zIndex: '200',
                left: runEditor ? windowWidth - SIDEBAR_WIDTH : drawerOffset
              } : {
                position: 'absolute',
                left: 0
              }}>

          <DrawerContainer
            variant="persistent"
            open={open}
            classes={{ paper: 'drawer-paper' }}
          >
            {open && (
              <ItemSideDrawerContents
                editingRun={editRun}
                editRun={startEditRun}
                key={itemId}
              />
            )}
          </DrawerContainer>
        </div>
      </Draggable>
    </>
  );
};

const DrawerContainer = styled(Drawer)`
  .drawer-paper {
    overflow: overlay;
    height: 100%;
    width: ${SIDEBAR_WIDTH}px;
    box-shadow: 0 2px 14px 0 rgba(176, 176, 176, 0.5);
    z-index: 200;
    padding: 0 24px 24px 24px;
    border: 1px solid #c6c6c6;
  }
`;
