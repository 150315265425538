import {
  ListResourceSettingsDocument,
  ListResourceSettingsQuery,
  ReorderResourcesCommandInput,
  ResourceChangedDocument,
  SetResourceResourceTypeCommandInput,
  useCreateResourceMutation,
  useDeleteResourceMutation,
  useFilterResourceMutation,
  useListResourceSettingsQuery,
  useRenameResourceMutation,
  useReorderResourcesMutation,
  useResourceFilterQuery,
  useResourceNameQuery,
  useResourceScheduleQuery,
  useResourceSettingsQuery,
  useResourceTypesQuery,
  useSetDefaultResourceScheduleMutation,
  useSetResourceItemTypesAllowedMutation,
  useSetResourceOverlapsAllowedMutation,
  useSetResourceResourceTypeMutation,
  useSetResourceScheduleMutation,
  useSimpleResourceListQuery,
  useTimelineResourcesQuery,
} from 'src/generated/graphql';
import { MOVEMENT_INCREMENT, UNKNOWN_RESOURCE_TYPE_ID } from 'src/constants';
import { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { timeDay } from 'd3-time';
import {
  resourceQueryPeriod,
  Scale,
  usePeriodEnd,
  usePeriodStart,
} from 'src/utils/scale';

export function useResourceName(resourceId?: ID) {
  const { data, ...result } = useResourceNameQuery({
    fetchPolicy: 'cache-only',
    variables: { id: resourceId! },
    skip: resourceId == null,
  });

  const name = data?.resource.name ?? null;
  return { name, ...result };
}

export function useSimpleResourceList() {
  return useSimpleResourceListQuery();
}

export function useCreateResource() {
  const [createResource] = useCreateResourceMutation({
    refetchQueries: ['ListResourceSettings'],
    variables: {
      input: {
        name: 'New Resource',
        resourceTypeId: UNKNOWN_RESOURCE_TYPE_ID,
      },
    },
  });

  return createResource;
}

export function useResourceTypes() {
  return useResourceTypesQuery();
}

export function useSetResourceResourceType() {
  const [set] = useSetResourceResourceTypeMutation({});

  return useCallback(
    (input: SetResourceResourceTypeCommandInput) => {
      set({ variables: { input } });
    },
    [set],
  );
}

interface ResourceScheduleInput {
  resourceId: ID;
  from: Date;
  ranges: Array<[number, number]>;
}

export function useSetDefaultResourceSchedule() {
  const apollo = useApolloClient();
  const [mutate] = useSetDefaultResourceScheduleMutation();
  return useCallback(
    ({ resourceId, from, ranges }: ResourceScheduleInput) => {
      return mutate({
        variables: {
          input: {
            resourceId,
            from,
            schedule: ranges.map(([a, b]) => ({
              startTime: new Date(from.getTime() + a * MOVEMENT_INCREMENT),
              endTime: new Date(from.getTime() + b * MOVEMENT_INCREMENT),
            })),
          },
        },
      }).then(() => {
        // TODO: is this still needed
        apollo.resetStore();
      });
    },
    [mutate, apollo],
  );
}

export function useSetResourceSchedule() {
  const apollo = useApolloClient();
  const [mutate] = useSetResourceScheduleMutation();
  return useCallback(
    ({ resourceId, from, ranges }: ResourceScheduleInput) => {
      return mutate({
        variables: {
          input: {
            resourceId,
            from,
            to: timeDay.offset(from, 7),
            schedule: ranges.map(([a, b]) => ({
              startTime: new Date(from.getTime() + a * MOVEMENT_INCREMENT),
              endTime: new Date(from.getTime() + b * MOVEMENT_INCREMENT),
            })),
          },
        },
      }).then(() => {
        // TODO: is this still needed
        apollo.resetStore();
      });
    },
    [mutate, apollo],
  );
}

export function useResourceSchedule({ id, from }: { id: ID; from: Date }) {
  return useResourceScheduleQuery({
    variables: {
      id,
      from,
      to: timeDay.offset(from, 7),
    },
  });
}

export function useCachedResourceSchedule(resourceId: ID | null) {
  const viewStart = usePeriodStart();
  const viewEnd = usePeriodEnd();
  const { data } = useResourceScheduleQuery({
    fetchPolicy: 'cache-first',
    skip: resourceId == null,
    variables: resourceId
      ? { id: resourceId, from: viewStart, to: viewEnd }
      : undefined,
  });
  return data?.resource?.schedule;
}

export function useResourceFilter() {
  return useResourceFilterQuery();
}

export function useFilterResource() {
  return useFilterResourceMutation()[0];
}

export function useListResourceSettings() {
  return useListResourceSettingsQuery();
}

export function useReorderResources() {
  const [mutate] = useReorderResourcesMutation();
  return useCallback(
    (input: ReorderResourcesCommandInput) => {
      mutate({
        variables: { input },
        optimisticResponse: {
          __typename: 'Mutation',
          reorderResources: true,
        },
        update(cache) {
          const result = cache.readQuery<ListResourceSettingsQuery>({
            query: ListResourceSettingsDocument,
          });
          if (result == null) {
            return;
          }

          const { resources } = result;

          const moving = resources.find((x) => x.id === input.id);
          if (moving == null) return null;

          const reordered = resources
            .slice(0)
            .sort((a, b) => a.displayIndex - b.displayIndex)
            .filter((x) => x.id !== input.id);
          reordered.splice(input.newIndex, 0, moving);

          cache.writeQuery<ListResourceSettingsQuery>({
            query: ListResourceSettingsDocument,
            data: {
              __typename: 'Query',
              resources: reordered
                .filter((x) => x !== null)
                .map((resource, index) => ({
                  ...resource,
                  displayIndex: index,
                })),
            },
          });
        },
      });
    },
    [mutate],
  );
}

export function useResourceSettings(id: ID) {
  const result = useResourceSettingsQuery({
    variables: { id },
  });

  const { subscribeToMore } = result;
  useEffect(() => {
    return subscribeToMore({
      document: ResourceChangedDocument,
    });
  }, [subscribeToMore]);

  return result;
}

export function useRenameResource() {
  const [mutate] = useRenameResourceMutation();
  return useCallback(
    (id: ID, name: string) => {
      return mutate({
        variables: {
          input: {
            id,
            name,
          },
        },
      });
    },
    [mutate],
  );
}

export type ResourceItemPermissionsUpdateField =
  | 'allowProcesses'
  | 'allowSources'
  | 'allowSinks'
  | 'allowRuns';

export interface UpdateResourceItemPermissions {
  id: ID;
  updateField: ResourceItemPermissionsUpdateField;
  allowed: boolean;
}

export function useSetResourceItemTypesAllowed() {
  const [mutate] = useSetResourceItemTypesAllowedMutation();

  return useCallback(
    (input: UpdateResourceItemPermissions) => {
      return mutate({
        variables: {
          input: {
            id: input.id,
            [input.updateField]: input.allowed,
          },
        },
      });
    },
    [mutate],
  );
}

export function useDeleteResource() {
  const [mutate] = useDeleteResourceMutation();
  return useCallback(
    (id: ID) => {
      return mutate({
        variables: {
          input: { id },
        },
        refetchQueries: ['ListResourceSettings'],
      });
    },
    [mutate],
  );
}

export function useSetResourceOverlapsAllowed() {
  return useSetResourceOverlapsAllowedMutation()[0];
}

export function useTimelineResources(scale: Scale, cacheOnly: boolean) {
  const { startTime, endTime } = resourceQueryPeriod(scale);
  const result = useTimelineResourcesQuery({
    fetchPolicy: cacheOnly ? 'cache-only' : undefined,
    variables: { startTime, endTime },
  });
  return { ...result, startTime, endTime };
}
