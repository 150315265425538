import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonBase, InputBase } from '@mui/material';
import { Remove as MinusIcon, Add as PlusIcon } from '@mui/icons-material';
import { useSetRangedValue } from 'src/utils/hooks';
import { hoursToSeconds, secondsToHours } from 'src/utils';
import { useSetItemLag } from '../../server/drawer';

export interface OutputBufferProps {
  id: ID;
  disabled: boolean;
  serverLag: Seconds | null;
  duration: Seconds;
}

export const OutputBuffer: React.VFC<OutputBufferProps> = ({
  id,
  disabled,
  serverLag,
  duration,
}) => {
  const { outputBuffer, setOutputBuffer } = useServerOutputBuffer({
    itemId: id,
    serverLag,
  });

  const { changeValue, onChange, stringVal } = useSetRangedValue({
    setValue: (v) => setOutputBuffer(v as Hours | null),
    value: outputBuffer,
    maxValue: secondsToHours(duration),
    minValue: 0,
  });

  return (
    <Container>
      <div className="text">Output Buffer</div>
      <div className="counter">
        <ChangeButton onClick={() => changeValue(-1)} disabled={disabled}>
          <MinusIcon />
        </ChangeButton>
        <NumberInput
          value={stringVal}
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp') {
              e.preventDefault();
              changeValue(1);
            } else if (e.key === 'ArrowDown') {
              e.preventDefault();
              changeValue(-1);
            }
          }}
          onChange={onChange}
          disabled={disabled}
          classes={{ input: 'input' }}
        />
        <ChangeButton onClick={() => changeValue(1)} disabled={disabled}>
          <PlusIcon />
        </ChangeButton>
      </div>
    </Container>
  );
};

export function useServerOutputBuffer(input: {
  itemId: ID;
  serverLag: Seconds | null;
}) {
  const [setLag] = useSetItemLag();

  const setServerOutputBuffer = (seconds: Seconds | null) => {
    if (input.itemId == null) return;
    setLag({
      variables: {
        input: {
          itemId: input.itemId,
          lag: seconds == null ? null : (Math.round(seconds) as Seconds),
        },
      },
    });
  };

  const [outputBuffer, setOutputBuffer_] = useState<null | Hours>(
    input.serverLag == null ? null : secondsToHours(input.serverLag),
  );
  const setOutputBuffer = (v: Hours | null) => {
    setOutputBuffer_(v);
    setServerOutputBuffer(v === null ? null : hoursToSeconds(v));
  };

  return { outputBuffer, setOutputBuffer };
}

const Container = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    font-size: 16px;
    font-weight: 500;
  }

  .counter {
    background-color: ${(props) => props.theme.lightTint};
    width: 153px;
    height: 40px;
    display: flex;
  }
`;

const ChangeButton = styled(ButtonBase)`
  flex: 0 0 36px;
  height: 36px;
  margin: 2px;
  background-color: #fff;
`;

const NumberInput = styled(InputBase)`
  padding: 2px;
  flex-grow: 1;

  .input {
    text-align: center;
    padding-bottom: 3px;
  }
`;
