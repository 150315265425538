import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch } from 'src/store/hooks';
import { secondsToHours } from 'src/utils';
import { useApolloClient } from '@apollo/client';
import { moveItem } from 'src/server/item-movement';
import { resizeItem } from 'src/server/item-resize';
import { extendItemUsingDuration } from 'src/server/drawer';
import { SimpleDatePicker } from './SimpleDatePicker';

const DEBOUNCE_MS = 500;

export const DatePicker: React.FC<{
  item: {
    id: ID;
    inRun: boolean;
    duration: Seconds | null;
  };
  serverStartTime: Date;
  serverEndTime?: Date;
  disabled: boolean;
  hideDuration: boolean;
}> = ({ item, serverStartTime, serverEndTime, disabled, hideDuration }) => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const [startTime, setStartTime_] = useState(serverStartTime);
  const [endTime, setEndTime_] = useState(serverEndTime);
  useEffect(() => {
    setStartTime_(serverStartTime);
    if (serverEndTime) {
      setEndTime_(serverEndTime);
    }
  }, [serverStartTime, serverEndTime]);

  const setServerStartTime = useDebouncedCallback((date: Date) => {
    moveItem(client, dispatch, {
      type: 'timeline',
      itemId: item.id,
      startTime: date,
      resourceId: null,
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, DEBOUNCE_MS);

  const setServerEndTime = useDebouncedCallback((date: Date) => {
    if (date.getTime() - serverStartTime.getTime() < 0) {
      return;
    }

    if (item.inRun) {
      moveItem(client, dispatch, {
        type: 'run',
        processId: item.id,
        runId: null,
        index: -1,
        duration: null,
        endTime: date,
      }).catch((error) => {
        Sentry.captureException(error);
      });
    } else {
      resizeItem(client, {
        itemId: item.id,
        startTime: serverStartTime,
        endTime: date,
      });
    }
  }, DEBOUNCE_MS);

  const setServerDuration = useDebouncedCallback((duration: Seconds) => {
    duration = Math.round(duration) as Seconds;
    if (duration <= 0) return;

    if (item.inRun) {
      moveItem(client, dispatch, {
        type: 'run',
        processId: item.id,
        runId: null,
        index: -1,
        duration,
        endTime: null,
      }).catch((error) => {
        Sentry.captureException(error);
      });
    } else {
      extendItemUsingDuration(client, {
        itemId: item.id,
        duration,
      });
    }
  }, DEBOUNCE_MS);

  const setStartTime = (date: Date) => {
    setStartTime_(date);
    setServerStartTime(date);
  };

  const setEndTime = (date: Date) => {
    setEndTime_(date);
    setServerEndTime(date);
  };

  const setDuration = (seconds: Seconds) => {
    setServerDuration(seconds);
  };

  return item.duration == null ? (
    <SimpleDatePicker
      {...{
        startTime,
        setStartTime,
        disabled,
        hideDuration: false,
        disableStartTime: false,
      }}
    />
  ) : (
    <SimpleDatePicker
      disableStartTime={item.inRun}
      duration={secondsToHours(item.duration)}
      {...{
        startTime,
        endTime,
        setStartTime,
        setEndTime,
        setDuration,
        hideDuration,
        disabled,
      }}
    />
  );
};
