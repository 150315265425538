import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { TimeRange } from 'src/models';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { DowntimeBackground } from 'src/components/Timeline/Background';
import { ItemDropTarget } from 'src/components/ItemDropTarget';
import { ItemType, ITEM_TYPES } from 'src/models/item';
import { ViewState } from './position-items';
import { Scale } from 'src/utils/scale';

export interface ResourceProps {
  children?: ReactNode;
  displayCreateItem: (id: ID, x: number, y: number) => void;
  onClick: () => void;
  scale: Scale;
  id: ID;
  name: string;
  inventoryWorkstationId: ID | null;
  allowedItems?: ItemType[];
  viewState: ViewState;
  schedule: TimeRange[];
  cycleViewState: (id: ID) => (event: React.MouseEvent) => void;
  screenOffsets: number[];
  onMouseMove?: (evt: React.MouseEvent) => void;
  height: number;
}

export const Resource: React.VFC<ResourceProps> = ({
  children,
  displayCreateItem,
  onClick,
  scale,
  id,
  name,
  inventoryWorkstationId,
  allowedItems = ITEM_TYPES,
  viewState,
  cycleViewState,
  screenOffsets,
  schedule,
  onMouseMove,
  height,
}) => {
  const onDoubleClick = ({
    target,
    clientX,
    clientY,
  }: React.MouseEvent<Element>) => {
    // Need to find grid element to calculate correct offset
    let gridEl = target as HTMLElement | null;
    while (gridEl && !gridEl.classList.contains('resources')) {
      gridEl = gridEl.offsetParent as HTMLElement | null;
    }
    // This offset allows the buttons to be positioned correctly on the resource
    let clickOffset = 0;
    if (gridEl && gridEl.offsetParent) {
      clickOffset = (gridEl.offsetParent as HTMLElement).offsetTop;
    }

    const y = clientY - clickOffset;
    displayCreateItem(id, clientX, y);
  };

  const cycleView = cycleViewState(id);

  return (
    <ItemDropTarget
      targetKeys={allowedItems}
      dropData={{
        type: 'resource',
        resourceId: id,
        inventoryWorkstationId,
      }}
    >
      <Grid
        className="resources"
        container
        style={{ position: 'relative', height }}
        {...{ onDoubleClick, onClick, onMouseMove }}
      >
        {screenOffsets.map((offset, idx) => (
          <ResourceHeaderName
            key={idx}
            style={{ left: offset }}
            name={name}
            viewState={viewState}
            cycleViewState={cycleView}
          />
        ))}
        {children}
        <DowntimeBackground
          scale={scale}
          downtime={schedule}
          style={{ height }}
        />
      </Grid>
    </ItemDropTarget>
  );
};

function ResourceHeaderName({
  name,
  viewState,
  cycleViewState,
  style,
}: {
  name: string;
  viewState: ViewState;
  cycleViewState: (event: React.MouseEvent) => void;
  style?: React.CSSProperties;
}) {
  return (
    <ResourceHeader style={style}>
      <span onClick={cycleViewState}>
        {viewState === ViewState.Normal ? (
          <KeyboardArrowRightIcon />
        ) : viewState === ViewState.Expanded ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowUpIcon />
        )}
        {name}
      </span>
    </ResourceHeader>
  );
}

const ResourceHeader = styled(Grid)`
  width: 0px;
  position: absolute;
  top: 10px;
  left: 0.5em;

  font-size: 1.5em;
  user-select: none;

  left: 15px;
  font-size: 20px;
  color: ${(props) => props.theme.timelineText};
  z-index: 31;
  white-space: nowrap;

  .MuiSvgIcon-root {
    color: #00a0ff;
    transform: translateY(4px);
  }

  .expandContainer {
    background: red;
  }
`;
