// Adapted from
// https://github.com/d3fc/d3fc/blob/master/packages/d3fc-discontinuous-scale/src/discontinuity/skipWeekends.js

// The base functionality isn't exposed via the library, but is needed for
// skipping just Sunday.

import { DiscontinuityProvider } from '@d3fc/d3fc-discontinuous-scale';
import {
  timeDay,
  timeSunday,
  timeMonday,
  CountableTimeInterval,
} from 'd3-time';

export const base = (
  dayAccessor: (date: Date) => number,
  intervalDay: CountableTimeInterval,
  intervalHide: CountableTimeInterval,
  intervalShow: CountableTimeInterval,
): DiscontinuityProvider => {
  const millisPerDay = 24 * 3600 * 1000;
  const millisPerWorkWeek = millisPerDay * 6;
  const millisPerWeek = millisPerDay * 7;

  const skipSunday: DiscontinuityProvider = {} as any;

  const isSunday = (date: Date) => dayAccessor(date) === 0;

  skipSunday.clampDown = (date) => {
    if (date && isSunday(date)) {
      const newDate = intervalDay.ceil(date);
      if (isSunday(newDate)) return newDate;
      return intervalDay.offset(intervalDay.ceil(date), -1);
    }
    return date;
  };

  skipSunday.clampUp = (date) => {
    if (date && isSunday(date)) {
      return intervalDay.offset(intervalDay.floor(date), 1);
    }
    return date;
  };

  // returns the number of included milliseconds (i.e. those which do not fall)
  // within discontinuities, along this scale
  skipSunday.distance = function (startDate, endDate) {
    startDate = skipSunday.clampUp(startDate);
    endDate = skipSunday.clampDown(endDate);

    // move the start date to the end of week boundary
    const offsetStart = intervalHide.ceil(startDate);
    if (endDate < offsetStart) {
      return endDate.getTime() - startDate.getTime();
    }

    const msAdded = offsetStart.getTime() - startDate.getTime();

    // move the end date to the end of week boundary
    const offsetEnd = intervalHide.ceil(endDate);
    const msRemoved = offsetEnd.getTime() - endDate.getTime();

    // determine how many weeks there are between these two dates
    // round to account for DST transitions
    const weeks = Math.round(
      (offsetEnd.getTime() - offsetStart.getTime()) / millisPerWeek,
    );

    return weeks * millisPerWorkWeek + msAdded - msRemoved;
  };

  skipSunday.offset = function (startDate, ms) {
    let date = isSunday(startDate) ? skipSunday.clampUp(startDate) : startDate;

    if (ms === 0) {
      return date;
    }

    const isNegativeOffset = ms < 0;
    const isPositiveOffset = ms > 0;
    let remainingms = ms;

    // move to the end of week boundary for a postive offset or to the start of a week for a negative offset
    const weekBoundary = isNegativeOffset
      ? intervalShow.floor(date)
      : intervalHide.ceil(date);
    remainingms -= weekBoundary.getTime() - date.getTime();

    // if the distance to the boundary is greater than the number of ms
    // simply add the ms to the current date
    if (
      (isNegativeOffset && remainingms > 0) ||
      (isPositiveOffset && remainingms < 0)
    ) {
      return new Date(date.getTime() + ms);
    }

    // skip the weekend for a positive offset
    date = isNegativeOffset
      ? weekBoundary
      : intervalDay.offset(weekBoundary, 2);

    // add all of the complete weeks to the date
    const completeWeeks = Math.floor(remainingms / millisPerWorkWeek);
    date = intervalDay.offset(date, completeWeeks * 7);
    remainingms -= completeWeeks * millisPerWorkWeek;

    // add the remaining time
    date = new Date(date.getTime() + remainingms);
    return date;
  };

  skipSunday.copy = function () {
    return skipSunday;
  };

  return skipSunday;
};

export const makeSkipSunday = () =>
  base((date) => date.getDay(), timeDay, timeSunday, timeMonday);
