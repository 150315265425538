import React from 'react';

export type PositioningProps = React.HTMLProps<HTMLDivElement> & {
  innerRef?: React.Ref<any>;
  width?: number;
  leftOffset?: number;
  today?: boolean;
};

export const Positioning: React.VFC<PositioningProps> = ({
  innerRef,
  style,
  width,
  leftOffset,
  today: _today,
  ...props
}) => (
  <div
    ref={innerRef}
    style={{ ...style, width, marginLeft: leftOffset }}
    {...props}
  />
);
