import React from 'react';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';
import { Tooltip } from '@mui/material';
import {
  CloseButton,
  CopyLinkButton,
  DeleteButton,
  DuplicateButton,
  EditButton,
  LockUnlockButton,
} from './Buttons';
import { displayItemType, ItemType } from 'src/models/item';

export interface ItemSideDrawHeaderProps {
  onCloseDrawer: () => void;
  itemLocked: boolean;
  canEdit: boolean;
  editRun: (() => void) | null;
  showIcons: boolean;
  setItemLocked: (v: boolean) => void;
  onItemClone: () => void;
  onDeleteItem: () => void;
  resourceName?: string;
  itemId: ID;
  itemType: ItemType;
}

export const ItemSideDrawerHeader: React.FC<ItemSideDrawHeaderProps> = ({
  onCloseDrawer,
  itemLocked,
  canEdit,
  editRun,
  showIcons,
  setItemLocked,
  onItemClone,
  onDeleteItem,
  resourceName,
  itemId,
  itemType,
}) => {
  useHotkeys(
    'shift+l',
    () => {
      const ae = document.activeElement as any;
      if (ae!.tagName !== 'INPUT' && ae!.tagName !== 'TEXTAREA' && canEdit) {
        setItemLocked(!itemLocked);
      }
    },
    {},
    [itemLocked, canEdit],
  );

  useHotkeys(
    'shift+d',
    () => {
      const ae = document.activeElement as any;
      if (ae!.tagName !== 'INPUT' && ae!.tagName !== 'TEXTAREA' && canEdit) {
        onItemClone();
      }
    },
    {},
    [canEdit],
  );

  const deleteItem = () => {
    const ae = document.activeElement as any;
    if (
      ae!.tagName !== 'INPUT' &&
      ae!.tagName !== 'TEXTAREA' &&
      !itemLocked &&
      canEdit
    ) {
      onDeleteItem();
    }
  };
  useHotkeys('delete', deleteItem, {}, [itemLocked, canEdit]);
  useHotkeys('shift+delete', deleteItem, {}, [itemLocked, canEdit]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'move',
        margin: '6px 0',
        padding: '18px 0',
      }}
      className="handle"
    >
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px' }}>
        <CloseButton onClick={onCloseDrawer} />
        <Title>
          {resourceName}: {displayItemType(itemType)}
        </Title>
      </div>
      <div style={{ display: 'flex', marginTop: 0, paddingRight: '6px' }}>
        {showIcons && editRun && (
          <EditButton onClick={editRun} style={{ marginRight: 8 }} />
        )}
        <CopyLinkButton itemId={itemId} />
        {showIcons ? (
          <LockUnlockButton
            locked={itemLocked}
            setLocked={setItemLocked}
            disabled={!canEdit || !showIcons}
          />
        ) : (
          itemLocked && (
            <Tooltip title="Process is locked because its run is locked">
              <span>
                <LockUnlockButton
                  locked={itemLocked}
                  setLocked={setItemLocked}
                  disabled={true}
                />
              </span>
            </Tooltip>
          )
        )}
        {showIcons && canEdit && <DuplicateButton onClick={onItemClone} />}
        {!itemLocked && canEdit && <DeleteButton onClick={onDeleteItem} />}
      </div>
    </div>
  );
};

const Title = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(props) => props.theme.mainAccent};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
