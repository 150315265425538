import React, { useCallback } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  useListResourceSettings,
  useReorderResources,
} from 'src/server/resources';
import { ResourceListItem } from './ResourceListItem';
import styled from 'styled-components';

export const ResourceList: React.VFC = () => {
  const { loading, error, data } = useListResourceSettings();
  const reorderResources = useReorderResources();

  const sortedResources = data
    ? [...data.resources].sort((a, b) => a.displayIndex - b.displayIndex)
    : [];

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      reorderResources({
        id: result.draggableId as ID,
        newIndex: result.destination.index,
      });
    },
    [reorderResources],
  );

  if (loading) return <LinearProgress />;
  if (error || !data || !data.resources) {
    return <div>Failed to fetch resources :(</div>;
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="container">
        {(provided, snapshot) => (
          <Background
            {...provided.droppableProps}
            ref={provided.innerRef}
            // Otherwise the container shrinks in height by one element when you
            // pick up an element, which doesn't look great
            style={
              snapshot.isDraggingOver
                ? { paddingBottom: 58, marginBottom: 2 }
                : undefined
            }
          >
            {sortedResources.map((res, index) => (
              <div key={res!.id}>
                <ResourceListItem
                  id={res!.id}
                  index={index}
                  name={res!.name}
                  allowSources={res!.allowSources}
                  allowProcesses={res!.allowProcesses}
                  allowSinks={res!.allowSinks}
                  allowRuns={res!.allowRuns}
                />
              </div>
            ))}
          </Background>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Background = styled.div`
  outline: 2px solid ${(props) => props.theme.lightTint};
  background-color: ${(props) => props.theme.lightTint};
`;
