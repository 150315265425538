import { useState } from 'react';
import styled from 'styled-components';
import {
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as DropdownIcon } from 'src/assets/icons/dropdown-arrow.svg';
import { useIsMobile } from 'src/utils/media';
import { useAppSelector } from 'src/store/hooks';
import { AccountSelectors, UserUtils } from 'src/store/login';
import React from 'react';
import { getProvider } from 'src/services/auth.service';

export const UserDropdown: React.VFC = () => {
  const mobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const user = useAppSelector(AccountSelectors.user);
  const name = user?.name ?? '';

  let nameText = mobile ? name.split(' ')[0] : name;
  const isAdmin = UserUtils.isAdmin(user);
  const canEdit = UserUtils.canEdit(user);
  nameText += isAdmin ? ' (Admin)' : canEdit ? '' : ' (Readonly)';

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NameWrapper>
          <Typography style={{ fontSize: mobile ? 11 : 'unset' }}>
            {nameText}
          </Typography>
        </NameWrapper>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <DropdownChevron onClick={() => setOpen((o) => !o)} />
            {open && (
              <DropdownWrapper>
                <List>
                  {isAdmin && (
                    <ListItem button component={Link} to="/settings">
                      <ListItemText primary="Settings" />
                    </ListItem>
                  )}
                  <ListItem button component={Link} to="/changes">
                    <ListItemText primary="Changes" />
                  </ListItem>
                  {user?.name != null && (
                    <ListItem
                      button
                      onClick={() => getProvider().logoutRedirect()}
                    >
                      <ListItemText primary="Log Out" />
                    </ListItem>
                  )}
                </List>
              </DropdownWrapper>
            )}
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

const DropdownChevron: React.VFC<{ onClick: () => void }> = ({ onClick }) => (
  <DropdownChevronButton size="small" onClick={onClick}>
    <DropdownIcon />
  </DropdownChevronButton>
);

const DropdownChevronButton = styled(IconButton)`
  width: 16px;
  height: unset !important;
  padding: 0;
  margin: 0 0 2px 0;
  object-fit: contain;
  color: #5878ff !important;
`;

const NameWrapper = styled.div`
  margin-right: 8px;
  color: #fff;
`;

const DropdownWrapper = styled(Paper)`
  position: absolute;
  top: 3em;
  right: 1em;
  z-index: 10000;
`;
