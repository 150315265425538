import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import { kioskMode } from 'src/services/auth.service';
import { enableMapSet } from 'immer';

import { drawerReducer } from './drawer';
import { loginReducer } from './login';
import { plannerReducer } from './planner';
import { undoReducer } from './undo';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state: RootState) => {
    const { user } = state.login;
    if (kioskMode) {
      scope.setUser(null);
      scope.setTag('user.auth', 'kiosk');
    } else if (user == null) {
      scope.setUser(null);
      scope.setTag('user.auth', 'logged-out');
    } else {
      scope.setUser({
        id: user.email ?? undefined,
        username: user.name ?? undefined,
        email: user.email ?? undefined,
      });

      scope.setTag('user.auth', 'azure');
    }
  },
});

enableMapSet();

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    login: loginReducer,
    planner: plannerReducer,
    undo: undoReducer,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefault) => getDefault({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type Store = typeof store;
