import React, { useState, useContext, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ButtonBase, ClickAwayListener, Paper, Popper } from '@mui/material';
import { ColorwayName } from 'src/config/theme';

export interface ColorPickerProps {
  colorway: ColorwayName;
  setColorway: (v: ColorwayName) => void;
  disabled: boolean;
  style?: React.CSSProperties;
}

export const ColorPicker: React.VFC<ColorPickerProps> = ({
  colorway,
  setColorway,
  disabled,
  style,
}) => {
  const theme = useContext(ThemeContext);
  const [pickerOpen, setPickerOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <ClickAwayListener onClickAway={() => setPickerOpen(false)}>
      <div>
        <PickerButton
          onClick={() => setPickerOpen((v) => !v)}
          disabled={disabled}
          ref={buttonRef}
          tabIndex={-1}
          style={{
            backgroundColor: theme.colorways[colorway],
            ...style,
          }}
        />
        <Popper
          style={{ zIndex: 300 }}
          open={pickerOpen}
          anchorEl={buttonRef.current}
          placement="left"
        >
          <StyledPaper elevation={3}>
            {Object.keys(theme.colorways).map((c) => (
              <PickerButton
                key={c}
                onClick={() => setColorway(c as ColorwayName)}
                style={{
                  backgroundColor: theme.colorways[c as ColorwayName],
                }}
              />
            ))}
          </StyledPaper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const PickerButton = styled(ButtonBase)`
  border-radius: 15px;
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
  border: 2px solid ${(props) => props.theme.lightTint};
`;

const StyledPaper = styled(Paper)`
  padding: 5.5px;
  border-radius: 4px;
  background-color: #fff;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 129px;
  height: 168px;

  ${PickerButton} {
    margin: 4.5px;
  }

  /* The shadow for the triangle */
  ::before {
    content: '';
    position: absolute;
    right: 0;
    top: 84.5px;

    width: 13px;
    height: 13px;
    transform: translate(50%, -50%) rotate(45deg);

    /* From elevation: 3 styling on Paper component */
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    z-index: -1;
  }

  /* The triangle */
  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 84.5px;

    background-color: #fff;

    width: 13px;
    height: 13px;
    transform: translate(50%, -50%) rotate(45deg);

    z-index: 1;
  }
`;
