import React from 'react';
import styled from 'styled-components';

interface ChangedHighlightStyleProps {
  borderRadius: number;
  borderSize: number;
  grow?: boolean;
}

export interface ChangedHighlightProps extends ChangedHighlightStyleProps {
  style?: React.CSSProperties;
}

export const ChangedHighlight: React.VFC<ChangedHighlightProps> = ({
  borderRadius,
  borderSize,
  grow,
  style,
}) => (
  <ChangedHighlightStyle
    borderRadius={borderRadius}
    borderSize={borderSize}
    grow={grow}
  >
    <div className="highlight" style={style} />
  </ChangedHighlightStyle>
);

const ChangedHighlightStyle = styled.div<ChangedHighlightStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${(props) =>
    props.grow &&
    `\
    top: -${props.borderSize}px;
    left: -${props.borderSize}px;
    width: calc(100% + ${props.borderSize * 2}px);
    height: calc(100% + ${props.borderSize * 2}px);
  `}

  z-index: 4;

  .highlight {
    border: ${(props) => props.borderSize}px solid
      ${(props) => props.theme.changedHighlight};
    border-radius: ${(props) => props.borderRadius}px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
