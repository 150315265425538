import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { timeFormat } from 'd3-time-format';
import { ChangeType } from '../../generated/graphql';
import { TidyLink } from 'src/components/Util';
import { Change, useRecentChanges } from 'src/server/changes';
import { useJumpToItem } from '../../utils/item';

export const changeTypeMap: Record<ChangeType, string> = {
  ADD_ITEM: 'Add Item',
  MOVE_ITEM: 'Move Item',
  RESIZE_ITEM: 'Resize Item',
  DELETE_ITEM: 'Delete Item',
  ADD_RESOURCE: 'Add Resource',
  DELETE_RESOURCE: 'Delete Resource',
  MOVE_TO_CLIPBOARD: 'Moved to Clipboard',
  MOVE_FROM_CLIPBOARD: 'Moved from Clipboard',
};

export const ChangeList: React.VFC = () => {
  const { loading, error, data } = useRecentChanges();

  if (loading) return <LinearProgress />;
  if (error || !data || !data.changes) {
    return <div>Failed to fetch changes :(</div>;
  }

  return (
    <div>
      {[...data.changes]
        .sort((a, b) => b.date.getTime() - a.date.getTime())
        .map((change) => (
          <div key={change.id}>
            <ChangeItem {...change} />
          </div>
        ))}
    </div>
  );
};

const formatDate = timeFormat('%H:%M %b %d, %Y');

const ChangeItem: React.VFC<Change> = ({
  date,
  userName,
  changeType,
  affectedItem,
  affectedResource,
}) => {
  const jumpToItem = useJumpToItem();

  return (
    <ItemContainer>
      <div className="date">{formatDate(date)}</div>
      <div className="type">{changeTypeMap[changeType]}</div>
      {affectedItem ? (
        affectedItem.deleted ? (
          <div className="entity" title="Deleted">
            {affectedItem && (affectedItem.title || 'No title')}
          </div>
        ) : (
          <div className="entity">
            <TidyLink to="/" onClick={() => jumpToItem(affectedItem!.id)}>
              <div style={{ paddingRight: '0.25em' }}>
                {affectedItem && (affectedItem.title || 'No title')}
              </div>
              <LinkIcon />
            </TidyLink>
          </div>
        )
      ) : (
        affectedResource && (
          <div className="entity">
            {affectedResource && (affectedResource.name || 'No name')}
          </div>
        )
      )}
      <div className="user">{userName}</div>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  width: 100%;
  height: 58px;
  margin-bottom: 2px;
  background-color: white;
  /* Fake border which doesn't double up on edges */
  box-shadow: 0 0 0 2px ${(props) => props.theme.lightTint};

  display: grid;
  align-items: center;
  grid-template-columns: 180px 1fr 2fr 1fr;

  .type {
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.calmText};
  }

  .user {
    display: flex;
    & > div {
      margin-right: 25px;
    }
  }

  .entity {
    text-transform: uppercase;
    font-weight: 500;

    ${TidyLink} {
      display: flex;
      align-items: center;
    }
  }

  .date {
    margin: 3px 20px 0 0;
    display: flex;
    justify-content: flex-end;

    :after {
      content: '';
      position: relative;
      right: -10px;
      top: -3px;
      width: 2px;
      height: 28px;
      background-color: ${(props) => props.theme.lightTint};
    }
  }
`;
